import React from 'react';

export const FiltersContext = React.createContext({
	geo: {
		values: [],
		current: null
	},
	period: {
		values: [],
		current: null
	},
	colors: {
		values: [],
		current: null
	},
	styles: {
		values: [],
		current: null
	},
	elements: {
		current: null
	},
	basedOn: {
		values: [{"id": "heritage", "title": "Cultural heritage"}],
		current: null
	},

	fetch: () => {},
});

export default FiltersContext;