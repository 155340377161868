import React from 'react';
import {SvgIcon} from "@mui/material";

function SearchIcon(props) {
  return (
    <SvgIcon sx={{
      width: props.width,
      height: props.height,
    }}
    viewBox="0 0 16 16">
        <path d="M14 14L10.0001 10M11.3333 6.66667C11.3333 9.244 9.244 11.3333 6.66667 11.3333C4.08934 11.3333 2 9.244 2 6.66667C2 4.08934 4.08934 2 6.66667 2C9.244 2 11.3333 4.08934 11.3333 6.66667Z" fill="none" stroke='currentColor' stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    </SvgIcon>
  );
}

export default SearchIcon;