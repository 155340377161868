import React, {useEffect, useState} from "react";
import useScrollPosition from "@react-hook/window-scroll";

import {Box, Grid, Paper, Typography, Container, LinearProgress} from "@mui/material";
import {SearchString} from "../../../components/SearchString/SearchString";
import {CollectionPreview} from "../../../components/Collection/CollectionPreview";
import SearchButtons from "../../../components/SearchString/SearchButtons";
import useAxios from "axios-hooks";
import {getAxiosHeaders} from "../../../api/client";
const _ = require("lodash");

export function CollectionsPage() {

  const [collections, setCollections] = useState([]);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  const scrollY = useScrollPosition(10);

  const [{data, loading}] = useAxios({
    url: '/api/products/',
    headers: getAxiosHeaders(),
    params: {type: "collection"},
  }, {useCache: false});

  const fetchNextPage = () => {
    setOffset(collections ? collections.length : 0)
  }

  useEffect(() => {
    if (data && data.length) {
      setHasMore(offset + data.length < data.count);
      setCollections( (collections) => collections ? _.concat(collections, data) : data);
    } // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (hasMore) {
      const bottom = Math.abs(window.innerHeight + scrollY - document.body.scrollHeight) < 20;
      if (bottom) {
        fetchNextPage();
      }
    } // eslint-disable-next-line
  }, [scrollY]);

  if (loading) {
    return  <LinearProgress style={{width: '100%'}} />
  }

  return (
    <>
      <Paper elevation={1} m={10} color={"transparent"}>
        <Container maxWidth="xl">
        <Box sx={{ flexGrow: 1 }}>
          <SearchButtons current={"collections"}/>
          <Grid container justifyContent="center" alignItems="center" spacing={2}>
            <Grid item xs={12} textAlign="center">
              <Typography variant="h1" p={3}>Collections</Typography>
              <Typography variant={"subtitle1"} pb={2}>Only verified authentic graphics. Highest technical and visual quality</Typography>
            </Grid>
            <Grid item xs={12} mb={4}>
              <SearchString />
            </Grid>
          </Grid>

        </Box>
        </Container>
      </Paper>
      <Container maxWidth="xl" disableGutters>
        {!loading && collections && collections.map((collection) => (
          <CollectionPreview
            key={collection.id}
            collection={collection}
          />))}
      </Container>
    </>
  );
}

export default CollectionsPage;