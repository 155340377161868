import React from 'react';
import {SvgIcon} from "@mui/material";

export function PlusIcon(props) {
  return (
      <SvgIcon sx={{...props}} viewBox={'0 0 24 24'}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={'currentColor'}>
              <path d="M12.1445 5.14453V19.1445M5.14453 12.1445H19.1445" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
      </SvgIcon>
  );
}