import i18n from 'i18next';
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import translationEN from './locales/en/translation.json';
import translationRU from './locales/ru/translation.json';
import {getLanguageLocally} from "./helpers/profile";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  ru: {
    translation: translationRU
  },
  en: {
    translation: translationEN
  }
};

i18n
.use(detector)
.use(initReactI18next) // passes i18n down to react-i18next
.init({
  resources,
  lng: getLanguageLocally(),
  debug: false,

  interpolation: {
    escapeValue: false // react already safes from xss
  }
});

export default i18n;
