import './App.css';
import './theme.scss';
import {Box, Container, Grid, useTheme} from "@mui/material";
import {ThemeProvider} from "@mui/material/styles";
import React, {useEffect, useState} from "react";
import {getAxiosHeaders} from "./api/client";
import useAxios from "axios-hooks";
import AuthContext from "./contexts/AuthContext";
import CartContext from "./contexts/CartContext";
import {getCurrentToken, setCurrentToken} from "./helpers/profile";
import {Header} from "./components/Header/Header";
import {Outlet} from "react-router-dom";
import {Footer} from "./components/Footer/Footer";
import SignInForm from "./components/Authorization/SignInForm";
import SignUpForm from "./components/Authorization/SignUpForm";
import WelcomeModal from "./components/Authorization/WelcomeModal";

const _ = require("lodash");
const amplitude = window.amplitude;

const PluranaApp = () => {
  const theme = useTheme();
  const [user, setUser] = useState(null);
  const [session, setSession] = useState(null);

  const [cart, setCart] = useState({});

  const [formsState, setFormsState] = useState({
    signin: false,
    signup: false,
    welcome: false,
    subscribe: false,
    tokens: false,
    callback: null
  });

  const [{data: userData}, doFetchUser] = useAxios({
    url: '/api/auth/by-token/',
    headers: getAxiosHeaders(),
  }, {autoCancel: false});

  const [{data: cartData}, doFetchCart] = useAxios({
    url: '/api/cart/',
    headers: getAxiosHeaders(),
  }, {autoCancel: false});

  const [{}, doAddToCart] = useAxios({
    method: 'POST',
    url: '/api/cart/add-product/',
    headers: getAxiosHeaders(),
  }, {manual: true, autoCancel: false});

  const [{}, doUpdateCart] = useAxios({
    method: 'POST',
    url: '/api/cart/update-product/',
    headers: getAxiosHeaders(),
  }, {manual: true, autoCancel: false});


  // const [{data: cartLinesData}, doFetchCartLines] = useAxios({
  //   headers: getAxiosHeaders(),
  // }, {autoCancel: false, manual: true});


  const fetchUser = (token) => {
    console.log('fetchUser');
    if (!token && _.isEmpty(getCurrentToken())) {
      return null;
    }
    return doFetchUser({
      headers: getAxiosHeaders(token)
    });
  }

  useEffect(() => {
    console.log('userData changed', userData);
    if (userData && userData.user) {
      setUser(userData.user);
    } else if (userData && userData.session) {
      setSession(userData.session);
    }
    if (userData && amplitude) {
      const identifyEvent = new amplitude.Identify();
      if (userData.user) {
        identifyEvent.set('subscription', userData.user.subscription?.tier.key || "starter");
        amplitude.setUserId(userData.user.email);
      }
      amplitude.setDeviceId(userData.session.id);
      amplitude.identify(identifyEvent);
    }

  }, [userData]);

  useEffect(() => {
    console.log('cartData changed', cartData);
    setCart(cartData);
  }, [cartData]);

  const logoutUser = () => {
    setCurrentToken("");
    setUser(null);
  }

  const withdrawTokens = (amount) => {
    const tokens = user ? user.tokens : session.usage;
    updateTokens(tokens - amount);
  }

  const updateTokens = (amount) => {
    if (user) {
      setUser(_.assign({}, user, {"tokens": amount}))
    } else {
      setSession(_.assign({}, session, {"usage": amount}))
    }
  }

  const getAuthContextValue = () => {
    return {
      user: user,
      session: session,
      updateTokens: updateTokens,
      withdrawTokens: withdrawTokens,
      fetch: fetchUser,
      logout: logoutUser,
      signInForm: _.partial(handleOpenForm, "signin"),
      signUpForm: _.partial(handleOpenForm, "signup"),
      welcomeModal: _.partial(handleOpenForm, "welcome"),
    }
  }

  const handleOpenForm = (form, callback) => {
    setFormsState({
      signin: form === "signin",
      signup: form === "signup",
      welcome: form === "welcome",
      subscribe: form === "subscribe",
      tokens: form === "tokens",
      callback: callback
    });
  }
  const handleCloseForm = () => {
    setFormsState({
      signin: false,
      signup: false,
      welcome: false,
      subscribe: false,
      tokens: false,
      callback: null
    });
  }

  return (
    <ThemeProvider theme={theme}>
        <Container maxWidth={false} disableGutters className="root-container">
          <Grid container justifyContent="center">

          <CartContext.Provider value={{cart, setCart, refetch: doFetchCart, doAddToCart, doUpdateCart}}>
            <AuthContext.Provider value={getAuthContextValue()}>
              <Grid item xs={12}>
                <Header />
                <Box>
                  <Outlet />
                </Box>
                <SignInForm open={formsState.signin} onClose={handleCloseForm} callback={formsState.callback}/>
                <SignUpForm open={formsState.signup} onClose={handleCloseForm} callback={formsState.callback}/>
                <WelcomeModal open={formsState.welcome} onClose={handleCloseForm}/>
              </Grid>
            </AuthContext.Provider>
           </CartContext.Provider>
          </Grid>
        </Container>
      
        <Footer />
    </ThemeProvider>
  );
}

export default PluranaApp;
