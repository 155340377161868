import {Box, Button, IconButton} from "@mui/material";
import './GraphicElementsList.scss';
import {DeleteIcon, GalleryPlusIcon} from "../../icons";

export const GraphicElementsList = ({
    elements,
    setElements,
    handleFilesAttach,
    addItemToHistory,
}) => {

    const toggleItem = (item) => {
        setElements(elements.map(attachment => {
            if (attachment.id === item.id) {
                return {...item, selected: !item.selected}
            }
            return attachment
        }))
    }

    const removeItem = (item) => {
        setElements([...elements.filter(attachment => attachment.id !== item.id)])
        addItemToHistory({
            type: 'remove',
            target: 'element',
            undo: () => {setElements(elements)},
            redo: () => setElements([...elements.filter(attachment => attachment.id !== item.id)])
        })
    }


    return (
        <Box className={'GraphicElementsList'}>
            <Box className={'GraphicElementsList__Item'}>
                <Button component={'label'} className={'GraphicElementsList__Item__Tappable'}>
                    <GalleryPlusIcon width={28} height={28} color={'#1A1A19'}/>
                    <input type="file" style={{display: 'none'}} onChange={e => handleFilesAttach(e.target.files)} multiple accept={'.png,.svg,.jpg,.jpeg'}/>
                </Button>
            </Box>

            {elements.map(item => (
                <Box className={'GraphicElementsList__Item' + (item.selected ? ' GraphicElementsList__Item-selected' : '')} key={item.id}>
                    <Button className={'GraphicElementsList__Item__Tappable'} onClick={() => toggleItem(item)}>
                        <img src={item.png} alt={''} className={'GraphicElementsList__Item__Image'}/>
                    </Button>
                    <IconButton className={'GraphicElementsList__Item__RemoveButton'} onClick={() => removeItem(item)}>
                        <DeleteIcon width={12} height={12}/>
                    </IconButton>
                </Box>
            ))}
        </Box>
    )
}