import {Link as RouterLink, useParams} from "react-router-dom";
import React, {useContext, useEffect} from "react";
import useAxios from "axios-hooks";
import {getAxiosHeaders} from "../../api/client";
import {makeStyles} from "@mui/styles";
import {Box, Button, FormControl, FormLabel, Grid, IconButton, MenuItem, Select, Typography} from "@mui/material";
import {currencySymbol} from "../../helpers/shop";
import CloseIcon from "@mui/icons-material/Close";

const _ = require("lodash");

const useStyles = makeStyles((theme) => ({
	orderHeader: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		marginBottom: 24,
		"& h2.MuiTypography-root": {
			color: "#1A1A19",
			fontSize: 46,
			fontWeight: 600,
			lineHeight: "100%",
			letterSpacing: "-0.46px",
		},
		"& .MuiTypography-root.MuiTypography-caption": {
			color: "rgba(26, 26, 25, 0.50)",
			fontSize: 16,
			fontWeight: 350,
			lineHeight: "100%",
			letterSpacing: "0.16px",
		},
	},
	orderItemsList: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-start",
		gap: 14,
	},
	orderItem: {
		width: "100%",
		display: "flex",
		padding: 20,
		flexDirection: "row",
		alignItems: "center",
		gap: 20,
		alignSelf: "stretch",
		borderRadius: 20,
		boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.10), 0px 2px 2px 0px rgba(0, 0, 0, 0.04)",
	},
	orderItemImage: {
		width: 160,
		height: 160,
		borderRadius: 12,
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center",
		backgroundSize: "cover",
		backgroundColor: "#F2F2F2",
	},
	orderItemInfo: {
		display: "flex",
		alignSelf: "stretch",
		flexDirection: "column",
		alignItems: "flex-start",
		justifyContent: "flex-start",
		flexGrow: 1,
		gap: 20,
		"& .MuiInputBase-root": {
			borderRadius: 6,
			border: "1px solid rgba(0, 0, 0, 0.07)",
			boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.05), 0px 2px 104px 0px rgba(0, 0, 0, 0.03)",
		},
		"& .MuiFormLabel-root": {
			fontSize: 14,
			fontWeight: 350,
			lineHeight: "100%",
			letterSpacing: "0.28px",
			marginBottom: 11,
		},
	},
	orderItemPrice: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		gap: 20,
		paddingLeft: 51,
	},
	orderTotalBoard: {
		display: "flex",
		width: "100%",
		padding: "10px 30px 20px 30px",
		flexDirection: "column",
		alignItems: "flex-start",
		gap: 10,
		borderRadius: 20,
		background: "#F5F5F5",

		"& .totalLine": {
			display: "flex",
			flexDirection: "row",
			alignSelf: "stretch",
			alignItems: "center",
			justifyContent: "space-between"
		},

		"& .caption.MuiTypography-root": {
			color: "rgba(51, 51, 51, 0.50)",
			fontFeatureSettings: "'case' on",
			fontSize: 16,
			fontWeight: 350,
			lineHeight: "120%",
			margin: "10px 0",
		},
		"& .download.MuiButtonBase-root": {
			color: "#FFFFFF",
			display: "flex",
			padding: "19px 20px",
			justifyContent: "center",
			alignItems: "center",
			gap: 13,
			alignSelf: "stretch",
			borderRadius: 12,
			background: "#1A1A19",
		}
	},
}));

const OrderPage = () => {

	const params = useParams();

	const classes = useStyles();

	const [{data, loading, error}] = useAxios({
		method: 'GET',
		url: `/api/orders/${params.orderId}/`,
		headers: getAxiosHeaders(),
	});

	// const [{data: download}, doDownloadFiles] = useAxios({
	// 	method: 'GET',
	// 	url: `/api/order/${params.orderId}/download/`,
	// 	headers: getAxiosHeaders(),
	// }, {manual: true});

	const handleDownloadClick = () => {
		window.location.href = `/api/order/${params.orderId}/download/`;
	}

	if (loading) return <>Loading...</>

	return <>
		<Grid container spacing={4}>
			<Grid item xs={12}>
				<Grid item xs={8}>
					<Box className={classes.orderHeader}>
						<Typography variant="h2">Order {data.number}</Typography>
						<Typography variant="caption">View all orders →</Typography>
					</Box>
				</Grid>
			</Grid>
			<Grid item xs={8} mb={1}>
				<Box className={classes.orderItemsList}>
					{_.map(data?.lines, ({product, stockrecord, ...line}) => {
						return <Box key={line.id} className={classes.orderItem}>
							<Box className={classes.orderItemImage} sx={{backgroundImage: `url(${product.images[0].original})`}}></Box>
							<Box className={classes.orderItemInfo}>
								<Typography variant="h3">{product.title}</Typography>
								<FormControl fullWidth>
									<FormLabel>License</FormLabel>
									{stockrecord.title}
								</FormControl>
							</Box>
							<Box className={classes.orderItemPrice}>
								<Typography variant="body1">{currencySymbol(stockrecord.price_currency)} {parseFloat(stockrecord.price)}</Typography>
							</Box>
						</Box>
					})}
				</Box>
			</Grid>
			<Grid item xs={4} mb={1}>
				<Box className={classes.orderTotalBoard}>
					<Button className="download" variant="contained" onClick={handleDownloadClick}>Download all</Button>
				</Box>
			</Grid>
		</Grid>
	</>

}

export default OrderPage;