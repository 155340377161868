import React from 'react';
import {SvgIcon} from "@mui/material";

function SelectSmallIcon(props) {
  return (
    <SvgIcon width="25" height="25" viewBox="0 0 25 25" {...props}>
        <path d="M7.89502 11.6685L12.9951 16.7686L18.0952 11.6685" stroke="#1A1A19" fill='none' stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </SvgIcon>
  );
}

export default SelectSmallIcon;