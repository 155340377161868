import Cookies from "js-cookie";

export function getCurrentToken() {
  return localStorage.getItem('plurana') || Cookies.get('session');
}

export function setCurrentToken(token) {
  return localStorage.setItem('plurana', token);
}

export function getLanguageLocally() {
  return localStorage.getItem('i18nextLng') || 'en';
}

export function storeLanguageLocally(lang) {
  return localStorage.setItem('i18nextLng', lang);
}

const emailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

export function isValidEmail(email) {
  return emailPattern.test(email);
}