import './i18n';
import './index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import {ThemeProvider} from '@mui/material/styles';
import {SnackbarProvider, MaterialDesignContent} from 'notistack';
import styled from 'styled-components';

import theme from './theme';
import pluranaRouter from "./router";
import {RouterProvider} from "react-router-dom";
import * as Sentry from "@sentry/react";

const root = ReactDOM.createRoot(document.getElementById('root'));

if (process.env['NODE_ENV'] === "production") {
  Sentry.init({
    dsn: "https://ea018c425cc7411ebecf8feb1e6f8fba@io-sentry-sh.plurana.com/4",
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/app\.plurana\.com\/api/],
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 1.0,
  });
}

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: '#1A1A19',
    borderRadius: '12px',
    fontWeight: 350,
    fontSize: '14px',
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'center'
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: '#970C0C',
  },
}));

const Root = () => {

  return (
      <ThemeProvider theme={theme}>
        <CssBaseline/>
        <SnackbarProvider
            hideIconVariant
            Components={{
              success: StyledMaterialDesignContent,
              error: StyledMaterialDesignContent
            }}
            maxSnack={1}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
        >
          <RouterProvider router={pluranaRouter} />
        </SnackbarProvider>
      </ThemeProvider>
  )
}
root.render(<Root/>);