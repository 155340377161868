import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Modal} from "@mui/material";
import Button from "@mui/material/Button";
import * as React from "react";
import {makeStyles} from "@mui/styles";
import {getAxiosHeaders} from "../../api/client";
import useAxios from "axios-hooks";
import {capitalize} from "lodash";
import LoadingIcon from "../../icons/LoadingIcon";


const useStyles = makeStyles((theme) => ({
	modalForm: {
		"& .MuiTypography-h5": {
			fontSize: 20
		},
		"&.MuiContainer-root": {
			width: 368,
		},
		backgroundColor: "#FBFAFA",
		borderRadius: 12,
		boxShadow: "0px 4px 14px rgba(204, 204, 204, 0.5)",
		position: "absolute",
		left: "calc(50% - 462px/2)",
		top: "calc(50% - 380px/2)",
	},
	divider: {
		borderBottom: "1px solid #E6E6E5"
	},
	modalHeader: {
		borderTopLeftRadius: 12,
		borderTopRightRadius: 12,
		height: 190,
		backgroundColor: "#CEC3C1",
		backgroundImage: "url(/static/images/tokens-purchase-success-header.svg)",
		backgroundSize: "contain",
		backgroundRepeat: "no-repeat",
		backgroundPositionY: "bottom",
	}
}));


const SubscriptionSuccessModal = ({open, onClose, session_id}) => {

	const classes = useStyles();

	const [{data, loading, error}] = useAxios({
		method: 'POST',
		url: '/api/subscription/check/',
		headers: getAxiosHeaders(),
		data: {session_id: session_id}
	});

	return <Modal open={open} onClose={onClose}>
		<Container component="main" maxWidth="xs"  className={classes.modalForm} disableGutters>
			<Box display="flex" flexDirection="row" justifyContent="center" alignItems="center"
					 px={2} py={1} className={classes.modalHeader}>
			</Box>
			<Box p={2} display="flex" flexDirection="column" alignItems="center">

				{loading && !error ?
					<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" p={5}>
						<Typography variant="h5" mb={1}>Verifying transaction</Typography>
						<LoadingIcon/>
					</Box>

					: error ?
						<>
							<Box px={3} textAlign="center">
								<Typography variant="h5" mb={1}>Looks like something went wrong =(</Typography>
								<Typography variant="subtitle3">Please, contact our support</Typography>
							</Box>

							<Box m={2}>
								<Button variant="contained" color="secondary" onClick={onClose}>Continue with Plurana</Button>
							</Box>
						</>
					: <>
						<Box px={3} textAlign="center">
							<Typography variant="h5" mb={1}>Amazing!</Typography>
							<Typography component="p" py={1} variant="subtitle3">Your plan is {capitalize(data.tier.key)}</Typography>
							<Typography component="p" py={1} variant="subtitle3">You have {data.tier.tokens} tokens monthly</Typography>
							<Typography component="p" py={1} variant="subtitle3">Have fun!🍏</Typography>
						</Box>

						<Box m={2}>
							<Button variant="contained" color="secondary" onClick={onClose}>Continue with Plurana</Button>
						</Box>
					</>
			}
			</Box>
		</Container>
	</Modal>
}

export default SubscriptionSuccessModal;