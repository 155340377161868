import React from 'react';
import {SvgIcon} from "@mui/material";

export function ProfileIcon(props) {
    return (
        <SvgIcon sx={{...props}} viewBox={'0 0 20 20'}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke={'currentColor'}>
                <path d="M18 17C17.6813 15.383 16.4289 14.119 14.8267 13.7974C14.4366 13.719 13.9674 13.719 13.0291 13.719H6.9709C6.03257 13.719 5.56341 13.719 5.17327 13.7974C3.57111 14.119 2.31869 15.383 2 17M14.0388 6.07619C14.0388 8.3274 12.2306 10.1524 10 10.1524C7.76943 10.1524 5.9612 8.3274 5.9612 6.07619C5.9612 3.82497 7.76943 2 10 2C12.2306 2 14.0388 3.82497 14.0388 6.07619Z" stroke="currentColor" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </SvgIcon>
    );
}
