import React, {useEffect, useState, useRef} from "react";
import {Box, Container, LinearProgress, useMediaQuery, useTheme, Typography} from "@mui/material";
import useAxios from "axios-hooks";

import _ from "lodash";
import {getAxiosHeaders} from "../../api/client";
import ShopItem from "../../components/Shop/ShopItem";
import StackGrid from "react-stack-grid";

import "./ParentReconstruction.scss";


const ParentReconstruction = (props) => {
    const setId = props.setId

	const [shopItems, setShopItems] = useState([]);

	const [{data, loading}] = useAxios({
		url: '/api/products/',
		headers: getAxiosHeaders(),
		params: {set_id: setId, type: 'reconstruction'},
	}, {useCache: false});

	useEffect(() => {
		if (data?.results?.length > 0) {
			setShopItems((items) => items ? _.concat(items, data.results) : data.results);
		} // eslint-disable-next-line
	}, [data]);

    const theme = useTheme();
	const gridRef = useRef(null);

	const tablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
	const mobile = useMediaQuery(theme.breakpoints.down("sm"));
	const isDesktopLg = useMediaQuery('(min-width: 1400px)');

	let columnWidth = "25%";
	let gutterWidth = 16;

	if (isDesktopLg) {
		columnWidth = '20%';
	}

	if (tablet || mobile) {
		columnWidth = "50%";
		gutterWidth = 10
	}

	if (!loading && !shopItems.length) {
		return null;
	}

	return <>
		<Container maxWidth={false} disableGutters>
			<Box className={'Shop__collection__box ParentReconstructionBox'} style={{marginTop: '32px'}}>
				<Box className={'Shop__collection__list'}>
					<Typography className={'Title__collection'}>Related vectorized artwork</Typography>
					<Typography className={'Description__collection'}>This image is based on the artwork below.</Typography>
					<StackGrid
						className={'GenerativeListGrid'}
						gridRef={grid => gridRef.current = grid}
						columnWidth={columnWidth}
						itemComponent="div"
						duration={0}
						appearDelay={0}
						gutterWidth={gutterWidth}
						gutterHeight={24}
					>
						{_.map(shopItems, (item) => <ShopItem maxWidth={300} key={item.id} item={item} />)}
						{loading &&
							Array.from(Array(3), (e, i) => (
								<ShopItem skeleton key={i} item={{id: i}}/>
							))
						}
					</StackGrid>
				</Box>
			</Box>
		</Container>
	</>

}

export default ParentReconstruction;