import {Box} from "@mui/material";
import PopupState, {bindHover, bindPopover} from "material-ui-popup-state";
import HoverPopover from "material-ui-popup-state/HoverPopover";
import React from "react";
import './OriginalLabel.scss';
import {PluranaButton} from "../Base";

export const OriginalLabel = ({item}) => {
    return (
        <PopupState variant="popover" popupId={"original-popover-" + item.id}>
            {(popupState) => (
                <div>
                    <Box className={'OriginalLabel'} {...bindHover(popupState)}>
                        <img src={item.original_image} alt={''} className={'OriginalLabel__Image'}/>
                        <div>Historic source</div>
                    </Box>
                    <HoverPopover
                        transitionDuration={200}
                        {...bindPopover(popupState)}
                        className={'OriginalPopover-popover'}
                        anchorOrigin={{
                            vertical: 0,
                            horizontal: "center"
                        }}
                        transformOrigin={{
                            vertical: "bottom",
                            horizontal: "center"
                        }}
                    >
                        <Box className={'OriginalPopover__Wrapper'}>
                            <Box className={'OriginalPopover'}>
                                <Box className={'OriginalPopover__Header'}>
                                    <img className={'OriginalPopover__Image'} alt={''} src={item.original_image}/>
                                    <PluranaButton showBorder href={`/shop/${item.type}/${item.id}-${item.slug}`}>Learn more</PluranaButton>
                                </Box>
                                <Box className={'OriginalPopover__Artwork'}>
                                    <div className={'OriginalPopover__Label'}>Original artwork</div>
                                    {item.original_title}
                                </Box>
                                <Box className={'OriginalPopover__Description'}>
                                    <Box className={'OriginalPopover__Description-item'}>
                                        <div className={'OriginalPopover__Label'}>Dated</div>
                                        {item.period_text}
                                    </Box>
                                    <Box className={'OriginalPopover__Description-item'}>
                                        <div className={'OriginalPopover__Label'}>Museum</div>
                                        {item.museum}
                                    </Box>
                                    <Box className={'OriginalPopover__Description-item'}>
                                        <div className={'OriginalPopover__Label'}>Geography</div>
                                        {item.geography_text}
                                    </Box>
                                    <Box className={'OriginalPopover__Description-item'}>
                                        <div className={'OriginalPopover__Label'}>Medium</div>
                                        {item.medium}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </HoverPopover>
                </div>
            )}
        </PopupState>
    )
}