import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {useContext, useState} from "react";
import AuthContext from "../../contexts/AuthContext";
import {IconButton, Modal, InputAdornment} from "@mui/material";
import {makeStyles} from "@mui/styles";
import useAxios from "axios-hooks";
import {getAxiosHeaders} from "../../api/client";
import {setCurrentToken} from "../../helpers/profile";
import { Mail02 } from "untitledui-js/icons/communication"
import { Lock02 } from "untitledui-js/icons/security"
import {XClose} from "untitledui-js/icons/general";


const useStyles = makeStyles((theme) => ({
	buttonClose: {
		position: 'absolute',
        width: '32px',
        height: '32px',
        backgroundColor: 'white',
        top: '-12px',
        right: '-12px',
        boxShadow: '0 4px 14px 0 rgba(17, 17, 17, 0.10)'
	},

	modalForm: {
		backgroundColor: "#ffffff",
		borderRadius: 24,
		boxShadow: "0px 5px 14px 0px rgba(16, 24, 40, 0.03), 0px 4px 24px 4px rgba(16, 24, 40, 0.07)",
		position: "absolute",
		left: "calc(50% - 400px/2)",
		top: "calc(50% - 600px/2)",
		[theme.breakpoints.down('sm')]: {
			left: 0,
		},

		"& .MuiTypography-caption": {
			[theme.breakpoints.down('lg')]: {
				fontSize: 12,
			},
		}
	},
	textField: {
		"& .MuiInputBase-root": {
			background: 'none',
			border: '1px solid #CCCCCC',
			boxShadow: 'none',

			"& input": {
				padding: '10px 14px 10px 0',
				fontSize: 14,
				fontWeight: 600,
				color: '#1A1A19',
				
				'&::placeholder': {
					color: '#999999',
					opacity: 1
				  }
			}
		}
	}
}));

export default function SignUpForm({open, onClose, callback}) {

	const auth = useContext(AuthContext);
	const classes = useStyles();

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const [login, setLogin] = useState("");
	const [password, setPassword] = useState("");

	const [{}, doCreateUser] = useAxios({
		method: 'POST',
		url: '/api/signup/',
		headers: getAxiosHeaders(),
	}, {manual: true});

	const createUser = (email, password) => {
		return doCreateUser({data: {email, password1: password, password2: password}});
	}

	const handleOpenSignInForm = (e) => {
		e.preventDefault();
		auth.signInForm(callback);
	}

	const handleSubmit = (event) => {
		event.preventDefault();
		setLoading(true);
		createUser(login, password).catch(({response}) => {
			if (response?.status === 400) {
				setError("Wrong credentials. Please try again");
			}
			setLoading(false);
		}).then((response) => {
			setLoading(false);
			if (response?.status === 201 && response?.data.token) {
				setCurrentToken(response.data.token);
				auth.fetch();
				onClose();
				callback ? callback() : auth.welcomeModal()
			}
		});
	};

	return (
		<Modal open={open} onClose={onClose}>
			<Box  width="400px" className={classes.modalForm} disableGutters>
	
				<IconButton onClick={onClose} style={{
					position: 'absolute',
					width: '32px',
					height: '32px',
					backgroundColor: 'white',
					borderRadius: '100%',
					top: '-12px',
					right: '-12px',
					boxShadow: '0 4px 14px 0 rgba(17, 17, 17, 0.10)'
				}}>
					<XClose size={'16px'}/>
				</IconButton>
	
				<Box sx={{
					display: "flex",
					alignItems: "center",
					marginTop: '35px',
					flexDirection: "column",
				}}>
					<img src="/static/images/sign.svg" width={'68px'} height={'68px'} alt="" />
					<Typography sx={{
						fontSize: '20px',
						fontWeight: 600,
						lineHeight: '100%',
						marginTop: '24px',
					}}>Register</Typography>
				</Box>
	
				<Box component="form" onSubmit={handleSubmit} noValidate p={'32px 32px 48px 32px'} display="flex" flexDirection="column" alignItems="flex-start">
					<Typography sx={{
						alignItems: 'flex-start',
						fontSize: '14px',
						fontWeight: 350,
						lineHeight: '100%',
						color: '#4D4D4C',
						margin: '0 0 8px 0'
					}}>
						Email
					</Typography>
					<TextField
						className={classes.textField}
						sx={{
							margin: 0,
						}}
						margin="normal"
						variant="outlined"
						fullWidth
						id="email"
						placeholder='olivia@plurana.com'
						name="email"
						autoComplete="email"
						autoFocus
						disabled={loading}
						onChange={(event) => {
							setLogin(event.target.value);
						}}
						InputProps={{
							startAdornment: (
							  <InputAdornment position="start">
								<Mail02 size='20px' stroke={'#999999'} />
							  </InputAdornment>
							),
						}}
					/>
					<Typography sx={{
						alignItems: 'flex-start',
						fontSize: '14px',
						fontWeight: 350,
						lineHeight: '100%',
						color: '#4D4D4C',
						margin: '16px 0 8px 0'
					}}>
						Password
					</Typography>
					<TextField
						className={classes.textField}
						style={{
							margin: 0,
						}}
						margin="normal"
						variant="outlined"
						fullWidth
						name="password"
						type="password"
						placeholder='•••••••••••••••'
						id="password"
						disabled={loading}
						autoComplete="current-password"
						onChange={(event) => {
							setPassword(event.target.value);
						}}
						InputProps={{
							startAdornment: (
							  <InputAdornment position="start">
								<Lock02 size='20px' stroke={'#999999'} />
							  </InputAdornment>
							),
						}}
					/>
					{error ? error : null}
					<Button
						type="submit"
						// variant="contained"
						disabled={loading}
						style={{
							marginTop: '32px', 
							width: '100%',
							borderRadius: '12px',
							height: '40px',
							color: '#ffffff',
							background: '#FF33D3',
							":hover": 'none',
							fontSize: '14px',
							fontWeight: 600,
						}}
						onClick={handleSubmit}
					>
						Start creating
					</Button>
	
					<Button
						variant="contained"
						disabled={loading}
						style={{ 
							marginTop: "12px", 
							width: '100%',
							borderRadius: '12px',
							height: '40px',
							color: '#666666',
							background: '#F2F2F2',
							fontSize: '14px',
							fontWeight: 600
						}}
						onClick={handleOpenSignInForm}
					>
						I already have an account
					</Button>
				</Box>
			</Box>
		</Modal>
	);
}