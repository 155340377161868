import {Box, Container} from "@mui/material";
import {makeStyles} from "@mui/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	oops: {
		height: "calc(100vh - 300px)",
	}
}));

const NotFoundPage = () => {

	const classes = useStyles();

	return <Container maxWidth={false}>
		<Box display="flex" flexDirection="column" justifyContent="center"
				 alignItems="center" className={classes.oops} gap={10}>
			<Box display="flex" flexDirection="column" alignItems="center" gap={2}>
				<Typography variant="h1">
					Looks like there is nothing here!
				</Typography>
				<Typography variant="subtitle">
					Page does not exists or was removed
				</Typography>
			</Box>

			<Button component={RouterLink} to="/" variant="contained" color="secondary">
				Go to generator
			</Button>

		</Box>

	</Container>
}

export default NotFoundPage;