import {Button, Grid} from "@mui/material";
import React from "react";

const SearchButtons = ({current}) =>
	<Grid container pt={4} justifyContent="space-around">
		<Grid item>
			<Button variant={current === "collections" ? "search" : "text"} color="primary" sx={{height: 35}}>Collections</Button>
		</Grid>
		<Grid item>
			<Button variant={current === "vectorized" ? "search" : "text"} color="primary" sx={{height: 35}}>Vectorized&nbsp;Art</Button>
		</Grid>
		<Grid item>
			<Button variant="text" color="primary" sx={{height: 35}} disabled>Patterns</Button>
		</Grid>
		<Grid item >
			<Button variant="text" color="primary" sx={{height: 35}} disabled>Prints</Button>
		</Grid>
		<Grid item>
			<Button variant="text" color="primary" sx={{height: 35}} disabled>Slides</Button>
		</Grid>
	</Grid>

export default SearchButtons;