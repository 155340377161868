import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import React, {useEffect} from "react";
import {Box, Container, LinearProgress} from "@mui/material";
import {makeStyles} from "@mui/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {Link as RouterLink } from "react-router-dom";
import useAxios from "axios-hooks";
import {getAxiosHeaders} from "../../api/client";


const useStyles = makeStyles((theme) => ({
	root: {
		height: "calc(100vh - 300px)",
	}
}));


const CheckPaymentPage = () => {

	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();

	const [{loading, error}, doCheckPayment] = useAxios({
		method: 'POST',
		url: '/api/payment/check/',
		headers: getAxiosHeaders(),
		data: {session_id: searchParams.get("payment_session_id")}
	}, {manual: true, autoCancel: false});

	const checkPayment = async () => {
		try {
			const {data} = await doCheckPayment();
			if (data.status === "success") {
				navigate(`/shop/order/${data.order_number}`);
			}
		} catch (e) {
			console.log(e)
		}
	}

	useEffect(() => {
		if (searchParams?.get("payment_session_id")) {
			checkPayment();
		}
	}, [searchParams])

	const classes = useStyles();

	return <Container maxWidth={false}>
		<Box display="flex" flexDirection="column" justifyContent="center"
				 alignItems="center" className={classes.root} gap={10}>
			<Box display="flex" flexDirection="column" alignItems="center" gap={2}>
				<Typography variant="h1">
					Checking your payment
				</Typography>
				<Typography variant="subtitle">
					<LinearProgress style={{width: '100%'}} color="secondary"/>
				</Typography>
			</Box>
		</Box>

	</Container>
}

export default CheckPaymentPage;