import React, {useContext, useState} from "react";
import {Box, Button, Grid, IconButton, ImageListItem, ImageListItemBar} from "@mui/material";
import {makeStyles} from "@mui/styles";
import * as Sentry from "@sentry/react";
import {useSnackbar} from "notistack";
import useAxios from "axios-hooks";
import {getAxiosHeaders} from "../../api/client";
import AuthContext from "../../contexts/AuthContext";
import SplitButton from "../Base/SplitButton";
import {Loading01, HeartRounded} from "untitledui-js/icons/general";
import {useScreenType} from "../../hooks/useScreenType";
import './GenerativeItem.scss'


function getRandomArbitrary(min, max) {
	return Math.random() * (max - min) + min;
}


const amplitude = window.amplitude;

const useStyles = makeStyles(() => ({

	generativeItem: {

		"& img": {
			borderRadius: 16,
		},
		"& .dimmed": {
			filter: "blur(3.6px) grayscale(1)"
		},
		"&:hover": {
			"& .MuiImageListItemBar-root": {
				opacity: 1,
			}
		},
		"& .MuiImageListItemBar-root": {
			transition: 'all 0.3s ease-out',
			opacity: 0,
			display: 'flex',
			borderRadius: 16,
			flexDirection: "column",
			background: "linear-gradient(180deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.00) 100%);",
			width: "100%",
			height: "100%",

			"& .MuiImageListItemBar-titleWrap": {
				width: "100%",
				padding: "12px 16px"
			},
			"& .MuiImageListItemBar-actionIcon": {
				width: "100%",
				textAlign: "right",
				padding: "12px 16px"
			},
		}
	},
	downloadCost: {
		background: "linear-gradient(125.04deg, #FFE5F9 29.39%, rgba(255, 255, 255, 0.96) 140.78%)",
		borderRadius: 24,
		minWidth: 100,
		color: "#FF66DE",
		fontSize: 14,
		fontWeight: 450,
		textAlign: "center"
	},
}));


const GenerativeItem = ({data, preview, canDimDisliked = false, skeleton=false, id}) => {

	const classes = useStyles();
	const auth = useContext(AuthContext);
	const { enqueueSnackbar } = useSnackbar();
	const [downloading, setDownloading] = useState(false);
	const [liking, setLiking] = useState(false);
	const screenType = useScreenType();

	const [_likeResponse, doToggleLike] = useAxios({
		method: 'POST',
		url: '/api/generator/toggle-like/',
		headers: getAxiosHeaders(),
	}, {manual: true, autoCancel: false});

	// eslint-disable-next-line no-unused-vars
	const [_downloadResponse, doDownload] = useAxios({
		method: 'POST',
		url: '/api/generator/download/',
		headers: getAxiosHeaders(),
	}, {manual: true, autoCancel: false});

	const handleItemActionError = (error, item_id, action) => {
		if (error.response?.data?.error) {
			enqueueSnackbar(error.response.data.error, {autoHideDuration: 3000, variant: "error"})
		}
		Sentry.captureMessage(`Failed to ${action} generative ${item_id}`);
		Sentry.captureException(error);
	}

	const toggleLike = async (generativeID) => {

		setLiking(true);
		data.favorite = !data.favorite;
		try {
			const {data: updated} = await doToggleLike({data: {generative_id: generativeID}})
			if (!data.favorite) {
				amplitude?.track('front.generator.like', {generative_id: generativeID});
			} else {
				amplitude?.track('front.generator.dislike', {generative_id: generativeID});
			}
			data.favorite = updated.favorite;
			setLiking(false);
		} catch (e) {
			handleItemActionError(e, generativeID, "toggle like");
			setLiking(false);
		}
	}

	const download = async (format, generativeID) => {
		if (auth.user) {
			setDownloading(true);
			try {
				const {data: updated} = await doDownload({data: {generative_id: generativeID, format }});
				amplitude?.track('front.generator.download', {generative_id: generativeID});
				auth.updateTokens(updated.tokens);
				window.location.href = updated.link;
				setDownloading(false);
			} catch (e) {
				handleItemActionError(e, generativeID, "download");
				setDownloading(false);
			}
		} else {
			auth.signUpForm();
		}
	}

	if (skeleton) {
		return (
			<div
				className={'GenerativeItem skeleton'}
				style={{
					width: '100%',
					height: ([150, 180, 210])[data.id % 3]
				}}
			/>
		)
	} else {
		return (
			<ImageListItem key={data.id} className={classes.generativeItem + ' GenerativeItem'}>
				<img
					src={data.image}
					loading="lazy"
					alt=""
					width={data.width}
					height={data.height}
					className={(canDimDisliked && !data.favorite && "dimmed") + ' skeleton'}
				/>
				<ImageListItemBar
					title={preview ? null :
						<Grid container directoin="row" justifyContent="flex-end" alignItems={'center'} className={'GenerativeItem__Header'}>
							<Grid item>
								<Grid container columnGap={'8px'}>
									<IconButton className={'GenerativeItem__Header__Like'} disabled={liking} onClick={() => auth.user ? toggleLike(data.id): auth.signUpForm()}>
										<HeartRounded size="18px" fill={data.favorite ? "#1A1A19" : "none"} style={{strokeWidth: 2.5}} />
									</IconButton>
									<SplitButton
										label={screenType === 'mobile' ? 'Download' : undefined}
										options={[
											{value: "svg", label: "SVG"},
											{value: "png", label: "PNG"},
											{value: "webp", label: "WebP"}
										]}
										onClick={(format) => download(format, data.id)}
										onSelect={(format) => download(format, data.id)}
									/>
								</Grid>
							</Grid>
						</Grid>
					}
					actionPosition="right"
					position={screenType === 'desktop' ? 'top' : 'below'}
				/>
			</ImageListItem>
		)
	}
}

export default GenerativeItem;
