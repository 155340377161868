import React from 'react';
import {SvgIcon} from "@mui/material";

export function RedoIcon(props) {
  return (
      <SvgIcon sx={{...props}}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={'currentColor'}>
              <path d="M21 9H7.5C5.01472 9 3 11.0147 3 13.5C3 15.9853 5.01472 18 7.5 18H12M21 9L17 5M21 9L17 13" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
      </SvgIcon>
  );
}
