import {Box, Button, Grid, Typography} from "@mui/material";
import PluranaTokenIcon from "../../icons/PluranaToken";
import FavoriteIcon from "../../icons/FavoriteIcon";
import ShoppingBagIcon from "../../icons/ShoppingBagIcon";
import {getPrice} from "../../helpers/shop";
import {useEffect, useState} from "react";

export const CollectionPreview = (props) => {
  const {collection, compact} = props;

  const [price, setPrice] = useState(null);

  useEffect(() => {
    setPrice(getPrice(collection, "EUR"));
  }, [collection])

  return (
    <Grid container mt={8}>
      <Grid item xs={6}>
        <Typography>{collection.title}</Typography>
      </Grid>
      <Grid item xs={6} textAlign="right">
        <Typography>{collection.collection_size} assets | from {price?.price} {price?.price_currency}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{border:"1px solid #E0E0E0", borderRadius: 2, height: compact ? 210 : 397, overflow: "hidden"}}>
          <Grid container p={2} direction={"row"} columns={15}>
            <Grid item xs={3}>
              <Grid container justifyItems={"center"} alignItems={"center"} direction={"column"} textAlign={"center"}>
                <Grid item sx={{width: 180, height: 66, lineHeight: 1}} mb={1}>
                  <Typography variant={"caption"}>{collection.description}</Typography>
                </Grid>
                {compact ? null :
                  <Grid item>
                    <img src={collection.images[0].original} alt={collection.title} className={"collection-preview-cover"}/>
                  </Grid>}
              </Grid>
            </Grid>

            <Grid item xs={9}>
              {collection.images.length > 1 ?
                <img
                  className={"collection-preview-singleton"}
                  src={collection.images[1].original}
                  alt={collection.title}
                  loading="lazy"
                /> : null}

              {/*<ImageList*/}
              {/*  variant="masonry" cols={5} gap={8} sx={{margin: 0}}*/}
              {/*>*/}
              {/*  {collection.subproducts.slice(0, compact ? 5 : 10).map((item) => (*/}
              {/*    <ImageListItem key={item.id}>*/}
              {/*      <img*/}
              {/*        className={"collection-preview-image"}*/}
              {/*        src={item.images[0].original}*/}
              {/*        alt={collection.title}*/}
              {/*        loading="lazy"*/}
              {/*      />*/}
              {/*    </ImageListItem>*/}
              {/*  ))}*/}
              {/*</ImageList>*/}
            </Grid>
          </Grid>
        </Box>
      </Grid>

      {compact ? null :
        <Grid item xs={12} textAlign="right" py={1.3}>
          <Button variant="contained" color="secondary">
            <PluranaTokenIcon inheritViewBox sx={{height: 15}}/> Generate
          </Button>

          <Button variant="circled" sx={{mx: 1}}>
            <FavoriteIcon/>
          </Button>

          <Button variant="circled">
            <ShoppingBagIcon/>
          </Button>

      </Grid>
      }


    </Grid>
  );
};