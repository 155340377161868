import React, {useEffect, useState, useRef, useLayoutEffect} from "react";
import {Box, Container, LinearProgress, useMediaQuery, useTheme, Typography} from "@mui/material";
import useScrollPosition from "@react-hook/window-scroll";
import useAxios from "axios-hooks";

import _ from "lodash";
import {getAxiosHeaders} from "../../api/client";
import ShopItem from "../../components/Shop/ShopItem";
import ShopSearchBoard from "../../components/Shop/SearchBoard";
import {useParams, useSearchParams} from "react-router-dom";
import StackGrid from "react-stack-grid";
import {useScreenType} from "../../hooks/useScreenType";
import {SHOP_SECTIONS} from "./ShopIndexPage";
import './ShopAllIndexPage.scss'
import SubscriptionCards from "../../components/Subscription/SubscriptionCards";
import {HelpContent} from "../../components/Help/HelpContent";
import SelectIcon from "../../icons/SelectIcon";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {FilterTag} from "../../components/Generator/FilterTag";
import {Check} from "untitledui-js/icons/general";
import NotFoundSmile from './notfoundsmile.png';

const SUGGESTIONS = [
	'Flowers',
	'Roman Empire',
	'Intricate pattern',
	'Authentic',
	'Modern',
	'Minimalistic',
	'Authentic',
];

const ShopAllIndexPage = () => {
	const params = useParams();
	const [searchParams, setSearchParams] = useSearchParams()
	const screenType = useScreenType();

	const [shopItems, setShopItems] = useState([]);
	const [offset, setOffset] = useState(0);
	const [hasMore, setHasMore] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");
	const [searchType, setSearchType] = useState('all');
	const [geoFilters, setGeoFilters] = useState([]);
	const [periodFilters, setPeriodFilters] = useState([]);
	const [selectedFilters, setSelectedFilters] = useState([])
	const [hasResults, setHasResults] = useState(false);

	const scrollY = useScrollPosition(10);

	const getFilterLabel = (filter_id) => {
		if (filter_id.startsWith('geo')) {
			return geoFilters.filter(item => item.id === filter_id)[0].location
		} else if (filter_id.startsWith('period')) {
			return periodFilters.filter(item => item.id === filter_id)[0].period
		} else {
			return 'unknown'
		}
	}

	const getFiltersForQuery = () => {
		if (searchType === 'historic' || params.slug === 'reconstruction') {
			return {
				period: selectedFilters.filter(item => item.startsWith('period')).map(item => getFilterLabel(item)).join(';') || null,
				geography: selectedFilters.filter(item => item.startsWith('geo')).map(item => getFilterLabel(item)).join(';') || null,
				type_period: searchType !== 'all' ? searchType : null
			}
		} else {
			return {
				type_period: searchType !== 'all' ? searchType : null
			}
		}
	}

	const [{data, loading}] = useAxios({
		url: '/api/products/',
		headers: getAxiosHeaders(),
		params: params.slug === "freebies" ?
			{offset: offset, trend: true, limit: 10, query: searchQuery, ...getFiltersForQuery()}
			:
			{offset: offset, type: params.slug, limit: 10, query: searchQuery, ...getFiltersForQuery()},
	}, {useCache: false});

	const [{data: geoFiltersData, loading: geoFiltersLoading}] = useAxios({
		url: '/api/geographies/',
		headers: getAxiosHeaders(),
		params: {limit: 100},
	})

	const [{data: periodFiltersData, loading: periodFiltersLoading}] = useAxios({
		url: '/api/periods/',
		headers: getAxiosHeaders(),
		params: {limit: 100},
	})

	const onFiltersChange = (filter_id) => {
		if (!selectedFilters.includes(filter_id)) {
			setSelectedFilters(selectedFilters => [...selectedFilters, filter_id])
		} else {
			setSelectedFilters(selectedFilters => selectedFilters.filter(item => item !== filter_id))
		}
	}

	const getSelectedFiltersCount = (type) => {
		return selectedFilters.filter(item => item.startsWith(type)).length
	}

	const isFilterSelected = (filter_id) => {
		return selectedFilters.includes(filter_id);
	}

	const fetchNextPage = () => {
		setOffset(shopItems ? shopItems.length : 0)
	}

	useEffect(() => {
		setShopItems([]);
		setOffset([]);
		setHasMore(true);
	}, [searchQuery, selectedFilters, searchType, params.slug]);

	useEffect(() => {
		if (geoFiltersData?.results?.length > 0) {
			setGeoFilters(geoFiltersData.results.map(item => (
				{...item, id: `geo-${item.id}`, original_id: item.id}
			)))
		}
	}, [geoFiltersData])

	useEffect(() => {
		if (periodFiltersData?.results?.length > 0) {
			setPeriodFilters(periodFiltersData.results.map(item => (
				{...item, id: `period-${item.id}`, original_id: item.id}
			)))
		}
	}, [periodFiltersData])

	useEffect(() => {
		const query = searchParams.get('q');
		if (query) {
			setSearchQuery(query);
		}
	}, [searchParams.get('q')])

	useEffect(() => {
		setShopItems([]);
	}, [params.slug])

	useLayoutEffect(() => {
		if (data?.results?.length > 0) {
			setHasMore(data.count ? offset + data.results.length < data.count : false);
			setShopItems((items) => items ? _.concat(items, data.results) : data.results);
		} else {
			setHasMore(false)
		} // eslint-disable-next-line
	}, [data]);

	useEffect(() => {
		if (hasMore && !loading) {
			const elem = document.getElementById('shop-collection').getBoundingClientRect()
			if (elem.top < 0) {
				fetchNextPage();
			}
			// console.log(elem)
			// const bottom = Math.abs(window.innerHeight + scrollY - document.body.scrollHeight) < 220;
			// if (bottom) {
			// 	fetchNextPage();
			// }
		} // eslint-disable-next-line
	}, [scrollY]);

	useEffect(() => {
		gridRef.current?.updateLayout()
	}, [shopItems, offset])

    const theme = useTheme();
	const gridRef = useRef(null);

	const tablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
	const mobile = useMediaQuery(theme.breakpoints.down("sm"));
	const isDesktopLg = useMediaQuery('(min-width: 1400px)');

	let columnWidth = "25%";
	let gutterWidth = 16;

	if (isDesktopLg) {
		columnWidth = '20%';
	}

	if (tablet || mobile) {
		columnWidth = "50%";
		gutterWidth = 10
	}

	return (
		<Container maxWidth={false} disableGutters style={{marginTop: '64px'}}>
			<Box className={'SearchPage__Search'}>
				<Box className={'SearchPage__Header'}>
					{!(searchQuery) ?
						<>
							<Typography className={'SearchPage__Title'}>{SHOP_SECTIONS[params.slug].title}</Typography>
							{SHOP_SECTIONS[params.slug].description ?
								<Typography className={'SearchPage__Description'}>{SHOP_SECTIONS[params.slug].description}</Typography>
								:
								null
							}
						</>
						:
						loading || shopItems.length ?
							<Typography className={'SearchPage__Description'}>Search results for <span id={'searchQuery'}>{searchQuery}</span></Typography>
							:
							<Typography className={'SearchPage__Description'}>
								<img src={NotFoundSmile} alt={''} style={{height: '29px', marginRight: 2, marginBottom: '-4px'}}/> Sorry, nothing found for <span id={'searchQuery'}>{searchQuery}</span>
							</Typography>
					}
				</Box>

				<Box sx={{ flexGrow: 1, width: '100%' }}>
					<ShopSearchBoard searchQuery={searchQuery} setSearchQuery={setSearchQuery} variant={'default'}/>
				</Box>

				<Box className={'SearchPage__Filters'}>
					<Box className={'SearchPage__Filters--row'}>
						{params.slug !== 'reconstruction' &&
							<Box className={'RadioGroup'}>
								<Box
									className={'PluranaRadio' + (searchType === 'all' ? ' PluranaRadio-selected': '')}
									onClick={() => setSearchType('all')}
								>
									<div className={'PluranaRadio--radio'}/>
									All results
								</Box>
								<Box
									className={'PluranaRadio' + (searchType === 'historic' ? ' PluranaRadio-selected': '')}
									onClick={() => setSearchType('historic')}
								>
									<div className={'PluranaRadio--radio'}/>
									Historic
								</Box>
								<Box
									className={'PluranaRadio' + (searchType === 'modern' ? ' PluranaRadio-selected': '')}
									onClick={() => setSearchType('modern')}
								>
									<div className={'PluranaRadio--radio'}/>
									Modern
								</Box>
							</Box>
						}
					</Box>

					{(['reconstruction'].includes(params.slug) || searchType === 'historic') &&
						<>
							<Box className={'SearchPage__Filters--row'}>
								<Select
									sx={{'& .MuiSvgIcon-root': {
											marginTop: '6px',
											marginRight: '4px',
											height: '20px',
											width: '20px',
											'& path': {stroke: 'var(--plurana-neutrals-500)'}
										}}}
									variant={'outlined'}
									className={'SearchPage__Select'}
									IconComponent={SelectIcon}
									value={['']}
									multiple
									disabled={!geoFilters.length}
									MenuProps={{className: 'SearchFilterPopover'}}
									renderValue={() => (
										<div className={'SearchPage__Select-value'}>
											All regions
											{getSelectedFiltersCount('geo') > 0 &&
												<span className={'PluranaCounter'}>
													{getSelectedFiltersCount('geo')}
												</span>
											}
										</div>
									)}
								>
									{geoFilters.map(item => (
										<MenuItem
											className='SearchPage__Select--item'
											key={item.id}
											value={''}
											onClick={() => onFiltersChange(item.id)}
										>
											<div style={{display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between'}}>
												{item.location}{isFilterSelected(item.id) && <Check stroke={'var(--plurana-shades-600)'}/>}
											</div>
										</MenuItem>
									))}
								</Select>
								<Select
									sx={{'& .MuiSvgIcon-root': {
											marginTop: '6px',
											marginRight: '4px',
											height: '20px',
											width: '20px',
											'& path': {stroke: 'var(--plurana-neutrals-500)'}
										}}}
									variant={'outlined'}
									className={'SearchPage__Select'}
									value={['']}
									multiple
									IconComponent={SelectIcon}
									disabled={!periodFilters.length}
									MenuProps={{className: 'SearchFilterPopover'}}
									renderValue={() => (
										<div className={'SearchPage__Select-value'}>
											All historic periods
											{getSelectedFiltersCount('period') > 0 &&
												<span className={'PluranaCounter'}>
													{getSelectedFiltersCount('period')}
												</span>
											}
										</div>
									)}
								>
									{periodFilters.map(item => (
										<MenuItem
											className='SearchPage__Select--item'
											key={item.id}
											value={''}
											onClick={() => onFiltersChange(item.id)}
										>
											<div style={{display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between'}}>
												{item.period}{isFilterSelected(item.id) && <Check stroke={'var(--plurana-shades-600)'}/>}
											</div>
										</MenuItem>
									))}
								</Select>
							</Box>
							{selectedFilters.length ?
								<Box className={'SearchPage__Filters--row SearchPage__Filters--row-tags'}>
									{selectedFilters.map(item => (
										<FilterTag key={item} label={getFilterLabel(item)} selected setSelected={() => onFiltersChange(item)}/>
									))}
								</Box>
								: null
							}
						</>
					}
					{((searchQuery || selectedFilters.length) && !loading && !shopItems.length) ?
						<Box className={'SearchPage__Filters--row SearchPage__Filters--row-tags SearchPage__Filters--row-suggestions'}>
							<Box className={'SearchPage__SuggestionsTitle'}>
								Try these popular searches instead
							</Box>
							{SUGGESTIONS.map((item, i) => (
								<FilterTag key={i} label={item} setSelected={() => {
									setSearchQuery(item);
									setSelectedFilters([]);
								}}/>
							))}
						</Box>
						:
						null
					}
				</Box>
			</Box>

			<Box className={'Shop__collection__box Shop__collection__box-smpadding'} id={'shop-collection'}>
				<Box className={'Shop__collection__list'}>
					<StackGrid
						gridRef={grid => gridRef.current = grid}
						columnWidth={columnWidth}
						itemComponent="div"
						duration={0}
						appearDelay={0}
						gutterWidth={gutterWidth}
						gutterHeight={24}
					>
						{_.map(shopItems, (item) => <ShopItem key={item.id} item={item} />)}
						{loading &&
							Array.from(Array(10), (e, i) => (
								<ShopItem skeleton key={i} item={{id: i}}/>
							))
						}
						{!shopItems.length && <div style={{height: 2}}/>}
					</StackGrid>
					<Typography className={'Subsription__title'}>Subscription options</Typography>
					<SubscriptionCards />
				</Box>
			</Box>
			<Box marginTop='96px' style={{width: '100%'}}>
				<HelpContent />
			</Box>

		{/*{loading ? <Box p={2}><LinearProgress style={{width: '100%'}} color="secondary"/></Box> : null }*/}
		</Container>
	)
}

export default ShopAllIndexPage;