import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Modal} from "@mui/material";
import Button from "@mui/material/Button";
import * as React from "react";
import {makeStyles} from "@mui/styles";
import {useContext} from "react";
import AuthContext from "../../contexts/AuthContext";


const useStyles = makeStyles((theme) => ({
	modalForm: {
		"& .MuiTypography-h5": {
			fontSize: 20
		},
		"&.MuiContainer-root": {
			width: 368,
		},
		backgroundColor: "#FBFAFA",
		borderRadius: 12,
		boxShadow: "0px 4px 14px rgba(204, 204, 204, 0.5)",
		position: "absolute",
		left: "calc(50% - 368px/2)",
		top: "calc(50% - 497px/2)",
	},
	textField: {
		"& .MuiFormLabel-root": {
			fontSize: 16,
		},
	},
	divider: {
		borderBottom: "1px solid #E6E6E5"
	},
	quantityBox: {
		minWidth: 50,
		padding: 8,
		height: 40,
		textAlign: "center",
		background: "#FFFFFF",
		color: "#B3B3B2",
		fontSize: 14,
		border: "2px solid #FFCCF4",
		borderRadius: 6,
		margin: 10
	},
	filled: {
		"&.MuiButton-circled": {
			width: 40,
			height: 40,
			maxWidth: 40,
			maxHeight: 40,
			color: "#ffffff",
			"&.Mui-disabled": {
				color: "#ffffff",
				backgroundColor: "#E6E6E5",
			},
			"&:hover": {
				backgroundColor: "#9a9a9a",
			},
			backgroundColor: "#B3B3B2",
		},
	},
	modalHeader: {
		// borderBottom: "1px solid #E6E6E5",
		height: 120,
		backgroundImage: "url(/static/images/tokens-purchase-header.svg)",
		backgroundSize: "auto 80px",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center center",
	}
}));


const TokensPurchaseModal = ({open, onClose, quantity, setQuantity, showModal}) => {

	const Paddle = window.Paddle;
	const classes = useStyles();
	const auth = useContext(AuthContext);

	const handleSuccessPayment = ({checkout, product}) => {
		auth.updateTokens(
			auth.user.tokens + product.quantity
		)
		showModal("success")
	}

	const handleBuyTokens = () => {
		onClose();

		Paddle.Checkout.open({
			product: 53597,
			title: `${quantity} Plurana Tokens`,
			method: "overlay",
			quantity: quantity,
			allowQuantity: 0,
			disableLogout: false,
			email: auth.user.email,
			passthrough: `{"user_id": "${auth.user.id}"}`,
			successCallback: handleSuccessPayment,
		});
	}

	const handleIncreaseQuantity = () => {
		if (quantity <= 1000)  setQuantity(quantity + 100);
	}
	const handleDecreaseQuantity = () => {
		if (quantity >= 100) setQuantity(quantity - 100);
	}

	return <Modal open={open} onClose={onClose}>
		<Container component="main" maxWidth="xs" className={classes.modalForm} disableGutters>
			<Box display="flex" flexDirection="row" justifyContent="center" alignItems="center"
					 px={2} py={1} className={classes.modalHeader}>
			</Box>
			<Box p={2} display="flex" flexDirection="column" alignItems="center">
				<Box px={3} textAlign="center">
					<Typography variant="h5" mb={1}>Get more tokens to continue creating</Typography>
					<Typography variant="subtitle3">{
						auth.user && auth.user.tokens === 0 ?
							"You ran out of your tokens. No worries, we got you!" : "More tokens for the tokens god!"
					}</Typography>
				</Box>

				<Box my={2} className={classes.divider}></Box>

				<Typography variant="subtitle1">{quantity / 10} $</Typography>

				<Box className={classes.divider}></Box>

				<Box display="flex" flexDirection="row" alignItems="center">

					<Button variant="circled" className={classes.filled} disabled={quantity <= 100} onClick={handleDecreaseQuantity}>
						-
					</Button>
					<Box className={classes.quantityBox}>{quantity}</Box>
					<Button variant="circled" className={classes.filled}  disabled={quantity >= 1000} onClick={handleIncreaseQuantity}>
						+
					</Button>
				</Box>

				<Box m={5}>
					<Button variant="contained" color="secondary" onClick={handleBuyTokens}>Continue</Button>
				</Box>

			</Box>
		</Container>
	</Modal>
}

export default TokensPurchaseModal;