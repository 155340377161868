import React from 'react';
import {keyframes} from '@emotion/react';
import {SvgIcon} from "@mui/material";


const LoadingIcon = () => {

	const variant1 = keyframes`
    0%, 100% {
			opacity: 0;
    }
    50% {
      opacity: 1;
    }`;
	const variant2 = keyframes`
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }`;

	const iconStyles = {
		"& .variant-1": {
			animation: `${variant1} 0.3s infinite`,
		},
		"& .variant-2": {
			animation: `${variant2} 0.3s infinite`,
		},
		width: 61,
		height: 81,
  };

	return <SvgIcon sx={iconStyles} viewBox="0 0 61 81">
			<path className="variant-1" d="M61 40.4999C61 57.3446 47.3446 71 30.4999 71C13.6553 71 0 57.3446 0 40.4999C0 23.6553 13.6553 10 30.4999 10C47.3446 10 61 23.6553 61 40.4999Z" fill="#FF99E9"/>
			<path className="variant-1" d="M52 40.5001C52 52.3741 42.3741 62 30.5001 62C18.6259 62 9 52.3741 9 40.5001C9 28.6259 18.6259 19 30.5001 19C42.3741 19 52 28.6259 52 40.5001Z" fill="#FF66DE"/>
			<path className="variant-1" d="M44 40.5C44 47.9558 37.9558 54 30.5 54C23.0441 54 17 47.9558 17 40.5C17 33.0442 23.0441 27 30.5 27C37.9558 27 44 33.0442 44 40.5Z" fill="#FF2BD1"/>

			<path className="variant-2" d="M61 40.5001C61 57.3447 47.3447 71 30.5001 71C13.6553 71 0 57.3447 0 40.5001C0 23.6553 13.6553 10 30.5001 10C47.3447 10 61 23.6553 61 40.5001Z" fill="#FF99E9"/>
			<path className="variant-2" d="M52 40.5C52 52.3741 42.3741 62 30.5 62C18.6259 62 9 52.3741 9 40.5C9 28.6259 18.6259 19 30.5 19C42.3741 19 52 28.6259 52 40.5Z" fill="#FF66DE"/>
			<path className="variant-2" d="M44 40.5C44 47.9558 37.9558 54 30.5 54C23.0441 54 17 47.9558 17 40.5C17 33.0442 23.0441 27 30.5 27C37.9558 27 44 33.0442 44 40.5Z" fill="#FF33D3"/>
	</SvgIcon>;
};


<svg width="61" height="81" viewBox="0 0 61 81" fill="none" xmlns="http://www.w3.org/2000/svg">
</svg>


export default LoadingIcon;