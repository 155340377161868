import {Box, Button, Fade} from "@mui/material";
import {makeStyles} from "@mui/styles";
import React, {useContext, useState, useEffect} from "react";
import Typography from "@mui/material/Typography";
import {currencySymbol, getPrice} from "../../helpers/shop";
import AuthContext from "../../contexts/AuthContext";
import {useSnackbar} from "notistack";
import useAxios from "axios-hooks";
import {getAxiosHeaders} from "../../api/client";
import { HeartRounded } from "untitledui-js/icons/general"
import {MagicWand02} from 'untitledui-js/icons/editor'
import {ShoppingCart03} from 'untitledui-js/icons/finance'
import * as Sentry from "@sentry/react";
import CartContext from "../../contexts/CartContext";
import {Link, useNavigate} from "react-router-dom";
import './ShopItem.scss';
import {useScreenType} from "../../hooks/useScreenType";
import PopupState, {bindHover, bindPopover} from "material-ui-popup-state";
import HoverPopover from "material-ui-popup-state/HoverPopover";
import {PluranaButton} from "../Base";
import {OriginalLabel} from "./OriginalLabel";

const amplitude = window.amplitude;

const useStyles = makeStyles((theme) => ({
	photo: {
		width: "100%",
		height: "100%",

		"&:hover": {
			backgroundColor: 'rgba(0, 0, 0, 1)'
		}
	},
	titleReconstruction: {
    "&.MuiTypography-root": {
      color: "rgba(26, 26, 25, 1)",
      fontSize: "14px",
			letterSpacing: "0.01em",
      fontWeight: 600,
      lineHeight: "100%"
    }
  },
	priceReconstruction: {
		"&.MuiTypography-root": {
			color: "#9FA3AB",
			fontSize: "14px",
			fontWeight: 600,
			minWidth: '80px',
			lineHeight: "100%",
			letterSpacing: '0.01em',
		}
	},
	itemContainer: {
		cursor: 'pointer',
		textDecoration: 'none',
		"&:hover .toolbox": {
			opacity: 1,
		},
		"& .toolbox": {
			opacity: 0,
			zIndex: 100,
			position: "absolute",
			top: '10px',
			right: '10px',
			display: "flex",
			flexDirection: "row",
			gap: '4px'
		}
	},
	addToCartButton: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		cursor: "pointer",
		padding: "14px 12px",
		height: '38px',
		borderRadius: 'var(--plurana-button-border-radius)',
		backgroundColor: "#FFF",
		textDecoration: 'none',
	},
	generateButton: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		cursor: "pointer",
		padding: "14px 12px",
		borderRadius: 'var(--plurana-button-border-radius)',
		height: '38px',
		backgroundColor: "#1A1A19"
	},
	favoriteButton: {
		paddingTop: '1px',
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		cursor: "pointer",
		borderRadius: 'var(--plurana-button-border-radius)',
		height: '38px',
		width: '38px',
		backgroundColor: "#fff"
	},
	cover: {
		"&:hover .original": {
			transform: "rotate(-3deg)",
			bottom: "13px",
			left: "14px",
		},
		"& .original": {
			position: "absolute",
			bottom: theme.spacing(1.5),
			left: theme.spacing(1.5),
			width: 80,
			height: 80,
			backgroundColor: "#29282E",
			backgroundPosition: "center",
			backgroundRepeat: "no-repeat",
			backgroundSize: "cover",
			borderRadius: 12,
			transitionProperty: "transform position",
			transitionDuration: "500ms",
		},
 		"& img": {
			borderRadius: '24px',
			maxWidth: "100%",
			maxHeight: "100%"
		},

		lineHeight: 0,
		borderRadius: 8,
		width: "100%",
		position: "relative",
	},
}));

const ShopItem = (props) => {

	const {item} = props;
	const classes = useStyles();
	const auth = useContext(AuthContext);
	const cart = useContext(CartContext);
	const { enqueueSnackbar } = useSnackbar();
	const [addingToCart, setAddingToCart] = useState(false);
	const [liking, setLiking] = useState(item.favorite);
	const navigate = useNavigate();
	const screenType = useScreenType();

	const [_likeResponse, doToggleLike] = useAxios({
		method: 'POST',
		url: '/api/saved/add-product/',
		headers: getAxiosHeaders(),
	}, {manual: true, autoCancel: false});

	const handleItemActionError = (error, item_id, action) => {
		if (error.response?.data?.error) {
			enqueueSnackbar(error.response.data.error, {autoHideDuration: 3000, variant: "error"})
		}
		Sentry.captureMessage(`Failed to ${action} shop item ${item_id}`);
		Sentry.captureException(error);
	}

	const redirectToGenerator = () => {
		navigate(`/?element_set=${item.element_set}`);
	}

	const handleClickSaved = async () => {
		if (!auth.user?.id) {
			return auth.signUpForm();
		}

		liking ? setLiking(false) : setLiking(true);
		if (liking) {
			try {
				await doToggleLike({
				data: {product_id: item.id, user_id: auth.user?.id}
				});
				enqueueSnackbar("The product is removed from saved! ✨", {autoHideDuration: 1500, variant: "success", action: () => (
						<div onClick={() => navigate('/favorites')} className={'Snackbar__Link'}>View saved</div>
					)})
			} catch (e) {
				console.log("oops", e)
				handleItemActionError(e, item.id, "add to saved");
		  	}
		} else {
			try {
			  await doToggleLike({
				data: {
				  product_id: item.id,
				  user_id: auth.user?.id
				}
			  });
			  enqueueSnackbar("The product is added to the saved! ✨", {autoHideDuration: 1500, variant: "success", action: () => (
				  <div onClick={() => navigate('/favorites')} className={'Snackbar__Link'}>View saved</div>
			  )})
			} catch (e) {
			  console.log("oops", e)
			  handleItemActionError(e, item.id, "add to saved");
			}
		  }
	  }

	const addToCart = async (e) => {
		e.preventDefault();
		e.stopPropagation();
		setAddingToCart(true);
		try {
			const {data} = await cart.doAddToCart({
				data: {
					url: item.id,
					quantity: 1,
					stockrecord: item.stockrecords[0].id,
				}
			});
			amplitude?.track('front.shop.to-cart', {item_id: item.id});
			enqueueSnackbar("The product is added to the cart! ✨", {autoHideDuration: 1500, variant: "success", action: () => (
					<div onClick={() => navigate('/shop/cart')} className={'Snackbar__Link'}>View cart</div>
				)})
			cart.refetch();
			setAddingToCart(false);
		} catch (e) {
			console.log("oops", e)
			handleItemActionError(e, item.id, "add to cart");
			setAddingToCart(false);
		}
	}

	const handleDownloadClick = () => {
		fetch(`/api/freebies/${item.id}/download/`, {
			method: 'GET',
			headers: {
			  ...getAxiosHeaders(),
			},
		  })
			.then((response) => {
			  if (!response.ok) {
				throw new Error('Network response was not ok');
			  }
			  return response.blob();
			})
			.then((blob) => {
			  const url = window.URL.createObjectURL(new Blob([blob]));
			  const link = document.createElement('a');
			  link.href = url;
			  link.setAttribute('download', 'file.zip');
			  document.body.appendChild(link);
			  link.click();
			  document.body.removeChild(link);
			})
			.catch((error) => {
			  console.error('There has been a problem with your fetch operation:', error);
			});
	}

	const getFreebies = (e) => {
		e.preventDefault();
		e.stopPropagation();
		auth.user ? handleDownloadClick() : auth.signInForm();
	}

	const handleItemClick = () => {
		navigate(`/shop/${item.type}/${item.id}-${item.slug}`);
	}

	if (props.skeleton) {
		return (
			<div
				className={'ShopItem skeleton'}
				style={{
					width: '100%',
					height: ([150, 230, 280])[props.item.id % 3]
				}}
			/>
		)
	}

	return (
		<Box className={'ShopItem'} style={props.maxWidth ? {maxWidth: props.maxWidth} : {}}>
			<Box className={classes.itemContainer} component={Link} to={`/shop/${item.type}/${item.id}-${item.slug}`} sx={{
				aspectRatio: `${item.images[0]?.size?.width} / ${item.images[0]?.size?.height}`
			}}>
				<Box className="toolbox">
					<Box className={classes.favoriteButton + ' toolbox__Like'} onClick={e => {e.preventDefault(); e.stopPropagation(); handleClickSaved(item.id)}}>
						<HeartRounded size="18px" fill={liking ? "#1A1A19" : "none"} style={{strokeWidth: 2.5}} />
					</Box>
					<Box className={classes.addToCartButton + ' toolbox__AddToCart'} component={Link} to={`/shop/${item.type}/${item.id}-${item.slug}`}>
						<img src="/static/images/shopping_cart.svg" alt="Add to cart" width='18px' height='18px'/>
						<Typography sx={{
							textDecoration: 'none',
							ml: 0.5,
							color: "#1A1A19",
							fontSize: 14,
							fontWeight: 600,
							lineHeight: "100%"
						}}>{item.trend ? 'Get' : 'Buy'}</Typography>
					</Box>
					<Box className={classes.generateButton + ' toolbox__Generate'} onClick={e => {e.preventDefault(); e.stopPropagation(); redirectToGenerator()}}>
						<img src="/static/images/magic_wand.svg" alt="Add to cart" width='18px' height='18px' />
						<Typography sx={{
							ml: 0.5,
							color: "#ffffff",
							fontSize: 14,
							fontWeight: 600,
							lineHeight: "100%"
						}}>Generate</Typography>
					</Box>
				</Box>

				{item.images?.length > 0 ? <Box className={classes.cover} sx={{
					aspectRatio: `${item.images[0].size?.width} / ${item.images[0].size?.height}`,
					position: 'relative'
				}}>
					<div className={'ShopItem__Gradient'}/>
					<img src={item.images[0].original} alt={item.title} loading={'lazy'} className={'skeleton'} height={item.images[0].size?.height} width={item.images[0].size?.width} style={{objectFit: 'cover', height: '100%', width: '100%'}}/>
					{
						(item.original_title !== "" && item.original_title !== null) || (item.original_description !== "" && item.original_description !== null) ?
							item.original_image ?
								screenType === 'mobile' ?
									<Box className={"original"} sx={{
										backgroundImage: `url(${item.original_image});`
									}}/>
									:
									<OriginalLabel item={item}/>
								: null
							: null
					}
				</Box>  : null}

				{!props.isFavoriteItem &&
					<Box display="flex" flexDirection="row" alignItems="center" mt={1.5} justifyContent="space-between"
						  className={'ShopItem__Description'}>
						<Typography
							className={classes.titleReconstruction + ' ShopItem__Title'}>{item.title}</Typography>
						{(item.stockrecords && item.stockrecords.length > 0) ?
							<Typography className={classes.priceReconstruction + ' ShopItem__Price'}>
								from {currencySymbol(item.stockrecords[0].price_currency)}{parseFloat(item.stockrecords[0].price)}
							</Typography>
							: null
						}
					</Box>
				}
			</Box>
			{!props.isFavoriteItem &&
				<Box className={'ShopItem__MobileControls'}>
					<Button className={classes.generateButton + ' ShopItem__MobileControls__Generate'} fullWidth>
						<MagicWand02 stroke={'currentColor'} size={18}/>
						Similar
					</Button>
				</Box>
			}
		</Box>
	);
}

export  default  ShopItem;