import React, {useEffect, useState, useRef} from "react";
import {Box, Container, LinearProgress, useMediaQuery, useTheme, Typography} from "@mui/material";
import useScrollPosition from "@react-hook/window-scroll";
import useAxios from "axios-hooks";

import _ from "lodash";
import {getAxiosHeaders} from "../../api/client";
import ShopItem from "../../components/Shop/ShopItem";
import ShopSearchBoard from "../../components/Shop/SearchBoard";
import {useParams} from "react-router-dom";
import StackGrid from "react-stack-grid";
import {useScreenType} from "../../hooks/useScreenType";


const ShopSearchPage = () => {
	const params = useParams();
	const screenType = useScreenType();

	const [shopItems, setShopItems] = useState([]);
	const [offset, setOffset] = useState(0);
	const [hasMore, setHasMore] = useState(false);
	const [searchQuery, setSearchQuery] = useState(params.slug === null || params.slug === "search" ? "" : params.slug);

	const scrollY = useScrollPosition(10);

	const [{data, loading}] = useAxios({
		url: '/api/products/',
		headers: getAxiosHeaders(),
		params: params.slug === "freebies" ? {offset: offset, trend: true, limit: 10, query: searchQuery} : {offset: offset, limit: 10, query: searchQuery},
	}, {useCache: false});

	console.log(data?.results, 'results');

	const fetchNextPage = () => {
		setOffset(shopItems ? shopItems.length : 0)
	}

	useEffect(() => {
		setShopItems([]);
	}, [searchQuery]);

	useEffect(() => {
		if (data?.results?.length > 0) {
			setHasMore(offset + data.results.length < data.count);
			setShopItems((items) => items ? _.concat(items, data.results) : data.results);
		} // eslint-disable-next-line
	}, [data]);

	useEffect(() => {
		if (hasMore) {
			const bottom = Math.abs(window.innerHeight + scrollY - document.body.scrollHeight) < 20;
			if (bottom) {
				fetchNextPage();
			}
		} // eslint-disable-next-line
	}, [scrollY]);

	useEffect(() => {
		gridRef.current?.updateLayout()
	}, [shopItems])

    const theme = useTheme();
	const gridRef = useRef(null);

	const tablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
	const mobile = useMediaQuery(theme.breakpoints.down("sm"));

	let columnWidth = "33.3%";
	let gutterWidth = 30;

	if (tablet || mobile) {
		columnWidth = "50%";
		gutterWidth = 10
	}

	return <>
		<Container maxWidth={false} disableGutters>
			<Typography className={'Title__page'}>
				{ screenType === 'desktop' ?
					<span>Generate and shop thousands of unique patterns,<br/>historic artworks, design templates and more.</span>
					:
					<span>Start Your Art<br/>Journey Now</span>
				}
			</Typography>

			<Box sx={{ flexGrow: 1 }}>
				<ShopSearchBoard searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
			</Box>

			<Box className={'Shop__collection__box'}>
				<Box className={'Shop__collection__list'}>
					<StackGrid
						gridRef={grid => gridRef.current = grid}
						columnWidth={columnWidth}
						itemComponent="div"
						duration={0}
						appearDelay={0}
						gutterWidth={gutterWidth}
						gutterHeight={24}
					>
						{_.map(shopItems, (item) => <ShopItem key={item.id} item={item} />)}
					</StackGrid>
				</Box>
			</Box>

		{loading ? <Box p={2}><LinearProgress style={{width: '100%'}} color="secondary"/></Box> : null }
		</Container>
	</>

}

export default ShopSearchPage;