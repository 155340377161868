import Lottie from "lottie-react";
import lottieLoader from './pluranaLoader.json';
export const PluranaLoaderIcon = () => {
    const options = {
        animationData: lottieLoader,
        loop: true
    }

    return (
        <div style={{overflow: "hidden", height: 64, width: 64, position: 'relative'}}>
            <Lottie {...options} style={{position: 'absolute', height: 200, width: 200, top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}/>
        </div>
    )
}