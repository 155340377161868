import React, {useState} from "react";
import {Button, Popover, Popper, ClickAwayListener, TextField, Box, IconButton} from "@mui/material";
import {ColorService, Hue, Saturation, useColor} from "react-color-palette";
import {  } from "untitledui-js/icons/general"
import "react-color-palette/css";
import './ColorPicker.scss';
import {useSnackbar} from "notistack";
import {EyeDropperIcon} from "../../icons";

export const ColorPicker = ({
     initialColor='#FFFFFF',
     onAddColor,
     anchorEl,
     onClose,
     open,
     ...restProps
 }) => {
    const [currentColor, setCurrentColor] = useColor(initialColor);
    const [tempColor, setTempColor] = useState(currentColor.hex);
    const {enqueueSnackbar} = useSnackbar();

    const onColorInputChange = (value) => {
        setCurrentColor(ColorService.convert("hex", value));
        setTempColor(value)
    }

    const onColorInputDone = (value) => {
        setCurrentColor(ColorService.convert("hex", value));
        setTempColor(ColorService.convert("hex", value).hex)
    }

    const openEyeDropper = () => {
        if (!window.EyeDropper) {
            enqueueSnackbar('Your browser does not support the EyeDropper')
            return
        }

        const eyeDropper = new window.EyeDropper();
        eyeDropper.open().then(result => {
            onColorInputDone(result.sRGBHex)
        })
    }

    return (
        open ?
            <ClickAwayListener onClickAway={onClose} mouseEvent="onMouseDown" touchEvent="onTouchStart">
                <Popper
                    className={'ColorPicker'}
                    open={open}
                    onClose={onClose}
                    anchorEl={anchorEl}
                    placement={'right'}
                    >
                    <div className="ColorPicker__Layout">
                        <Saturation height={174} color={currentColor} onChange={color => {
                            setCurrentColor(color);
                            setTempColor(color.hex)
                        }} />
                        <Hue color={currentColor} onChange={color => {
                            setCurrentColor(color);
                            setTempColor(color.hex)
                        }} />

                        <Box className={'ColorPicker__Controls'}>
                            <TextField
                                label={'HEX'}
                                value={`${tempColor}`}
                                className={'ColorPicker__Input'}
                                onChange={e => onColorInputChange(e.target.value)}
                                onBlur={() => onColorInputDone(tempColor)}
                            />
                            {window.EyeDropper &&
                                <Button onClick={openEyeDropper}>
                                    <EyeDropperIcon width={12} height={12}/>
                                </Button>
                            }
                        </Box>

                        <Button fullWidth onClick={() => {
                            onAddColor(currentColor.hex);
                            onClose();
                        }}>
                            Add color
                        </Button>
                    </div>
                </Popper>
            </ClickAwayListener>
        : null
    )
}