import { useLayoutEffect, useState } from 'react';

export function useScreenType() {
    const [screenType, setScreenType] = useState('desktop');
    useLayoutEffect(() => {
        function updateType() {
            if (window.innerWidth >= 768) {
                setScreenType('desktop')
            } else {
                setScreenType('mobile')
            }
        }
        window.addEventListener('resize', updateType);
        updateType();
        return () => window.removeEventListener('resize', updateType);
    }, []);
    return screenType;
}