import {
  useMediaQuery,
  useTheme
} from "@mui/material";

import {useEffect, useState} from "react";
import TokensPurchaseModal from "../Generator/TokensPurchaseModal";
import TokensPurchaseModalSuccess from "../Generator/TokensPurchaseSuccessModal";
import SubscriptionSuccessModal from "../Generator/SubscriptionSuccessModal";
import {useSearchParams} from "react-router-dom";
import MobileAppBar from "./MobileAppBar";
import DesktopAppBar from "./DesktopAppBar";

export const Header = () => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const [searchParams, setSearchParams] = useSearchParams();

  const [tokensPurchaseQuantity, setTokensPurchaseQuantity] = useState(100);
  const [openTokensPurchaseModal, setOpenTokensPurchaseModal] = useState({
    start: false,
    success: false,
    error: false
  });
  const [openSubscriptionSuccessModal, setOpenSubscriptionSuccessModal] = useState(false);

  const handleOpenTokensPurchaseModal = (step) => {
    setOpenTokensPurchaseModal({
      start: step === "start",
      success: step === "success",
      error: step === "error",
    });
  }
  const handleCloseTokensPurchaseModal = () => {
    setOpenTokensPurchaseModal({
      start: false,
      success: false,
      error: false
    });
  }

  const handleCloseSubscriptionSuccessModal = () => {
    searchParams.delete('subscription_session_id')
    setSearchParams(searchParams)
  }

  useEffect(() => {
    if (searchParams?.get('subscription_session_id')) {
      setOpenSubscriptionSuccessModal(true);
    } else {
      setOpenSubscriptionSuccessModal(false);
    }
  }, [searchParams])






  return (
    <>
      {isMobile ?
        <MobileAppBar handleOpenTokensPurchaseModal={handleOpenTokensPurchaseModal} /> :
        <DesktopAppBar handleOpenTokensPurchaseModal={handleOpenTokensPurchaseModal} />}

      <TokensPurchaseModal
        open={openTokensPurchaseModal.start}
        onClose={handleCloseTokensPurchaseModal}
        quantity={tokensPurchaseQuantity}
        showModal={handleOpenTokensPurchaseModal}
        setQuantity={setTokensPurchaseQuantity}
      />
      <TokensPurchaseModalSuccess
        open={openTokensPurchaseModal.success}
        onClose={handleCloseTokensPurchaseModal}
        quantity={tokensPurchaseQuantity}
      />
      {openSubscriptionSuccessModal ? <SubscriptionSuccessModal
        open={openSubscriptionSuccessModal}
        onClose={handleCloseSubscriptionSuccessModal}
        session_id={searchParams.get('subscription_session_id')}
      /> : null}
    </>
  );
};