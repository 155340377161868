import {Box, Container} from "@mui/material";
import {makeStyles} from "@mui/styles";
import Typography from "@mui/material/Typography";
import {ScrollToTopOnMount} from "../../helpers/navigarions";
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
}));

const NotFoundPage = () => {

	const classes = useStyles();

	return <Container maxWidth="lg">
		<ScrollToTopOnMount />
		<Box display="flex" flexDirection="column" justifyContent="center"
				 alignItems="center" className={classes.oops} gap={10}>
			<Box display="flex" flexDirection="column" alignItems="center" gap={2}>
				<Typography variant="h2">
					Sharing & publication policy
				</Typography>
			</Box>

			<Box>
				<Typography variant="h3">You have permission to:</Typography>
				<ul>
					<li>Share or sell any downloaded content obtained by clicking the "Download" button. However, please note that photos used for demonstration purposes, such as those in photo frames or social media posts, do not belong to you and cannot be sold.</li>
					<li>Mention Plurana as the software platform that facilitated the creation of your design material. It is recommended to use the phrase: "This design is created with Plurana."</li>
				</ul>
			</Box>

			<Box>
				<Typography variant="h3">You are not allowed to:</Typography>
				<ul>
					<li>Present your services as a collaboration with Plurana. You cannot use the phrase: "This design is created in collaboration with Plurana."</li>
					<li>Share or sell generated content that was not downloaded by you. This content does not belong to you as per our <Link to="/terms">service terms</Link>.</li>
				</ul>
			</Box>

		</Box>

	</Container>
}

export default NotFoundPage;