import {Box, Fade, IconButton, Modal, Slide} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {FilterTag} from "./FilterTag";
import {HorizontalScroll} from "../Base";
import {StylePreviewItem} from "./Set/StylePreviewItem";
import InfiniteScroll from "react-infinite-scroll-component";
import GeneratorContext from "../../contexts/GeneratorContext";
import {XClose} from "untitledui-js/icons/general";
import './StylesPreviewModal.scss';
import {useScreenType} from "../../hooks/useScreenType";
import useAxios from "axios-hooks";
import {getAxiosHeaders} from "../../api/client";

export const StylesPreviewModal = ({
    open,
    onClose,
    elements,
    fetchNextElements
}) => {
    const [tags, setTags] = useState([]);
    const generator = useContext(GeneratorContext);
    const screenType = useScreenType();

    const handleCategorySelect = (id) => {
        setTags(items => {
            return items.map(item => {
                if (item.id === id) {
                    return {...item, selected: !item.selected}
                }
                return item;
            })
        })
    }

    const [{data: categories}, doGetCategories] = useAxios({
        url: '/api/sets-categories/',
        headers: getAxiosHeaders(),
    }, {})

    useEffect(() => {
        if (categories && categories.results) {
            setTags(categories.results.map(item => ({...item, selected: false})))
        }
    }, [categories])

    const selectedTags = tags.filter(item => item.selected).map(item => item.id);
    const elementsToDisplay = elements.filter(item => {
        return item.categories.length ? selectedTags.every(
            x => item.categories.includes(x)
        ) : false
    })

    const ModalContent = (
        <Box className={'StylesPreviewModal'}>
            <Box className={'StylesPreviewModal__Content'}>
                <Box className={'StylesPreviewModal__Group'}>
                    <Box className={'StylesPreviewModal__GroupHeader'}>
                        Styles
                    </Box>
                    <HorizontalScroll hideArrows>
                        {tags.map((item, key) => (
                            <FilterTag selected={item.selected} label={item.name} key={item.id} setSelected={() => handleCategorySelect(item.id)}/>
                        ))}
                    </HorizontalScroll>
                </Box>

                    {
                        tags.filter(item => item.selected).length ?
                            <InfiniteScroll
                                dataLength={elements.length}
                                next={fetchNextElements}
                                hasMore={true}
                                scrollableTarget={'StylesListScroll'}
                            >
                                <Box className={'StylesPreviewModal__StylesList StylesPreviewModal__StylesList-grid StylesPreviewModal__Group'} id={'StylesListScroll'}>
                                    {elementsToDisplay.map(data => (
                                        <StylePreviewItem
                                            size={'l'}
                                            key={data.id}
                                            set={data}
                                            preview={data.preview}
                                            title={data.title}
                                            onClick={() => {
                                                generator.setState({
                                                    elements: data.elements.map(item => (
                                                        {...item, selected: true, setId: data.id}
                                                    )),
                                                    colors: data.colors.map(item => (
                                                        {color: item, selected: true, id: Math.random()}
                                                    )),
                                                    elementsSet: data
                                                })
                                                onClose()
                                            }}
                                            selected={generator.state.elementsSet?.id === data.id}
                                        />
                                    ))}
                                </Box>
                            </InfiniteScroll>
                        :
                            <Box className={'StylesPreviewModal__StylesList'} id={'StylesListScroll'}>
                                {categories ? categories.results.map(category => (
                                    <Box className={'StylesPreviewModal__Group'} style={{marginBottom: 12}}>
                                        <Box className={'StylesPreviewModal__GroupHeader StylesPreviewModal__GroupHeader--thick'}>
                                            {category.name}
                                        </Box>
                                        <HorizontalScroll controlsMargin={170 / 2 - 16 + 10}>
                                            {elements.filter(item => item.categories.includes(category.id)).map(data => (
                                                <StylePreviewItem
                                                    size={screenType === 'mobile' ? 'm' : 'l'}
                                                    key={data.id}
                                                    preview={data.preview}
                                                    title={data.title}
                                                    set={data}
                                                    onClick={() => {
                                                        generator.setState({
                                                            elements: data.elements.map(item => (
                                                                {...item, selected: true, setId: data.id}
                                                            )),
                                                            colors: data.colors.map(item => (
                                                                {color: item, selected: true, id: Math.random()}
                                                            )),
                                                            elementsSet: data
                                                        })
                                                        onClose()
                                                    }}
                                                    selected={generator.state.elementsSet?.id === data.id}
                                                />
                                            ))}
                                        </HorizontalScroll>
                                    </Box>
                                )) : null}
                            </Box>
                    }
                <IconButton className={'StylesPreviewModal__CloseButton'} onClick={onClose}>
                    <XClose size={'16px'}/>
                </IconButton>
            </Box>
        </Box>
    )

    return (
        <Modal open={open} onClose={onClose}>
            {screenType === 'desktop' ?
                <Fade in={open}>
                    {ModalContent}
                </Fade>
                :
                <Slide in={open} direction="up">
                    {ModalContent}
                </Slide>
            }

        </Modal>
    )
}