import {first} from "lodash/array";
import {filter} from "lodash/collection";

const CURRENCY_SYMBOLS = {
	"EUR": "€",
	"USD": "$",
	"GBP": "£",
}

export const currencySymbol = (currency) => {
	return CURRENCY_SYMBOLS[currency] || currency;
}

export const hasAnyPrice = (product) => {
	return product.stockrecords && product.stockrecords.length > 0
}

export const getPricesInCurrency = (product, currency) => {
	return filter(product.stockrecords, (record) => record.price_currency === currency)
}

export const getPrice = (product, currency, stockId) => {
	const pricesInCurrency = getPricesInCurrency(product, currency);
	if (!stockId) return first(pricesInCurrency);
	return first(filter(pricesInCurrency, (record) => record.id === stockId))
}