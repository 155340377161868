import React, {useContext, useEffect, useState} from "react";

import {Box, Button, IconButton, Typography} from "@mui/material";
import {
    UndoIcon,
    RedoIcon,
    CopyIcon,
    GalleryIcon,
    RandomIcon,
    PaletteIcon,
    PlusIcon
} from "../../icons";
import {DragAndDropInput} from "./DragAndDropInput";

import './CustomizationMenu.scss';
import {GraphicElementsList} from "./GraphicElementsList";
import GeneratorContext from "../../contexts/GeneratorContext";
import {GeneratorColorsList} from "./ColorsList";
import useAxios from "axios-hooks";
import {getAxiosHeaders} from "../../api/client";
import {useSnackbar} from "notistack";
import {ColorPicker} from "./ColorPicker";
import {Triangle} from 'untitledui-js/icons/shapes'
import {Loading01} from "untitledui-js/icons/general";

export const CustomizationMenu = ({
    startGeneration,
    isGenerating,
    getRandomPalette,
    setIsStylesModalOpen
}) => {
    const generator = useContext(GeneratorContext);

    const [history, setHistory] = useState({items: [], index: -1});
    const [isMinimized, setIsMinimized] = useState(false);
    const [transitionInProgress, setTransitionInProgress] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [dragActive, setDragActive] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const addItemToHistory = (item) => {
        setHistory(prevState => (
            {index: prevState.index + 1, items: [...prevState.items.slice(0, prevState.index + 1), item]}
        ))
    }

    const undoFromHistory = () => {
        if (history.index >= 0) {
            const item = history.items[history.index];
            setHistory({...history, index: history.index - 1})
            item.undo()
        }
    }

    const redoFromHistory = () => {
        if (history.items.length > history.index + 1) {
            const item = history.items[history.index + 1];
            setHistory({...history, index: history.index + 1})
            item.redo()
        }
    }

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else {
            if (typeof e.target.className === 'string') {
                if (e.target.className.indexOf('CustomizationMenu__Wrapper') > -1 && e.relatedTarget.className.indexOf('CustomizationMenu')) {
                    setDragActive(false);
                }
            }
        }
    }

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);

        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleFilesAttach(e.dataTransfer.files)
        }
    }

    const embedRastr = (data, image) => {
        const svgContent = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="${image.width}" height="${image.height}"><image width="${image.width}" height="${image.height}" xlink:href="${data}" /></svg>`;
        return `data:image/svg+xml;base64,${btoa(svgContent)}`;
    }

    const readFile = (file) => {
        return new Promise((resolve, reject) => {
            let loader = new FileReader();
            loader.onload = (e) => {
                if (file.size > 2*1024*1024) {
                    return reject("Maximum size of file is 2MB")
                }

                if (!['svg', 'png', 'jpg', '.jpeg'].includes(file.name.split('.').pop())) {
                    return reject('Only JPG, PNG and SVG files can be uploaded')
                }

                if (file.name.split('.').pop() === "svg") {
                    resolve({
                        name: file.name,
                        png: loader.result,
                        selected: true,
                        id: Math.random()
                    })
                } else {
                    const img = new Image();
                    img.onload = function () {

                        if (img.width > 1000 || img.height > 1000) {
                            return reject("Maximum size is 1000px")
                        }

                        resolve({
                            name: file.name,
                            png: embedRastr(loader.result, img),
                            selected: true,
                            id: Math.random(),
                            setId: null
                        });

                    };
                    img.onerror = function () {
                        reject("Failed to load image");
                    };
                    img.src = loader.result;
                }
            };
            loader.onerror = reject;
            loader.readAsDataURL(file);
        });
    }

    const handleFilesAttach = (files) => {
        let promises = []
        for (const item of files) {
            promises.push(readFile(item))
        }
        Promise.all(promises).then(items => {
            generator.setElements([...generator.state.elements, ...items])
            addItemToHistory({
                type: 'add',
                target: 'element',
                redo: () => generator.setElements([...generator.state.elements, ...items]),
                undo: () => generator.setElements(generator.state.elements.filter(item => !items.map(el => el.id).includes(item.id))),
            })
        }).catch(e => {
            enqueueSnackbar(e)
        })
    }

    const addColor = (color) => {
        generator.setColors([{color: color, selected: true, id: Math.random()}, ...generator.state.colors]);
        addItemToHistory({
            type: 'delete',
            target: 'color',
            undo: () => generator.setColors(generator.state.colors),
            redo: () => generator.setColors([{color: color, selected: true, id: Math.random()}, ...generator.state.colors])
        })
    }

    const getStylePreview = () => {
        const isSetSelected = Boolean(generator.state.elementsSet?.id);
        let isGeneratedPreview = false;
        let preview = '';

        if (isSetSelected) {
            if (generator.state.elementsSet?.preview) {
                preview = generator.state.elementsSet.preview;
            } else if (generator.state.elements?.length) {
                preview = generator.state.elements[0]['png'];
                isGeneratedPreview = true;
            }
        }

        return (
            <Button className={'StylePreview'} onClick={() => {
                setIsMinimized(!isMinimized);
                setTransitionInProgress(true);
                setTimeout(() => setTransitionInProgress(false), 600)
            }}>
                <Box className={'StylePreview__Title'}>
                    <Box className={'StylePreview__Image--wrap' + (isGeneratedPreview ? ' StylePreview__Image--wrap-generated' : '')} style={{
                        backgroundColor: isSetSelected ? generator.state.elementsSet?.colors[0] : '#FF33D3'
                    }}>
                        <img
                            className={'StylePreview__Image'}
                            src={preview}
                            alt={''}
                        />
                    </Box>
                    <Typography className={'StylePreview__Label'}>
                        {isSetSelected ? generator.state.elementsSet.title : 'Custom style'}
                    </Typography>
                </Box>
                <Box className={'StylePreview__Chevron'}>
                    <Triangle fill={'black'} size={'8px'}/>
                </Box>
            </Button>
        )
    }

    return (
        <Box
            className={'CustomizationMenu__Wrapper'}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
        >
            <Box className={'CustomizationMenu'}>
                <Box className={'CustomizationMenu__Header'}>
                    <Box className={'CustomizationMenu__Header__History'}>
                        <IconButton onClick={undoFromHistory} disabled={!(history.index >= 0)}>
                            <UndoIcon width={24} height={24}/>
                        </IconButton>
                        <IconButton onClick={redoFromHistory} disabled={!(history.items.length > history.index + 1)}>
                            <RedoIcon width={24} height={24}/>
                        </IconButton>
                    </Box>
                    <Box className={'CustomizationMenu__Header__Control'}>
                        { generator.state.elementsSet?.id ?
                            <Button onClick={() => {generator.setState({elements: [], colors: [], elementsSet: null})}}>
                                Start from scratch
                            </Button>
                            :
                            <Button onClick={() => setIsStylesModalOpen(true)}>
                                Choose style
                            </Button>
                        }

                    </Box>
                </Box>
                <Box className={'CustomizationMenu__Content' + (isMinimized ? ' CustomizationMenu__Content-minimized' : '') + (transitionInProgress ? ' CustomizationMenu__Content-in_transition' : '')}>
                    {getStylePreview()}

                    <Box className={'CustomizationMenu__Editor'}>
                        <Box className={'CustomizationMenu__Editor__Header'}>
                            <Typography>Graphic elements</Typography>
                        </Box>
                        <Box className={
                            'CustomizationMenu__Editor__Attachments' + (dragActive ?
                                ' CustomizationMenu__Editor__Attachments-ondrag'
                                : !generator.state.elements.length ?
                                ' CustomizationMenu__Editor__Attachments-showBorder'
                                : '')
                        }>
                            {!generator.state.elements.length ?
                                <DragAndDropInput dragActive={dragActive} handleFilesAttach={handleFilesAttach}/>
                                :
                                <GraphicElementsList
                                    elements={generator.state.elements}
                                    setElements={generator.setElements}
                                    handleFilesAttach={handleFilesAttach}
                                    addItemToHistory={addItemToHistory}
                                />
                            }
                        </Box>
                        <Box className={'CustomizationMenu__Editor__Colors'}>
                            <Typography className={'CustomizationMenu__Editor__Colors__Header'}>
                                Colors
                            </Typography>

                            {!generator.state.colors.length ?
                                <Box className={'CustomizationMenu__Editor__Colors__Controls'}>
                                    <IconButton component={'label'} onClick={e => setAnchorEl(e.currentTarget)}>
                                        <PlusIcon width={'16px'} height={'16px'}/>
                                    </IconButton>
                                    <Button startIcon={<RandomIcon width={18} height={18}/>} onClick={() => {
                                        const palette = getRandomPalette();
                                        generator.setColors(palette.map(color => ({color: color, selected: true, id: Math.random()})));
                                    }}>
                                        Random palette
                                    </Button>
                                    <ColorPicker anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)} onAddColor={addColor} initialColor={'#FFFFFF'}/>
                                </Box>
                                :
                                <>
                                    <GeneratorColorsList
                                        colors={generator.state.colors}
                                        setColors={generator.setColors}
                                        addColor={addColor}
                                        addItemToHistory={addItemToHistory}
                                    />
                                    <Box className={'CustomizationMenu__Editor__Colors__Controls'}>
                                        <Button startIcon={<RandomIcon width={18} height={18}/>} onClick={() => {
                                            const palette = getRandomPalette();
                                            generator.setColors(palette.map(color => ({color: color, selected: true, id: Math.random()})));
                                        }}>
                                            Random palette
                                        </Button>
                                    </Box>
                                </>
                            }
                        </Box>
                    </Box>

                    <Button className={'CustomizationMenu__Control'} disabled={isGenerating} onClick={
                        () => startGeneration({
                            set: {id: 0},
                            elements: generator.state.elements,
                            colors: generator.state.colors.map(item => item.color)
                        }, true)
                    }>
                        {!isGenerating ? 'Apply' : <Loading01 className={'LoadingIcon'} stroke={'white'}/>}
                    </Button>

                </Box>
            </Box>
        </Box>
    )
}