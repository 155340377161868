import {Box, Button, IconButton} from "@mui/material";
import './HorizontalScroll.scss';
import React, {useEffect, useRef, useState} from "react";
import {ArrowLeft, ArrowRight, ChevronLeft, ChevronRight} from "untitledui-js/icons/arrow";

export const HorizontalScroll = ({
    children, 
    asideControl = null,
    hideArrows = false,
    hideGradient = false,
    controlsMargin = 16,
    className,
    itemsGap=8,
    rightGradientVariant='md',
    scrollAreaStyle={},
    ...restProps
}) => {
    const [scrollX, setScrollX] = useState(0);
    const [scrollWidth, setScrollWidth] = useState(0);
    const scrollRef = useRef(null)

    useEffect(() => {
        updateScrollWidth();
        window.addEventListener('resize', updateScrollWidth);
        return () => {
            window.removeEventListener('resize', updateScrollWidth);
        }
    }, [scrollRef, children])

    const updateScrollWidth = () => {
        setScrollWidth(scrollRef.current.scrollWidth - scrollRef.current.clientWidth);
    }

    const handleScroll = (e) => {
        setScrollWidth(e.currentTarget.scrollWidth - e.currentTarget.clientWidth);
        setScrollX(e.currentTarget.scrollLeft);
    }
    
    return (
        <Box
            className={'HorizontalScroll' + (hideGradient ? ' HorizontalScroll--no_gradient' : '') + (hideArrows ? ' HorizontalScroll--no_arrows' : '') + (className ? ' ' + className : '')}
            {...restProps}
        >
            <Box className={'HorizontalScroll__ScrollArea'} style={scrollAreaStyle}>
                {Math.floor(scrollX) > 0 &&
                    <Box className={'HorizontalScroll__Arrow-wrapper HorizontalScroll__Arrow-wrapper_left'}>
                        <IconButton onClick={() => scrollRef.current.scrollTo({left: scrollX - 100, behavior: 'smooth'})} style={{top: controlsMargin}}>
                            <ArrowLeft size={16} stroke={'#1A1A19'} strokeWidth="1.4"/>
                        </IconButton>
                    </Box>
                }
                <Box className={'HorizontalScroll__Items-wrapper'} onScroll={handleScroll} onTouchMove={handleScroll} T ref={scrollRef}>
                    <Box className={'HorizontalScroll__Items'} style={{columnGap: itemsGap}}>
                        {children}
                    </Box>
                </Box>
            </Box>
            {scrollWidth > Math.ceil(scrollX) && asideControl &&
                <Box className={'HorizontalScroll__AsideControl--wrapper'}>
                    <Box className={'HorizontalScroll__AsideControl'} style={{marginTop: controlsMargin - 6}}>
                        {asideControl}
                    </Box>
                </Box>
            }
            {scrollWidth > Math.ceil(scrollX) &&
                <Box className={'HorizontalScroll__Arrow-wrapper HorizontalScroll__Arrow-wrapper_right HorizontalScroll__Arrow-wrapper_' + rightGradientVariant}>
                    <IconButton onClick={() => scrollRef.current.scrollTo({left: scrollX + 100, behavior: 'smooth'})} style={{top: controlsMargin}}>
                        <ArrowRight size={16} stroke={'#1A1A19'} strokeWidth="1.4"/>
                    </IconButton>
                </Box>
            }
        </Box>
    )
}