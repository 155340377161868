import React, {useEffect, useState} from "react";
import {Box, Grid, Paper, Typography, Container, LinearProgress} from "@mui/material";
import {SearchString} from "../../../components/SearchString/SearchString";
import useScrollPosition from "@react-hook/window-scroll";
import useAxios from "axios-hooks";
import {getAxiosHeaders} from "../../../api/client";
import {ReconstructionPreview} from "../../../components/Reconstruction/ReconstructionPreview";
import SearchButtons from "../../../components/SearchString/SearchButtons";

import _ from "lodash";
import {useParams} from "react-router-dom";
import {ScrollToTopOnMount} from "../../../helpers/navigarions";

export function ReconstructionDetailPage() {

  const params = useParams();
  const [productID, ...slug] = params.slug.split("-");

  const [{data: reconstruction, loading}] = useAxios({
    url: `/api/products/${productID}/`,
    headers: getAxiosHeaders(),
  }, {useCache: false});

  useEffect(() => {
      setTimeout(() => window.scrollTo(0,0, {behavior: 'smooth'}), 10)
  }, [productID])

  return (
    <>
      <ScrollToTopOnMount />
      <Container maxWidth={false} disableGutters style={{minHeight: loading ? '80vh' : 0}}>
        {loading ?
          <Box p={2}><LinearProgress style={{width: '100%'}} color="secondary"/></Box> :
          <ReconstructionPreview
            key={reconstruction.id}
            reconstruction={reconstruction}
          />
        }
      </Container>
    </>
  );
}

export default ReconstructionDetailPage;