import React from "react";
import {Typography, Box} from "@mui/material";
import { HelpContent } from "../../components/Help/HelpContent";

import './HelpPage.scss';


export const HelpPage = () => {

	return (
		<>
			<Typography className={'Title__page__help'}>Help</Typography>

			<Box className={'Help__content'}>
                <HelpContent />
			</Box>
		</>
	)
}
