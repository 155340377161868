import React, {useState, useRef, useEffect, useContext} from "react";
import useAxios from "axios-hooks";
import {Typography, Box, Button, LinearProgress, useMediaQuery, useTheme} from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import AuthContext from "../../contexts/AuthContext";
import {debounce, map} from "lodash";
import {getAxiosHeaders} from "../../api/client";
import {ScrollToTopOnMount} from "../../helpers/navigarions";
import {makeStyles} from "@mui/styles";
import LoadingIcon from "../../icons/LoadingIcon";
import GenerativeList from "../../components/Generator/GenerativeList";
import ShopItem from "../../components/Shop/ShopItem";
import StackGrid from "react-stack-grid";
import { Link } from "react-router-dom";
import GenerativeItem from "../../components/Generator/GenerativeItem";
import './FavoritesPage.scss'
import SubscriptionCards from "../../components/Subscription/SubscriptionCards";



const PAGE_LIMIT = 30;

const useStyles = makeStyles((theme) => ({
	periodToggle: {
		background: "var(--base-white)",
		padding: 4,
		[theme.breakpoints.down('sm')]: {
			padding: "4px 0",
		},
		"& .MuiButtonBase-root": {
			height: '49px',
            fontWeight: 400,
			textTransform: "none",
			border: 0,
			color: 'var(--plurana-neutrals-400)',
			borderRadius: 0,
			"&.active": {

				color: "var(--plurana-neutrals-900)",
                fontWeight: 600,
			},
		},
	},
	emptyCart: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		margin: '0',
		height: 305,
		flexShrink: 0,
		borderRadius: 40,
		backgroundColor: "var(--plurana-neutrals-100)",
		gap: 30,
		"& .title": {
			color: "var(--plurana-neutrals-400)",
			textAlign: 'center',
			fontSize: 46,
			fontWeight: 600,
			lineHeight: "100%",
		},
		"& .buttons": {
			display: "flex",
			gap: '16px',
			"& .shop.MuiButtonBase-root": {
				height: 48,
				width: 126,
				borderRadius: 'var(--plurana-button-border-radius)',
				lineHeight: "100%",
				background: "var(--base-white)",
				fontSize: 14,
				color: 'var(--plurana-neutrals-600)',
				fontWeight: 600,
			},
			"& .generator.MuiButtonBase-root": {
				height: 48,
				width: 152,
				borderRadius: 'var(--plurana-button-border-radius)',
				color: "var(--base-white)",
				lineHeight: "100%",
				fontSize: 14,
				fontWeight: 600,
				background: "var(--plurana-neutrals-900)",
			},
		}
	},
}));

export const FavoritesPage = () => {
	const [offset, setOffset] = useState(0);
	const [hasMore, setHasMore] = useState(false);
	const [favorites, setFavorites] = useState([]);
	const [generativeOffset, setGenerativeOffset] = useState(0);
	const [generativeHasMore, setGenerativeHasMore] = useState(false);
	const [favoritesGenerative, setFavoritesGenerative] = useState([]);
	const [removedCount, setRemovedCount] = useState(0);

	const auth = useContext(AuthContext);

	const [data, fetchFavorites] = useAxios({
		method: 'POST',
		url: '/api/saved/',
		data: {user_id: auth.user?.id},
		headers: getAxiosHeaders(),
	}, {manual: true, autoCancel: false})

	const [{loading, error}, fetchFavoritesG] = useAxios({
		method: 'GET',
		url: '/api/generator/favorites/',
		params: {offset, limit: PAGE_LIMIT},
		headers: getAxiosHeaders(),
	}, {manual: true, autoCancel: false})


	useEffect( () => {
		(async () => {
			const fetched = (await fetchFavorites()).data.filter(item => item.length) || [];
			setHasMore(fetched.length > 0);
			setFavorites(favorites => favorites.concat(fetched.map(item => ({...item, favorite: true}))));
		})();
	}, [offset, removedCount]);

	useEffect( () => {
		(async () => {
			const fetched = (await fetchFavoritesG()).data?.items || [];
			setHasMore(fetched.length > 0);
			setFavoritesGenerative(favoritesGenerative => favoritesGenerative.concat(fetched));
		})();
	}, [offset, removedCount]);


	// const tablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
	// const mobile = useMediaQuery(theme.breakpoints.down("sm"));

	const theme = useTheme();
	const tablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
	const mobile = useMediaQuery(theme.breakpoints.down("sm"));
	const isDesktopLg = useMediaQuery('(min-width: 1400px)');

	let columnWidth = "25%";
	let gutterWidth = 16;

	if (isDesktopLg) {
		columnWidth = '20%';
	}

	if (tablet || mobile) {
		columnWidth = "50%";
		gutterWidth = 10
	}

	const hasFavorites = Boolean(favorites.length);
	const gridRef = useRef(null);
	const [whoSaved, setSaved] = useState('shop');

	// const inferOffset = debounce(() => {
	// 	const removed = favorites.filter(item => !item.favorite);
	//
	// 	// removedCount is stored in the state in order
	// 	// to handle a specific edge-case: when offset stays unchanged after liking/unliking
	// 	// i.e. right after mounting before reaching the bottom of the page
	// 	// (=before InfiniteScroll loads new batch) if all favorites are unliked
	// 	// we need to fetch data with offset = 0
	// 	// as favorites cashed in the state are different from favorites in the DB
	//
	// 	setRemovedCount(removed.length);
	// 	setOffset(favorites.length - removed.length);
	// }, 300);

	const classes = useStyles();

	return (
		<>
			<ScrollToTopOnMount />

			<Typography sx={{
				textAlign: "center",
				marginTop: '54px',
				fontSize: '36px',
				fontWeight: 600,
				color: 'var(--plurana-neutrals-900)',
				lineHeight: '100%'
			}}>Favorites</Typography>

			<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={'58px'} mb={'56px'}>
				<Box className={classes.periodToggle + ' FavoritesToggle'}>
					<Button className={whoSaved === "shop" ? "active" : ""} onClick={() => setSaved("shop")}>
						Shop
					</Button>
					<Button className={whoSaved === "generator" ? "active" : ""} onClick={() => setSaved("generator")}>
						Generator
					</Button>
				</Box>
			</Box>

			<Box className={'Shop__collection__box Shop__collection__box-smpadding'} id={'shop-collection'}>
				<Box className={'Shop__collection__list'}>
					{data.loading || favorites.length > 0 ?
						<StackGrid
							className={'GenerativeListGrid'}
							gridRef={grid => gridRef.current = grid}
							columnWidth={columnWidth}
							gutterWidth={gutterWidth}
							gutterHeight={24}
							itemComponent="div"
							duration={0}
						>
							{whoSaved === 'shop' ?
								map(favorites, (item) => <ShopItem key={item[0].id} item={item[0]} isFavoriteItem/>)
								:
								map(favoritesGenerative, (item) => <GenerativeItem key={item.id} data={item} />)
							}
							{data.loading &&
								Array.from(Array(10), (e, i) => (
									<ShopItem skeleton key={i} item={{id: i}}/>
								))
							}
						</StackGrid>
						:
						<Box className={classes.emptyCart}>
							<Typography className="title">There is nothing here yet</Typography>
							<Box className="buttons">
								<Button variant="contained" className="shop" component={Link} to="/shop">Browse Shop</Button>
								<Button variant="contained" className="generator" component={Link} to="/">Generate Images</Button>
							</Box>
						</Box>
					}
					<Typography className={'Subsription__title'}>Subscription options</Typography>
					<SubscriptionCards />
				</Box>
			</Box>
		</>
	)
}
