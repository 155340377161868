import {Box, Container} from "@mui/material";
import {makeStyles} from "@mui/styles";
import Typography from "@mui/material/Typography";
import {ScrollToTopOnMount} from "../../helpers/navigarions";
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
}));

const NotFoundPage = () => {

	const classes = useStyles();

	return <Container maxWidth="lg">
		<ScrollToTopOnMount />
		<Box display="flex" flexDirection="column" justifyContent="center"
				 alignItems="center" className={classes.oops} gap={10}>
			<Box display="flex" flexDirection="column" alignItems="center" gap={2}>
				<Typography variant="h2">
					Plurana Terms & Conditions
				</Typography>
				<Typography variant="subtitle1">
					Thank you for using Plurana!
				</Typography>
				<Typography variant="subtitle1">
					These Terms of Use apply when you use the services of Plurana B.V.. The Terms include
					our <Link to="/terms/sharing-policy">Sharing & Publication Policy</Link> and
					our <Link to="/terms/privacy-policy">Privacy Policy</Link> that explains how we collect
					and use personal information.
				</Typography>

			</Box>

			<Box>
				<Typography variant="h3">1. Registration and Access</Typography>
				<p>To utilize the Services, you need to be at least 13 years of age. In case you are below 18 years old, you require the consent of your parent or legal guardian to use the Services. If you use the Services on behalf of someone else or a company, you must have the necessary authorization to accept the Terms on their behalf. It is essential to provide correct and thorough information when registering for an account. Sharing your access details or account with individuals outside your organization is prohibited, and you bear responsibility for all actions taken using your credentials.</p>
			</Box>


			<Box>
				<Typography variant="h3">2. Usage Requirements</Typography>
				<ol type="a">
					<li>Service usage. You have the option to access and we provide you with a non-exclusive privilege to utilize the Services, following these Terms. It is required that you abide by these Terms and adhere to all relevant laws while utilizing the Services. The ownership of the Services, including all rights, title, and interest, belongs to us.</li>
					<li>Providing Feedback. We value feedback, comments, ideas, proposals, and suggestions for enhancements. If you share any of these, we reserve the right to utilize them without any limitations or compensation to you.</li>
					<li>Limitations. It is prohibited to (i) employ the Services in a manner that infringes upon, misappropriates, or violates the rights of any individual; (ii) engage in reverse assembling, reverse compiling, decompiling, translating, or any other attempts to uncover the source code or underlying elements of the Services' models, algorithms, and systems (unless such limitations contradict applicable laws); (iii) utilize the output from the Services to create competing models against Plurana; (iv) transmit any personal information of children below 13 years old or the specified age of digital consent to us.</li>
					<li>External Services. The terms and conditions governing your utilization of any third-party software, services, or other products in conjunction with the Services are determined by their respective providers. We disclaim any responsibility for third-party products and their associated terms.</li>
				</ol>
			</Box>

			<Box>
				<Typography variant="h3">3. Content</Typography>
				<ol type="a">
					<li>Your Content. You have the option to provide input to the Services ("Input") and receive output designs generated by the Services based on the Input ("Output designs"). Based on your subscription level you may further download a certain amount of Output designs. Output designs that are downloaded by you become yours. This means you have the freedom to utilize the Content for any purpose, including commercial endeavors like sale or publication, provided you adhere to these Terms.<br/>
						There is an exception to the aforementioned statement. The output design may incorporate a picture or photo, which is utilized by Plurana to visually represent the appearance of a specific output design with an accompanying picture/photo. In such cases, Plurana retains the rights to the picture. For instance, when you generate Instagram posts based on selected colors and your own uploaded graphical elements, Plurana will generate sample posts that include photos or pictures from its library for demonstration purposes. These photos or pictures belong to Plurana, and you are not permitted to use them for any commercial or non-commercial purposes. It is advisable to delete or replace them with your own photos or pictures.<br/>
						Output that is not downloaded by you from the Plurana platform doesn’t belong to you and will be deleted by Plurana within 1 day.<br/>
						You bear the responsibility for the Content and must ensure that it complies with all relevant laws and these Terms.
					</li>
					<li>Content Similarity. Because of the way generative functionality works, it is possible for the Services to produce identical or similar output for Plurana users. This means that if you generate a very simple pattern consisting of squares and choose yellow and blue colors for it you may get identical or very similar results to results of another user with similar requests.  Also if you choose to generate Output design from Plurana own library elements you might get the same result as those users who also chose the same elements from Plurana library for their generative work. It's important to note that responses generated for other users are not considered as part of your own Content.</li>
					<li>Use of Content to Improve Services. We do not use Content to improve our Services. </li>
				</ol>
			</Box>

			<Box>
				<Typography variant="h3">4. Fees and Payments</Typography>
				<ol type="a">
					<li>Fees and Billing. You will pay all fees charged to your account (“Fees”) according to the prices and terms on the applicable pricing page, or as otherwise agreed between us in writing. We have the right to correct pricing errors or mistakes even if we have already issued an invoice or received payment. You will provide complete and accurate billing information including a valid and authorized payment method. We will charge your payment method on an agreed-upon periodic basis, but may reasonably change the date on which the charge is posted. You authorize Plurana and our third-party payment processor(s), to charge your payment method for the Fees. If your payment cannot be completed, we will provide you written notice and may suspend access to the Services until payment is received. Fees are payable in U.S. dollars and are due upon invoice issuance. Payments are nonrefundable except as provided in this Agreement.</li>
					<li>Changes in Pricing. Our prices may be modified by notifying you through your account and/or our website. Price adjustments will become effective 28 days after they are communicated, except for changes made due to legal requirements. Any alterations in prices will be applicable to the fees charged to your account immediately following the effective date of the changes.</li>
					<li>Disputes and Late Payments. If you wish to contest any fees, please reach out to <a href="mailto:support@plurana.com">support@plurana.com</a> within thirty (30) days of receiving the disputed invoice. Uncontested overdue amounts may incur a finance charge of 1.5% per month on the outstanding balance. In case any part of your fees remains unpaid, we may suspend your access to the Services after providing written notice of the overdue payment.</li>
					<li>Free Usage Tier. It is not permissible to create multiple accounts to take advantage of credits offered in the free tier of our Services. If we suspect that you are not utilizing the free tier in good faith, we reserve the right to charge standard fees or discontinue your access to the Services.</li>
				</ol>
			</Box>

			<Box>
				<Typography variant="h3">5. Termination</Typography>
				<p>You have the freedom to terminate these Terms at any point, for any reason, by simply ceasing your use of the Services and Content. On our part, we may terminate these Terms for any reason by giving you a minimum of 30 days' notice in advance. However, if you significantly violate the terms outlined in Section 2 (Usage Requirements), we have the right to terminate these Terms immediately by notifying you.</p>
			</Box>

			<Box>
				<Typography variant="h3">6. General Terms</Typography>
				<ol type="a">
					<li>Party Relationship. These Terms do not establish a partnership, joint venture, or agency between you and Plurana. Plurana and you are separate entities, and neither can obligate the other without written consent.</li>
					<li>Brand Usage. Prior written consent is required to use Plurana's name, logo, or trademark.</li>
					<li>Copyright Infringements. To report intellectual property infringements, please send a notice to the address provided. Plurana may remove infringing content and terminate repeat infringers.

						<p>Plurana B.V.<br/>
							Singel 542,<br/>
							1017 AZ Amsterdam<br/>
							Attn: Director</p>
					</li>
					<li>Assignment and Delegation. You cannot assign or delegate rights or obligations under these Terms without consent. Assignments or delegations will be void. Plurana may assign these Terms in certain circumstances.</li>
					<li>Modifications. We may update these Terms by posting revisions on our website. Significant changes impacting your rights will be notified via email or in-product. Changes take effect after a 30-day notice, except for immediate changes. Continued use after changes constitutes acceptance.</li>
					<li>Notices. All notices must be in writing. We may notify you using the information provided during registration or the associated email.</li>
					<li>Waiver and Severability. Non-action by Plurana does not waive rights for non-compliance.</li>
					<li>Equitable Remedies. Breach of these Terms may cause irreparable harm. Plurana reserves the right to seek injunctive relief and other remedies.</li>
					<li>Entire Agreement. These Terms, excluding Service-specific terms and enterprise agreements, constitute the complete agreement between you and Plurana, replacing prior understandings.</li>
					<li>Jurisdiction, Venue, and Choice of Law. These Terms are governed by Dutch law.</li>
				</ol>
			</Box>

		</Box>

	</Container>
}

export default NotFoundPage;