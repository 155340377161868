import {Box, IconButton, Typography} from "@mui/material";
import {XClose} from "untitledui-js/icons/general";
import './CategoryTag.scss';

export const CategoryTag = ({
    label,
    selected,
    setSelected,
    icon
}) => {
    return (
        <Box className={'CategoryTag' + (selected ? ' CategoryTag--selected' : '')} onClick={!selected ? () => setSelected(true) : undefined}>
            <Typography className={'CategoryTag__Label'}>
                {label}
            </Typography>
        </Box>
    )
}