import * as React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {Modal} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {Link} from "react-router-dom";


const useStyles = makeStyles((theme) => ({
	modalForm: {
		"& .MuiTypography-h5": {
			fontSize: 20
		},
		"&.MuiContainer-root": {
			width: 368,
		},
		backgroundColor: "#FBFAFA",
		borderRadius: 12,
		boxShadow: "0px 4px 14px rgba(204, 204, 204, 0.5)",
		position: "absolute",
		left: "calc(50% - 462px/2)",
		top: "calc(50% - 520px/2)",
		[theme.breakpoints.down('sm')]: {
			left: 0,
		},
		"& .MuiTypography-caption": {
			[theme.breakpoints.down('lg')]: {
				fontSize: 12,
			},
		}
	},
	divider: {
		borderBottom: "1px solid #E6E6E5"
	},
	modalHeader: {
		borderTopLeftRadius: 12,
		borderTopRightRadius: 12,
		height: 190,
		backgroundColor: "#CEC3C1",
		backgroundImage: "url(/static/images/tokens-purchase-success-header.svg)",
		backgroundSize: "contain",
		backgroundRepeat: "no-repeat",
		backgroundPositionY: "bottom",
	}
}));

const WelcomeModal = ({open, onClose}) => {

	// const auth = useContext(AuthContext);
	const classes = useStyles();

	const handleClick = () => {
		onClose();
	}

	return <Modal open={open} onClose={onClose}>
		<Container component="main" maxWidth="xs"  className={classes.modalForm} disableGutters>
			<Box display="flex" flexDirection="row" justifyContent="center" alignItems="center"
					 px={2} py={1} className={classes.modalHeader}>
				{/*<img src="/static/images/tokens-purchase-header.svg" alt=""/>*/}
			</Box>
			<Box p={2} display="flex" flexDirection="column" alignItems="center">
				<Box px={3} textAlign="center">
					<Typography variant="h5" mb={1}>Welcome to Plurana!</Typography>
					<Typography variant="subtitle3">Create 1000+ design pack just from few elements in seconds!</Typography>
					<Typography component="p" py={1} variant="body2">Our Starter plan is always free and allows 25 downloads per month.</Typography>
					<Typography component="p" py={1} variant="body2">Have fun!🍏</Typography>
					<Link to="/terms" onClick={onClose}><Typography variant="caption">Terms and Conditions</Typography></Link>
				</Box>

				<Box m={2}>
					<Button variant="contained" color="secondary" onClick={handleClick}>
						Continue with Plurana
					</Button>
				</Box>

			</Box>
		</Container>
	</Modal>
}

export default WelcomeModal;