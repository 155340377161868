import {useContext, useEffect, useState} from "react";
import {Box, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import {makeStyles} from "@mui/styles";
import Button from "@mui/material/Button";
import AuthContext from "../../contexts/AuthContext";
import useAxios from "axios-hooks";
import {getAxiosHeaders} from "../../api/client";
import ContinueSubscriptionModal from "./ContinueSubscriptionModal";
import {ScrollToTopOnMount} from "../../helpers/navigarions";
import {useScreenType} from "../../hooks/useScreenType";

const useStyles = makeStyles((theme) => ({
	periodToggle: {
		borderRadius: 1000,
		background: "var(--plurana-neutrals-100)",
		height: '44px',
		padding: 4,
		display: 'flex',
		columnGap: '8px',
		[theme.breakpoints.down('sm')]: {
			padding: "4px 0",
		},
		"& .MuiButtonBase-root": {
            color: 'var(--plurana-neutrals-700)',
			height: '36px',
            fontWeight: 400,
			textTransform: "none",
			border: 0,
			borderRadius: 40,
			fontSize: '14px',

			"&.active": {
				color: "var(--plurana-neutrals-700)",
				background: "var(--base-white)",
                fontWeight: 600
			},
			'&:hover': {
				background: "var(--base-white)",
			}
		},
	},
	tier: {
		borderRadius: 32,
		background: "#ffffff",
		boxShadow: "0px 4px 24px 0px #E7E2E1",
		minHeight: '400px',
		padding: "20px",
		display: "flex",
        width: '314px',
		flexDirection: "column",
		justifyContent: "space-between",
		alignItems: "center",
        "& button": {
            
        },
		"& ul": {
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "flex-start",
			gap: '20px',
            padding: 0,
            margin: '30px 0 0 0'
		},
		"& li": {
            fontWeight: 350,
			color: "var(--plurana-neutrals-500)",
			leadingTrim: "both",
			fontSize: '16px',
			lineHeight: "120%"
		},
		"& .title": {
			fontSize: '14px',
            fontWeight: 350,
		},
		"& .price": {
            marginTop: '16px',
			fontSize: '36px',
			fontWeight: 600,
			lineHeight: "100%",
			letterSpacing: "-0.36px",
		},
		"&.starter": {
			color: '#333333',
			outlineColor: '#3F6C51',
			"&.active": {
				outline: `3px solid #C5D2CA`,
                color: '#3F6C51',

                "& button": {
                    background: '#C5D2CA',
                    color: '#3F6C51'
                }
			},
			"&:hover": {
				outline: `3px solid #3F6C51`,
			},
            "& button": {
				height: '48px',
				borderRadius: '52px',
                fontSize: '14px',
                fontWeight: 600,
            }
		},
		"&.light": {
			color: '#333333',
            outlineColor: '#FF66DE',
			"&.active": {
                color: '#FF66DE',
				outline: `3px solid #FFB2EE`,
                "& button": {
                    background: '#FFB2EE',
                    color: '#FF66DE'
                }
			},
			"&:hover": {
				outline: `3px solid #FF66DE`,
			},
            "& button": {
                height: '48px',
                borderRadius: '52px',
                fontSize: '14px',
                fontWeight: 600
            }
		},
		"&.pro": {
			color: '#333333',
            outlineColor: '#990078',
			"&.active": {
                color: '#990078',
				outline: `3px solid #CC7FBB`,
                "& button": {
                    background: '#CC7FBB',
                    color: '#990078'
                }
			},
			"&:hover": {
				outline: `3px solid #990078`,
			},
            "& button": {
				height: '48px',
				borderRadius: '52px',
                fontSize: '14px',
                fontWeight: 600
            }
		},
		"&.enterprise": {
			color: '#333333',
            outlineColor: '#1A0014',
			"&.active": {
                color: '#1A0014',
				outline: `3px solid #8C7F89`,
                "& button": {
                    background: '#8C7F89',
                    color: '#1A0014'
                }
			},
			"&:hover": {
				outline: `3px solid #1A0014`,
			},
            "& button": {
				height: '48px',
				borderRadius: '52px',
                fontSize: '14px',
                fontWeight: 600
            }
		},
	},
}));


const SubscriptionCards = () => {

	const classes = useStyles();
	const auth = useContext(AuthContext);

	const [billingInterval, setBillingInterval] = useState("year");
	const [continueSubscriptionTier, setContinueSubscriptionTier] = useState(null);
	const screenType = useScreenType()

	const [{data}, doGetPaymentUrl] = useAxios({
		method: 'POST',
		url: '/api/subscribe/',
		headers: getAxiosHeaders(),
	}, {manual: true});

	const handleSubscriptionClick = (tier, force_annual=false) => {
		if (auth.user) {
			doGetPaymentUrl({data: {tier: tier, interval: force_annual ? "year" : billingInterval}});
		} else {
			auth.signUpForm(() => {
				setContinueSubscriptionTier(tier)
			})
		}
	}
	const handleContinueSubscriptionModalClose = () => {
		setContinueSubscriptionTier(null);
	}

	useEffect(() => {
		if (data?.payment_url) window.location.href = data.payment_url;
	}, [data])

	const isCurrentTier = (tier) => {
		if (!auth.user) return false;
		if (!tier && !auth.user.subscription?.tier.key) return true
		return auth.user?.subscription?.tier.key === tier
	}

	return <>
		<ScrollToTopOnMount />
		<Box>
			<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mb={8}>
				<Box className={classes.periodToggle}>
					<Button onClick={() => setBillingInterval("month")}
									className={billingInterval === "month" ? "active" : null}>
						Monthly
					</Button>
					<Button onClick={() => setBillingInterval("year")}
									className={billingInterval === "year" ? "active" : null}>
						Annual
					</Button>
				</Box>
			</Box>

			<Box display='flex' justifyContent='center' gap='28px' flexDirection={screenType === 'desktop' ? 'row' : 'column'}>
					<Box className={[classes.tier, "starter", isCurrentTier(null) && "active"]}>
                        <Box textAlign='center'>
                            <Typography className="title">Starter</Typography>
                            <Typography className="price">Free forever</Typography>
                            <ul>
                                <li>Get 500 tokens monthly</li>
                                <li>Generate up to 500 designs</li>
                                <li>Download up to 25 designs</li>
                                <li>Have fun!</li>
                            </ul>
                        </Box>
                        <Box width='100%'>
                            <Button variant="contained" fullWidth color="tierStarter" onClick={() => auth.user ? null : auth.signUpForm()}>
                                {isCurrentTier(null) ? "Subscribed" : "Choose Starter"}
                            </Button>
                        </Box>
					</Box>

					<Box className={[classes.tier, "light", isCurrentTier("light") && "active"]}>
                        <Box textAlign='center'>    
                            <Typography className="title" color='#FF66DE'>Plurana Light</Typography>
                            <Typography className="price">${billingInterval === "month" ? 12 : 9} per month</Typography>
                            <ul>
                                <li>Get 4000 tokens monthly</li>
                                <li>Generate up to 4000 designs</li>
                                <li>Download up to 200 designs</li>
                                <li>Be cool! 🖖</li>
                            </ul>
                        </Box>
                        <Box width='100%'>
                            <Button variant="contained" fullWidth color="tierLight"
                                            onClick={isCurrentTier("light") ? null : () => handleSubscriptionClick("light")}>
                                {isCurrentTier("light") ? "Subscribed" : "Choose Light"}
                            </Button>
                        </Box>
					</Box>
	
					<Box className={[classes.tier, "pro", isCurrentTier("pro") && "active"]}>
                        <Box textAlign='center'>    
                            <Typography className="title" color='#990078'>Plurana Pro</Typography>
                            <Typography className="price">${billingInterval === "month" ? 45 : 35} per month</Typography>
                            <ul>
                                <li>Get 20000 tokens monthly</li>
                                <li>Generate up to 20000 designs</li>
                                <li>Download up to 1000 designs</li>
                                <li>Color the world 🍏</li>
                            </ul>
                        </Box>
                        <Box width='100%'>
                            <Button variant="contained" fullWidth color="tierPro"
                                            onClick={isCurrentTier("pro") ? null : () => handleSubscriptionClick("pro")}>
                                {isCurrentTier("pro") ? "Subscribed" : "Choose Pro"}
                            </Button>
                        </Box>
					</Box>
	
					{/* <Box className={[classes.tier, "enterprise", isCurrentTier("enterprise") && "active"]}>
                        <Box textAlign='center'> 
                            <Typography className="title" color='#1A0014'>Plurana Enterprise</Typography>
                            <Typography className="price">$99 per month</Typography>
                            <ul>
                                <li>Get 80000 tokens monthly</li>
                                <li>Generate up to 80000 designs</li>
                                <li>Download up to 4000 designs</li>
                                <li>Shape the future</li>
                            </ul>
                        </Box>
                        <Box width='100%'>
                            <Button variant="contained" fullWidth color="tierEnterprise"
                                            onClick={isCurrentTier("enterprise") ? null : () => handleSubscriptionClick("enterprise", true)}>
                                {isCurrentTier("enterprise") ? "Subscribed" : "Choose Enterprise"}
                            </Button>
                        </Box>
					</Box> */}
			</Box>
		</Box>
		{continueSubscriptionTier && auth.user && continueSubscriptionTier !== auth.user.subscription?.tier.key ?
			<ContinueSubscriptionModal
				tier={continueSubscriptionTier}
				callback={handleSubscriptionClick}
				onClose={handleContinueSubscriptionModalClose}
			/> : null
		}
	</>
}

export default SubscriptionCards;