import {
	Box,
	Button,
	Container,
	Typography
} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import GeneratorContext from "../../contexts/GeneratorContext";
import useAxios from "axios-hooks";
import {getAxiosHeaders} from "../../api/client";
import LoadingIcon from "../../icons/LoadingIcon";
import GenerativeList from "../../components/Generator/GenerativeList";
import AuthContext from "../../contexts/AuthContext";
import {ScrollToTopOnMount} from "../../helpers/navigarions";
import {makeStyles} from "@mui/styles";
import {CustomizationMenu} from "../../components/Generator/CustomizationMenu";

import {GeneratorOnboardingModal} from "../../components/Generator/GeneratorOnboardingModal";
import {StylePreviewItem} from "../../components/Generator/Set/StylePreviewItem";
import './GeneratorPage.scss'
import {CategoryTag} from "../../components/Generator/CategoryTag";
import {StylesPreviewModal} from "../../components/Generator/StylesPreviewModal";
import {HorizontalScroll} from "../../components/Base";
import {useScreenType} from "../../hooks/useScreenType";
import {PluranaLoaderIcon} from "../../icons";
import {useSearchParams} from "react-router-dom";


const _ = require("lodash");
const amplitude = window.amplitude;

export const GENERATION_LIMIT = 15;

const useStyles = makeStyles((theme) => ({
	generativeList: {
		"& .MuiTypography-caption": {
			fontSize: 14,
		},
	},
}))

export const GeneratorPage = () => {

	const classes = useStyles();
	const generator = useContext(GeneratorContext);
	const auth = useContext(AuthContext);
	const [offset, setOffset] = useState(0);
	const [generative, setGenerative] = useState([]);
	const [generating, setGenerating] = useState(false);
	const [sessionID, setSessionID] = useState(null);
	const [categories, setCategories] = useState([]);
	const [featured, setFeatured] = useState([]);
	const [isStylesModalOpen, setIsStylesModalOpen] = useState(false);
	const [generatorTimer, setGeneratorTimer] = useState(null);
	const screenType = useScreenType();
	const [searchParams, setSearchParams] = useSearchParams()
	const [shouldGenerateFromQuery, setShouldGenerateFromQuery] = useState(false);


	const [{error: startGenerationError}, doStartGeneration] = useAxios({
		method: 'POST',
		url: '/api/generator/start/',
		headers: getAxiosHeaders(),
	}, {manual: true, autoCancel: false})

	const [{data: generativeData, error: checkResultError}, doCheckResult] = useAxios({
		url: '/api/generator/results/',
		headers: getAxiosHeaders(),
	}, {manual: true, autoCancel: false})

	const [{data: setsData, loading, _error}] = useAxios({
		url: '/api/sets/',
		headers: getAxiosHeaders(),
		params: {query: ''},
	}, {});

	const [{error: getCategoriesError}, doGetCategories] = useAxios({
	  url: '/api/generator/categories/',
	  headers: getAxiosHeaders(),
	}, {manual: true, autoCancel: false});

	const [{data: ownSetData}, doCreateSet] = useAxios({
		url: '/api/own-sets/',
		method: "POST",
		headers: getAxiosHeaders(),
	}, {manual: true});

	useEffect(() => {
	  doGetCategories().then(response => {
		setCategories([{id: 0, name: 'All media', selected: true}, ...response.data["results"].map(item => ({...item, selected: false}))]);
	  }).catch(error => {
		console.error('Error fetching categories:', error);
	  });
	  return () => {
		  if (generatorTimer) {
			  clearTimeout(generatorTimer);
			  setGeneratorTimer(null);
		  }
	  }
	}, []);

	useEffect(() => {
		if (searchParams.get('element_set') && searchParams.get('element_set') !== 'null') {
			setShouldGenerateFromQuery(true);
		} else {
			checkGenerationResult("");
		}
	}, [searchParams.get('element_set')])

	useEffect(() => {
		if (setsData && setsData.results) {
			setFirstPage(setsData.results);

			if (shouldGenerateFromQuery && !generating) {
				setShouldGenerateFromQuery(false)
				let setId = Number(searchParams.get('element_set'));
				let selectedSet = setsData.results.filter(item => item.id === setId)

				if (selectedSet.length) {
					generator.setState({
						elements: selectedSet[0].elements.map(item => (
							{...item, selected: true, setId: selectedSet[0].id}
						)),
						colors: selectedSet[0].colors.map(item => (
							{color: item, selected: true, id: Math.random()}
						)),
						elementsSet: selectedSet[0]
					})
				} else {
					checkGenerationResult('')
				}
			}
		}
	}, [setsData, shouldGenerateFromQuery])

	const setFirstPage = (data) => {
		setFeatured(data.sort(item => item.featured === true ? -1 : 1))
		// setLoadedSets({
		// 	visible: _.slice(data, 0, 10),
		// 	hidden: _.slice(data, 10)
		// })
	}

	const getFeatured = (data, count) => {
		return _.sampleSize(_.filter(data, ['featured', true]), count)
	}

	const getRandomPalette = () => {
		if (featured && featured.length) {
			const set = featured[Math.floor(Math.random() * featured.length)]
			return set.colors
		}
	}

	const scrollToGenerator = () => {
		const elem = document.getElementById("gen-editor");
		const y = elem.getBoundingClientRect().top + window.scrollY
		setTimeout(() => window.scrollTo({top: y, behavior: 'smooth'}), 200)
	}

	const generateSessionID = (set, elements, colors, categories) => {
		return [
			set.id,
			elements?.join("-"),
			colors?.join("-"),
			categories?.join("-")
		].join("-");
	}

	const startGeneration = ({set, elements, colors, categories}, reset=false) => {
		if (generatorTimer) {
			clearTimeout(generatorTimer);
			setGeneratorTimer(null);
		}

		const newSessionID = generateSessionID(set, elements, colors, categories)
		setSessionID(newSessionID)

		if (reset) {
			scrollToGenerator();
			setOffset(0);
			setGenerative([]);
		}

		console.log("startGeneration", newSessionID, offset);
		setGenerating(true);
		amplitude?.track('front.generator.started', {
			"set": set.id,
			"elements": elements,
			"colors": colors,
			"categories": categories,
			"customerData": generator.state.customerData,
		});
		
		// TODO: don't withdraw tokens if only categories changed since last call
		auth.withdrawTokens(1)

		prepareGeneratorProps().then(data => {
			//setGenerative([])
			_.defer(() => doStartGeneration({
				headers: getAxiosHeaders(),
				params: {offset: offset, limit: GENERATION_LIMIT},
				data: data
			}).then((response) => {
				if (response.data) handleGenerativeData(response.data)
			}));
		})
	}

	const prepareGeneratorProps = () => {
		return new Promise((resolve, reject) => {
			let imagesToUpload = generator.state.elements.filter(item => !item.png.startsWith('https://') && item.selected);
			if (imagesToUpload.length > 0) {
				doCreateSet({
					data: {
						colors: generator.state.colors.map(item => item.color.toUpperCase()),
						description: '-',
						elements: imagesToUpload.map(item => ({svg: item.png})),
						tags: [],
						title: _.times(20, () => _.random(35).toString(36)).join('')
					}
				}).then(response => {
					let uploadedElementIDs = imagesToUpload.map(item => item.id)
					let filteredElements = generator.state.elements.filter(item => !uploadedElementIDs.includes(item.id))
					let newData = {
						elements: [...filteredElements, ...response.data.elements.map(item => ({
							id: item.id,
							png: item.svg,
							selected: true,
							setId: response.data.id
						}))],
						elementsSet: response.data
					}
					generator.setState(newData);
					resolve({
						elements: newData.elements.filter(item => item.selected).map(item => item.id),
						colors: generator.state.colors.filter(item => item.selected).map(item => item.color.toUpperCase()),
						categories: categories.filter(item => item.selected).map(item => item.id).filter(item => item > 0),
						set: ""
					});
				}).catch(err => {
					reject(err)
				})
			} else {
				resolve({
					elements: generator.state.elements.filter(item => item.selected).map(item => item.id),
					colors: generator.state.colors.filter(item => item.selected).map(item => item.color.toUpperCase()),
					categories: categories.filter(item => item.selected).map(item => item.id).filter(item => item > 0),
					set: ""
				})
			}
		})
	}

	const checkGenerationResult = async (task_id, retry=3) => {
		console.log('checking results')
		try {
			const response = await doCheckResult({
				headers: getAxiosHeaders(),
				params: {task_id: task_id}
			})
			if (response.data) {
				handleGenerativeData(response.data);
			}
		} catch (e) {
			console.log("Error during results check", e)
			if (!e.response?.data) {
				console.log(`${retry} retries left`);
				await checkGenerationResult(task_id, retry-1);
			}
		}
	}

	const handleCategorySelect = (id) => {
		setCategories(categories.map(item => {
			if (item.id === id) {
				return {...item, selected: true}
			}
			return {...item, selected: false}
		}))
	}

	useEffect(() => {
		console.log("elementsSet changed", generator.state.elementsSet);
		//
		if (generator.state.elementsSet === null) {

		} else {
			// console.log('Start generation', 'generator.state.elementsSet')
			startGeneration({
				set: generator.state.elementsSet,
				elements: generator.state.elements,
				colors: generator.state.colors,
			}, true)
		}
	}, [generator.state.elementsSet])

	useEffect(() => {
		console.log("categories changed", categories);
		//
		if (generator.state.elementsSet && !generating) {
			startGeneration({
				set: generator.state.elementsSet,
				elements: generator.state.elements,
				colors: generator.state.colors,
			}, true)
		}
	}, [categories])

	useEffect( () => {
		console.log("offset changed", offset);
		if (offset && generator.state.elementsSet) {
			startGeneration({
				set: generator.state.elementsSet,
				elements: generator.state.elements,
				colors: generator.state.colors
			});
		}
	}, [offset]);

	const handleGenerativeData = (generativeData) => {

		if (!generativeData) return;

		if (generativeData.items) {
			setGenerative( (generative) =>
				generative ? _.concat(generative, generativeData.items) : generativeData.items
			);
		}

		if (generativeData.result === "pending") {
			const task_id = generativeData.task_id
			const timer = setTimeout(() => checkGenerationResult(task_id), 1000)
			setGeneratorTimer(prevState => {
				return timer;
			})
		} else if ( generativeData.result === "error") {
			if (generator.state.elementsSet) {
				amplitude?.track("front.generator.error", {
					"set": generator.state.elementsSet.id,
					"elements": generator.state.elements,
					"colors": generator.state.colors,
					"categories": generator.state.categories,
					"customerData": generator.state.customerData,
				});
			}
			setGenerating(false);
		} else if (generativeData.result === "success") {
			if (generator.state.elementsSet) {
				amplitude?.track("front.generator.finished", {
					"set": generator.state.elementsSet.id,
					"elements": generator.state.elements,
					"colors": generator.state.colors,
					"categories": generator.state.categories,
					"customerData": generator.state.customerData,
				});
			}
			setGenerating(false);
			if (generativeData.tokens) {
				auth.updateTokens(generativeData.tokens)
			}
		}
	}

	return (
		<>
			<ScrollToTopOnMount />

			<Box className={'GeneratorDescriptionCard'}>
				<Typography className={'GeneratorDescriptionCard__Text'}>
					Generate unique vector design in seconds
				</Typography>

				<Box className={'GeneratorDescriptionCard__Controls'}>
					<Button variant={'contained'} onClick={() => setIsStylesModalOpen(true)} className={'Button--black'}>
						Choose style
					</Button>
					{ screenType === 'desktop' &&
						<Button variant={'contained'} onClick={() => {
							document.getElementById("gen-editor").scrollIntoView({behavior: 'smooth'});
							generator.setState({elements: [], colors: [], elementsSet: null})
						}} className={'Button--white'}>
							Start from scratch
						</Button>
					}
				</Box>
			</Box>

			<div id={'gen-editor'} style={{height: 0, width: '100%'}}/>
			<Box className={'GeneratorPresetsList'}>
				{screenType === 'mobile' &&
					<StylePreviewItem
						title={`All styles`}
						onClick={() => setIsStylesModalOpen(true)}
						preview={""}
						isPlaceholder
						titleOnTop
					/>
				}
				<HorizontalScroll
					asideControl={screenType === 'desktop' && <Button onClick={() => setIsStylesModalOpen(true)}>All styles</Button>}
					hideArrows={screenType === 'mobile'}
					controlsMargin={36}
					itemsGap={13}
					rightGradientVariant={'lg'}
				>
					{!loading ?
						<>
							{screenType === 'desktop' &&
								<StylePreviewItem
									title={`Custom Style`}
									selected={!generator.state.elementsSet}
									onClick={() => {generator.setState({elements: [], colors: [], elementsSet: null})}}
									preview={""}
									isCustomStyle
									displayTitle={false}
								/>
							}
							{_.map(featured, (data) =>
								<StylePreviewItem
									key={data.id}
									variant="featured"
									preview={data.preview}
									title={data.title}
									set={data}
									onClick={() => {
										generator.setState({
											elements: data.elements.map(item => (
												{...item, selected: true, setId: data.id}
											)),
											colors: data.colors.map(item => (
												{color: item, selected: true, id: Math.random()}
											)),
											elementsSet: data
										})
									}}
									selected={generator.state.elementsSet?.id === data.id}
									titleOnTop={screenType === 'mobile'}
									displayTitle={screenType === 'mobile'}
								/>
							)}
						</>
						: null
					}
				</HorizontalScroll>
				<Box className={'GeneratorPresetsList--asideControlBackground'}/>
			</Box>

			{ (screenType === 'mobile' && generator.state.elementsSet?.title) &&
				<Box className={'Generator__CurrentStyle'}>
					<div className={'Generator__CurrentStyle-title'}>Generating</div>
					<div className={'Generator__CurrentStyle-style'}>Style: {generator.state.elementsSet.title}</div>
				</Box>
			}

			<Box className={'GeneratorResults'}>
				<Box className={'GeneratorResults__Layout'}>
					{ screenType === 'desktop' &&
						<CustomizationMenu
							startGeneration={startGeneration}
							isGenerating={generating}
							getRandomPalette={getRandomPalette}
							setIsStylesModalOpen={setIsStylesModalOpen}
						/>
					}
					<Box className={'GeneratorResults__Column'}>
						<Box className={'GeneratorResults__Filters__Wrapper'}>
							<Box className={'GeneratorResults__Filters'}>
								{screenType === 'mobile' ?
									<HorizontalScroll hideArrows>
										{categories.map(item => (
											<CategoryTag label={item.name} key={item.id} selected={item.selected} setSelected={(state) => handleCategorySelect(item.id)}/>
										))}
									</HorizontalScroll>
									:
									<Box className={'GeneratorFilters'}>
										{categories.map(item => (
											<Box key={item.id} className={'GeneratorFilters--item' + (item.selected ? ' GeneratorFilters--item-selected' : '')} onClick={() => handleCategorySelect(item.id)}>
												{item.name}
											</Box>
										))}
									</Box>
								}
							</Box>
						</Box>
						<Container maxWidth={false} className={classes.generativeList + ' GeneratorResults__Content'}>
							<GenerativeList items={generative} preview={false} showHeader={false}/>

							{generating && !startGenerationError && !checkResultError && !generative.length ?
								<Box display="flex" flexDirection="column" alignItems="center" className={'GeneratorLoader'}>
									<Box><PluranaLoaderIcon/></Box>
									<Box>
										<Typography component="div" variant="caption" m={'16px'} style={{color: '#FF99E9', fontWeight: 600, maxWidth: 150, textAlign: 'center'}}>
											Generation may take up to a minute.
										</Typography>
									</Box>
								</Box> : null
							}

							{startGenerationError || checkResultError ?
								<Box display="flex" flexDirection="column" alignItems="center">
									<Box>
										<Typography component="div" variant="caption" mt={4}>Oops =( Something went wrong</Typography>
									</Box>
									<Box>
										{startGenerationError ? <Typography component="div" variant="h2">{startGenerationError?.response?.data?.error}</Typography> : null}
										{checkResultError ? <Typography component="div" variant="h2">{checkResultError?.response?.data?.error}</Typography> : null}
									</Box>
								</Box>
								: null}

							{(!generating && generator.state.elementsSet && generative.length >= GENERATION_LIMIT) ?
								<Box display="flex" flexDirection="column" alignItems="center">
									<Button className={'GenerateMoreButton'} color="secondary" onClick={() => setOffset(generative ? generative.length : 0)}>
										Generate more
									</Button>
								</Box>
								: null}
						</Container>
					</Box>
				</Box>
			</Box>

			<GeneratorOnboardingModal/>
			<StylesPreviewModal open={isStylesModalOpen} onClose={() => setIsStylesModalOpen(false)} elements={featured}/>
			{/*{generator.showElementsSet() ? <ElementSetBoard data={generator.state.elementsSet} startGeneration={startGeneration}/> : null}*/}
		</>
	)
}