import React, {useEffect, useRef, useState} from "react";
import useAxios from "axios-hooks";
import {getAxiosHeaders} from "../../api/client";
import _ from "lodash";
import {Container, LinearProgress, Box, useMediaQuery, useTheme} from "@mui/material";
import ShopItem from "./ShopItem";
import StackGrid from "react-stack-grid";

import "./ShopItemsList.scss";
import GenerativeItem from "../Generator/GenerativeItem";


const ShopItemsList = ({props}) => {
	const [shopItems, setShopItems] = useState([]);
	const [offset, setOffset] = useState(0);
	const [hasMore, setHasMore] = useState(false);

	const [{data, loading}] = useAxios({
		url: '/api/products/',
		headers: getAxiosHeaders(),
		params: {...props},
	}, {useCache: false});

	useEffect(() => {
		if (data?.results?.length > 0) {
			setHasMore(offset + data.results.length < data.count);
			setShopItems( (items) => items ? _.concat(items, data.results) : data.results);
		}
	}, [data]);

	const theme = useTheme();
	const gridRef = useRef(null);

	const tablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
	const mobile = useMediaQuery(theme.breakpoints.down("sm"));

	const isDesktopLg = useMediaQuery('(min-width: 1400px)');

	let columnWidth = "25%";
	let gutterWidth = 16;

	if (isDesktopLg) {
		columnWidth = '20%';
	}

	if (tablet || mobile) {
		columnWidth = "50%";
		gutterWidth = 10
	}

	useEffect(() => {
		gridRef.current?.updateLayout()
	}, [shopItems])

	return (
		<Container maxWidth={false} disableGutters className={'ShopItemsList'}>
			<StackGrid
				className={'ShopItemsList__Grid'}
				gridRef={grid => gridRef.current = grid}
				columnWidth={columnWidth}
				itemComponent="div"
				duration={0}
				appearDelay={0}
				gutterWidth={gutterWidth}
				gutterHeight={!loading ? 24: gutterWidth}
			>
				{
					!loading ?
						_.map(shopItems, (item) => <ShopItem key={item.id} item={item} />)
						:
						Array.from(Array(8), (e, i) => (
							<ShopItem skeleton key={i} item={{id: i}}/>
						))
				}
			</StackGrid>
		</Container>
	)
}

export default ShopItemsList;