import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import {Download02} from "untitledui-js/icons/general"
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import {ChevronDown} from 'untitledui-js/icons/arrow'
import './SplitButton.scss';
import {useScreenType} from "../../hooks/useScreenType";

const SplitButton = ({
	variant="contained", color="secondary", disabled=false,
	options=[{}], onClick, onSelect, label=undefined
}) => {
  const [open, setOpen] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState(options[0]);
  const anchorRef = React.useRef(null);
  const screenType = useScreenType();

  const handleClick = () => {
		if (Boolean(onClick) && (onClick instanceof Function)) {
			onClick(selectedOption.value);
		}
  };

  const handleMenuItemClick = (option) => {
    setSelectedOption(option);
    setOpen(false);

		if (Boolean(onSelect) && (onSelect instanceof Function)) {
			onSelect(option.value);
		}
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup variant={'text'} disabled={disabled} ref={anchorRef} className={'SplitButton'}>
        <Button startIcon={screenType === 'desktop' && <Download02 size={18}/>} onClick={!label ? handleClick : handleToggle}>{label || selectedOption.label}</Button>
        <Button onClick={handleToggle} className={open ? "pressed" : ""}>
          <ChevronDown size={16}/>
        </Button>
      </ButtonGroup>
      <Popper
        sx={{ zIndex: 4, width: anchorRef?.current?.width }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal={screenType === 'desktop'}
        placement="bottom-end"
        className={'SplitButton__Popper'}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem>
                  {options.map((option) => (
                    <MenuItem
                      key={option.value}
                      selected={!label ? option.value === selectedOption.value : false}
                      onClick={() => handleMenuItemClick(option)}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}

export default SplitButton