import {Box, Button, Fade, IconButton, Modal} from "@mui/material";
import React, {useState} from "react";
import './GeneratorOnboardingModal.scss';
import {XClose} from "untitledui-js/icons/general";

const ONBOARDING_SLIDES = [
    {
        image: '',
        title: 'Generate thousands of unique design variations',
        description: 'Effortlessly create a world of design possibilities united by a visual style, historic inspiration, or a theme in mere seconds. Simply choose your style from our gallery and play with the results.',
    },
    {
        image: '',
        title: 'Upload your content',
        description: 'Transform your ideas into stunning designs by uploading your own content and watch it come to life in entirely new ways.',
    },
    {
        image: '',
        title: 'Edit with precision',
        description: 'Customize and fine-tune your results as all designs are in vector format, giving you full control over every detail. Simply download any image in SVG format.',
    },
    {
        image: '',
        title: 'Explore and inspire',
        description: 'Dive into a treasure trove of historic artwork and world cultural heritage, sparking your creativity and broadening your horizons.'
    },
]

export const GeneratorOnboardingModal = () => {
    const [open, setOpen] = useState(localStorage.getItem('GeneratorOnboardingShown') !== 'true')
    const [currentSlide, setCurrentSlide] = useState(0);

    const finishOnboarding = () => {
        setOpen(false);
        localStorage.setItem('GeneratorOnboardingShown', 'true');
    }

    return (
        <Modal open={open} onClose={finishOnboarding} className={'GeneratorOnboardingModal'}>
            <Fade in={open}>
                <Box className={'GeneratorOnboarding__Wrapper'}>
                    <Box className={'GeneratorOnboarding'}>
                        <IconButton className={'GeneratorOnboarding__CloseButton'} onClick={finishOnboarding}>
                            <XClose size={'16px'}/>
                        </IconButton>
                        <Box className={'GeneratorOnboarding__Image'}>
                            <img alt={''} src={ONBOARDING_SLIDES[currentSlide]['image']}/>
                        </Box>
                        <Box className={'GeneratorOnboarding__Title'}>
                            {ONBOARDING_SLIDES[currentSlide]['title']}
                        </Box>
                        <Box className={'GeneratorOnboarding__Description'}>
                            {ONBOARDING_SLIDES[currentSlide]['description']}
                        </Box>
                        <Box className={'GeneratorOnboarding__Progress'}>
                            {ONBOARDING_SLIDES.map((_, index) => (
                                <div
                                    className={'GeneratorOnboarding__Progress__Dot' + (index === currentSlide ? ' GeneratorOnboarding__Progress__Dot-active' : '')}
                                    onClick={() => setCurrentSlide(index)}
                                    key={index}
                                />
                            ))}
                        </Box>
                        <Box className={'GeneratorOnboarding__Controls'}>
                            {
                                (currentSlide === 0) ?
                                    <Button onClick={finishOnboarding}>Skip</Button>
                                : (ONBOARDING_SLIDES.length - 1 > currentSlide > 0) ?
                                    <Button onClick={() => setCurrentSlide(currentSlide - 1)}>Back</Button>
                                : null
                            }
                            {currentSlide < ONBOARDING_SLIDES.length - 1 ?
                                <Button onClick={() => setCurrentSlide(currentSlide + 1)}>Next</Button>
                                :
                                <Button onClick={finishOnboarding} className={'GeneratorOnboarding__Controls__FinishButton'}>
                                    Start Generating
                                </Button>
                            }
                        </Box>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    )
}
