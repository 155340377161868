import React from 'react';
import {SvgIcon} from "@mui/material";

function PluranaTokenIcon(props) {
  return (
    <SvgIcon viewBox="0 0 5 5" {...props}>
      <path d="M5 2.49999C5 3.8807 3.8807 5 2.49999 5C1.11929 5 0 3.8807 0 2.49999C0 1.11929 1.11929 0 2.49999 0C3.8807 0 5 1.11929 5 2.49999Z" fill="#FF99E9"/>
      <path d="M4.30618 2.50087C4.30618 3.49805 3.49781 4.30642 2.50063 4.30642C1.50344 4.30642 0.695068 3.49805 0.695068 2.50087C0.695068 1.50369 1.50344 0.695312 2.50063 0.695312C3.49781 0.695312 4.30618 1.50369 4.30618 2.50087Z" fill="#FF66DE"/>
      <path d="M3.61138 2.50076C3.61138 3.11441 3.11392 3.61187 2.50027 3.61187C1.88662 3.61187 1.38916 3.11441 1.38916 2.50076C1.38916 1.88711 1.88662 1.38965 2.50027 1.38965C3.11392 1.38965 3.61138 1.88711 3.61138 2.50076Z" fill="#FF2BD1"/>
    </SvgIcon>
  );
}

export default PluranaTokenIcon;