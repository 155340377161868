import {createTheme, darken} from "@mui/material/styles";
import {lighten} from "@mui/material";

export const ButtonsVariants = {
  squareElement: {
    width: 48,
    height: 48,
    minWidth: 48,
    minHeight: 48,
    padding: 0,
    borderRadius: 9,
    backgroundColor: "#ffffff",
    marginLeft: 2,
  },
  circleColor: {
    width: 36,
    height: 36,
    minWidth: 36,
    minHeight: 36,
    borderRadius: 36,
    padding: 0,
    marginTop: 6,
    backgroundColor: "#ffffff",
    marginLeft: 2,
  },
}

const theme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
        elementSet: {
          background: "#var(--plurana-neutrals-100)",
          borderRadius: 10,
        },
        "split-button-secondary": {
          color: "#FC68DB"
        },
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#ffffff",
          color: "#000000"
        },
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: "#ffffff",
          borderRadius: 10,
          boxShadow: "0px 3px 5px #E3E3E2",
        },
        notchedOutline: {
          border: 0,
        },
        input: {
          color: "#999999",
          padding: "10px 14px",
          fontSize: 16,
          fontWeight: 450
        }
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: 4,
          height: 50,
          minWidth: 120,
          padding: 15
        },
        outlined: {
          border: "1px solid #E0E0E0",
          borderRadius: 40,
        },
        circled: {
          "&:hover": {
            backgroundColor: darken("#FBFAFA", 0.1),
          },
          width: 50,
          minWidth: 48,
          padding: 0,
          // border: "1px solid #E0E0E0",
          borderRadius: 100,
          backgroundColor: "#FBFAFA"
        },
        squareElement: ButtonsVariants.squareElement,
        circleColor: ButtonsVariants.circleColor,
        searchPrimary: {
          backgroundColor: "#333333",
          "&:hover": {
            backgroundColor: lighten("#333333", 0.1),
          },
          color: "#fff"
        },
        containedPrimary: {
          backgroundColor: "#F1F1F1",
          "&:hover": {
            backgroundColor: darken("#F1F1F1", 0.1),
          },
          "&:pressed": {
            backgroundColor: darken("#F1F1F1", 0.1),
          },
          color: "#333333"
        },
        containedSecondary: {
          backgroundColor: "#FC68DB",
          "&:hover": {
            backgroundColor: "#FF2BD0",
          },
          "&.pressed": {
            backgroundColor: "#FF2BD0",
          },
          color: "#ffffff",
          "&.Mui-disabled": {
            backgroundColor: "#FC68DB",
            color: "#ffffff",
            opacity: 0.4
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          display: "none"
        }
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          background: "#fff",
          paddingLeft: 40,
          paddingRight: 40,
          fontSize: 16,
          "&.Mui-selected": {
            "&:before, &:after": {
              content: `""`,
              display: "block",
              width: 40,
              height: 40,
              position : "absolute",
              borderWidth: 10,
              borderStyle: "solid",
              borderRadius: "100%",
              borderColor: " #fff transparent transparent transparent",
              top: -10,
            },
            "&:before": {
              transform : "rotate(-48deg)",
              left: -10,
            },
            "&:after": {
              transform : "rotate(48deg)",
              right: -10,
            },
            background: "transparent",
          }
        },
      },
    }
  },
  palette: {
    primary: {
      main: "#333333",
      light: "#63a4ff",
      dark: "#004ba0",
      contrastText: "#fff",
    },
    secondary: {
      main: "#dc004e",
      light: "#ff5c8d",
      dark: "#9a0036",
      contrastText: "#fff",
    },
    success: {
      main: "#30AF4C",
      light: "#81c784",
      dark: "#388e3c",
      contrastText: "#fff",
    },
    warning: {
      main: "#ff9800",
      light: "#ffb74d",
      dark: "#f57c00",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    error: {
      main: "#f44336",
      light: "#e57373",
      dark: "#d32f2f",
      contrastText: "#fff",
    },
    info: {
      main: "#2196f3",
      light: "#64b5f6",
      dark: "#1976d2",
      contrastText: "#fff",
    },
    text: {
      primary: "#333333",
      secondary: "#ABABAB",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
    },
    black: {
      main: "#000000",
      contrastText: "#ffffff"
    },
    background: {
      default: "#fff",
      paper: "#fff",
    },
    loader: {
      main: "#ff5c8d",
    },
    tierStarter: {
      main: "#3F6C51",
      light: "#B4DCC5",
      contrastText: "#FFFFFF",
    },
    tierLight: {
      main: "#FF66DE",
      light: lighten("#FF66DE", 0.1),
      contrastText: "#FFFFFF",
    },
    tierPro: {
      main: "#990078",
      light: lighten("#990078", 0.1),
      contrastText: "#FFFFFF",
    },
    tierEnterprise: {
      main: "#1A0014",
      light: lighten("#1A0014", 0.1),
      contrastText: "#FFFFFF",
    },
  },
  typography: {
    fontFamily: "Inter",
    fontSize: 16,
    fontWeightRegular: 250,
    fontWeightBold: 450,
    logo: {
      fontSize: 27,
      fontWeight: 450,
      lineHeight: "25px",
      letterSpacing: "-0.02em",
    },
    h1: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 450,
      fontSize: 62,
      color: "#1A1A19",
      lineHeight: "100%",
    },
    h2: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 450,
      fontSize: 46,
      lineHeight: "60px",
      color: "#1A1A19",
      letterSpacing: "-0.46px"
    },
    h3: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      letterSpacing: '-0.4px',
      fontSize: 20,
      color: "#1A1A19",
      lineHeight: "100%",
    },
    subtitle1: {
      fontWeight: 450,
    },
    subtitle2: {
      fontFamily: "Inter",
      fontWeight: 400,
      fontSize: 18,
      lineHeight: "130%",
      letterSpacing: "-0.36px",
      textAlign: "center",
    },
    subtitle3: {
      fontWeight: 450,
      fontSize: 16,
      lineHeight: "120%",
      color: "#999999",
      textAlign: "center",
    },
    caption: {
      fontSize: "1vw",
      fontWeight: 250,
      lineHeight: "15px",
      color: "#ABABAB"
    },
    small: {
      fontSize: "1vw",
      fontWeight: 250,
      lineHeight: "15px",
    },
    titledCaption: {
      fontStyle: "normal",
      fontWeight: 450,
      fontSize: "14px",
      lineHeight: "130%",
      leadingTrim: "both",
      textEdge: "cap",
      letterSpacing: "0.02em",
      textTransform: "uppercase",
      color: "#B3B3B2",
    },
    note: {
      lineHeight: "120%",
      color: "#767675",
      textEdge: "cap",
      fontSize: 12,
      fontWeight: 300,
    }
  },
});

export default theme;