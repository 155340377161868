import {getCurrentToken} from "../helpers/profile";

export const getAxiosHeaders = (token) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  token = token || getCurrentToken();
  if (token) {
    headers['Authorization'] = 'Token ' + token;
  }

  return headers;
}
