import {Box, Chip, Link, Menu, MenuItem, Divider, ListItemIcon} from "@mui/material";
import AuthContext from "../../contexts/AuthContext";
import {useContext, useState} from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {makeStyles} from "@mui/styles";
import { Loading01, HeartRounded } from "untitledui-js/icons/general";
import {Link as RouterLink } from "react-router-dom";
import {capitalize} from "lodash";

import "./DesktopAppBar.scss";
import "./HeaderUserProfile.scss";
import {ProfileIcon} from "../../icons";


const useStyles = makeStyles(() => ({
	text: {
		color: "rgba(26, 26, 25, 1)",
		fontSize: "14px",
		fontWeight: 350,
	},
	userMenu: {
		"& .MuiPaper-root": {
			borderRadius: 12,
			boxShadow: "0px 4px 14px 0px rgba(54, 52, 55, 0.09)",
		},
		"& .MuiButtonBase-root": {
			fontSize: 14,
			padding: "12px 18px",
			"&:not(:last-child)": {
				borderBottom: "1px solid #F2F2F2",
			},
		},
	},
	chip: {
		"&.MuiChip-root": {
			cursor: "pointer",
			color: "#FF99E9",
			borderRadius: 6,
			backgroundColor: "#FFE5F9"
		},
	},
	tier: {
		display: "flex",
		padding: "5px 8px",
		justifyContent: "center",
		alignItems: "center",
		gap: 6,
		borderRadius: 4,
		fontSize: 14,
		background: "#E8F6EE",
		color: "#3F6C51",
	}
}));


const HeaderUserProfile = ({handleOpenTokensPurchaseModal, onProfileOpen=() => {}, onProfileClose=() => {}}) => {

	const auth = useContext(AuthContext);
	const classes = useStyles();
	const [anchorElUser, setAnchorElUser] = useState(null);

	const handleOpenUserMenu = (event) => {
		setAnchorElUser(event.currentTarget);
		onProfileOpen()
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
		onProfileClose()
	};

	console.log(auth.user);

	if (auth.user) {
		return <>
			<Link className={'Link__avatar'} component={"button"} onClick={handleOpenUserMenu}>
				<ProfileIcon width={'20px'} height={'20px'}/>
			</Link>

			<Menu
				className={'Menu__personal__area'}
				anchorEl={anchorElUser}
				anchorOrigin={{vertical: 'top', horizontal: 'right'}}
				PaperProps={{elevation: 0}}
				keepMounted
				transformOrigin={{vertical: 'top', horizontal: 'right'}}
				open={Boolean(anchorElUser)}
				onClose={handleCloseUserMenu}
			>
				<MenuItem disabled style={{opacity: 1, padding: 0}}>
					<Box className={'Menu__item__email__box'}>
						<Typography className={'Menu__item__email'}>{auth.user.email}</Typography>
						<Box className={'Menu__item__email__plan'}>
							<Typography className={`Menu__item__email__plan__text__${auth.user?.subscription?.tier.key === undefined ? "starter" : auth.user?.subscription?.tier.key}`}>
								{auth.user.subscription ? `${capitalize(auth.user.subscription.tier.key)} Plan` : "Starter Plan"}
							</Typography>
						</Box>
					</Box>
				</MenuItem>

				{/*<Divider style={{margin: '24px 5px 0 5px'}} />*/}

				<MenuItem style={{opacity: 1}} sx={{padding: '17px 0 17px 0', "& :hover": {borderRadius: 0}}}>
					<Box className={'Menu__item__token__box'}>
						<Box className={'Menu__item__token__box__info'}>
							<Box className={'TokenCounter'}>
								<Loading01 size='16px' />
								<Typography className={'Menu__item__token'}>{auth.user.tokens} tokens</Typography>
							</Box>
							<Button className={'AddTokensButton'} onClick={() => handleOpenTokensPurchaseModal("start")}>
								Add tokens
							</Button>
						</Box>
						{/* <Chip className={'Menu__item__token__button'} label="Add tokens" /> */}
					</Box>
				</MenuItem>

				<MenuItem style={{padding: '17px 0 17px 0'}} component={RouterLink} to="/terms">
					<Typography className={'Menu__item__terms'}>Purchases</Typography>
				</MenuItem>

				<MenuItem style={{padding: '17px 0 17px 0'}} component={RouterLink} to="/favorites">
					<Box className={'Menu__item__token__box'}>
						<Box className={'Menu__item__token__box__info'}>
							<Box className={'TokenCounter'}>
								<HeartRounded size='16px' strokeWidth={'1.6px'}/>
								<Typography className={'Menu__item__token'}>Saved</Typography>
							</Box>
						</Box>
					</Box>
				</MenuItem>

				<MenuItem style={{padding: '17px 0 17px 0'}} component={RouterLink} to="/terms">
					<Typography className={'Menu__item__terms'}>Terms & conditions</Typography>
				</MenuItem>

				{/*<Divider style={{margin: '0 5px'}} />*/}

				<MenuItem style={{padding: '17px 0 17px 0'}} onClick={() => {auth.logout(); onProfileClose();}}>
					<Typography className={'Menu__item__terms'}>Sign out</Typography>
				</MenuItem>
				
				<MenuItem component={RouterLink} to="/pricing">
					<Box className={'Menu__item__upgrade__box'}>
						<Button className={'Menu__item__upgrade_button'}>Save 40% and subscribe</Button>
					</Box>
				</MenuItem>
			</Menu>

		</>
	}

	return (
		<Link sx={{textDecoration: "none"}} component={RouterLink} onClick={() => auth.signInForm()}>
			<Box id="search" display={"flex"} alignItems={"center"} justifyContent={"center"}>
				<Typography className={classes.text} variant={classes.text}>Sign in</Typography>
			</Box>
		</Link>
	)
}

export default HeaderUserProfile;