import {Box, Container, InputAdornment,TextField, Typography} from "@mui/material";
import SearchIcon from "../../icons/SearchIcon";
import React, {useEffect, useState} from "react";
import useDebounce from "../../helpers/debounce";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import SelectIcon from "../../icons/SelectIcon";
import { useNavigate } from "react-router-dom";
import {useParams} from "react-router-dom";
import {useScreenType} from "../../hooks/useScreenType";


import './SearchBoard.scss';
import {XClose} from "untitledui-js/icons/general";


const amplitude = window.amplitude;


const ShopSearchBoard = ({searchQuery, setSearchQuery, variant='spaced', onKeyDown=(e) => {}}) => {
	const [isFocused, setIsFocused] = useState(false);
	const params = useParams();  
	const navigate = useNavigate();
	const debouncedQuery = useDebounce(searchQuery);
	const screenType = useScreenType();

	// const [{data: setsData, loading, _error}] = useAxios({
	// 	url: '/api/sets/',
	// 	headers: getAxiosHeaders(),
	// 	params: {query: debouncedQuery},
	// }, {});

	useEffect(() => {
		if (debouncedQuery) amplitude?.track('front.shop.search', {'query': debouncedQuery});
	},
	[debouncedQuery])

	const a = {
		'reconstruction': 'Cultural design',
		'pattern': 'Patterns',
		'freebies': 'Freebies',
		undefined: 'All visuals',
	};
	const [currentItem, setCurrentItem] = React.useState(a[params.slug] || 'All visuals');
	const items = ['All visuals', 'Cultural design', 'Patterns', 'Freebies'];

	useEffect(() => {
		setCurrentItem(a[params.slug] || 'All visuals');
	}, [params.slug])

	const handleChange = (event) => {
		setCurrentItem(event.target.value);

		if (event.target.value === 'All visuals') {
			navigate(`/shop/catalog`);
			// navigate(0);
		} else if (event.target.value === 'Cultural design') {
			navigate(`/shop/catalog/reconstruction`);
			// navigate(0);
		} else if (event.target.value === 'Patterns') {
			navigate(`/shop/catalog/pattern`);
			// navigate(0);
		} else if (event.target.value === 'Freebies') {
			navigate(`/shop/catalog/freebies`);
			// navigate(0);
		}
	};

	return (
		<Container maxWidth={false} m={0} disableGutters>
			<Box class={"Board__box Board__box-" + variant}>
				<Box class={"search" + (isFocused ? ' search-focused' : '')} style={{
					display: 'flex',
					width: '100%',
					maxWidth: variant !== 'header' ? '1334px' : 'calc(100% - 26px)',
					alignItems: 'center',
					zIndex: 1,
					border: '1px solid var(--plurana-neutrals-200, #EAECF0)',
					height: variant !== 'header' ? '56px' : '100%',
					justifyContent: 'center',
					borderRadius: '28px',
					// boxShadow: '0px 4px 14px 0px rgba(112, 112, 112, 0.10)',
					background: 'rgba(255, 255, 255, 1)',
				}}>
					<TextField sx={{
						'& .MuiInputBase-root': {
							boxShadow: 'none',
							background: 'none',
							height: variant !== 'header' ? '56px' : '100%',
            				padding: '0 0 0 16px'
						},
						'& .MuiInputBase-input': {
							boxShadow: 'none',
							display: 'flex',
							background: 'none',
							justifyContent: 'center',
							fontSize: variant !== 'header' ? '16px' : '14px',
							fontWeight: '350',
							padding: '0 0 0 2px !important',
							color: 'var(--plurana-neutrals-900, #667085)'
						},
					}}
						fullWidth
						value={searchQuery} onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)}
						onChange={(e) => setSearchQuery(e.target.value)}
						placeholder={'Search'}
					    onKeyDown={onKeyDown}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start" style={{color: "var(--plurana-neutrals-400)"}}>
									<SearchIcon width='20px' height='20px' style={{stroke: 'currentColor'}}/>
								</InputAdornment>
							),
							endAdornment: (
								<InputAdornment position={'end'} onClick={() => setSearchQuery('')}>
									{searchQuery ?
										<div className={'ClearSearchButton'}>
											<XClose size={'12px'} stroke={'var(--plurana-neutrals-500)'}/>
										</div>
										:
										<div className={'ClearSearchButton ClearSearchButton--placeholder'}/>
									}
								</InputAdornment>
							)
					}} />
					{variant !== 'header' &&
						<Select
							sx={{
								marginRight: '6px',
								borderRadius: '28px',
								boxShadow: 'none',
								'& .MuiSvgIcon-root': {
									marginTop: '4px',
									marginRight: '4px',
									height: '20px',
									width: '20px'
								}
							}}
							id='Select__items'
							value={currentItem}
							onChange={handleChange}
							IconComponent={SelectIcon}
							MenuProps={{className: 'SearchSelectPopover'}}
						>
							{items.map((item) => (
								<MenuItem id='Select__item' key={item} value={item}>{item}</MenuItem>
							))}
						</Select>
					}
				</Box>
			</Box>
		</Container>
	);
}

export default ShopSearchBoard;