import {InputAdornment, TextField} from "@mui/material";
import SearchIcon from "../../icons/SearchIcon";
import React from "react";

import SearchFilters from "./SearchFilters";

export const SearchString = () =>
  <React.Fragment>
    <TextField
      fullWidth
      placeholder="Find something"
      InputProps={{
        sx: {
          borderRight: 0,
        },
        startAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }} />
    <SearchFilters />
  </React.Fragment>