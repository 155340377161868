import {Typography, Box} from "@mui/material";
import SubscriptionCards from "../../components/Subscription/SubscriptionCards";
import { HelpContent } from "../../components/Help/HelpContent";


const SubscriptionPage = () => {

	return (
	<>
		<Typography textAlign='center' sx={{
				fontSize: '36px',
				fontWeight: 600,
				color: '#1A1A19',
				lineHeight: '100%',
				marginBottom: '64px',
				marginTop: '54px'
			}}>Subscription options
		</Typography>

		<SubscriptionCards />
		<Box marginTop='96px'>
			<HelpContent />
		</Box>
	</>
	);
}

export default SubscriptionPage;