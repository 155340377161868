import {Link} from "react-router-dom";
import {Box} from "@mui/material";
import React from "react";
import './BreadCrumbs.scss'

export const BreadCrumbs = ({items}) => {
    return (
        <Box className={'BreadCrumbs'}>
            {items.map((item, i) => {
                if (i + 1 !== items.length) {
                    return (
                        <React.Fragment key={i}>
                            <Link to={item[0]} className={'BreadCrumbs__Item'}>{item[1]}</Link>
                            <span className={'BreadCrumbs__Separator'}> / </span>
                        </React.Fragment>
                    )
                }
                return <Link to={item[0]} className={'BreadCrumbs__Item'}>{item[1]}</Link>
            })}
        </Box>
    )
}