import {Box, IconButton, Typography} from "@mui/material";
import {XClose} from "untitledui-js/icons/general";
import './FilterTag.scss';

export const FilterTag = ({
    label,
    selected,
    setSelected,
    icon
}) => {

    return (
        <Box className={'FilterTag' + (selected ? ' FilterTag--selected' : '')} onClick={!selected ? () => setSelected(true) : undefined}>
            <Box className={'FilterTag__Icon'}>
                <img src={icon} alt={''} style={{maxHeight: 16, maxWidth: 16}}/>
            </Box>
            <Typography className={'FilterTag__Label'}>
                {label}
            </Typography>
            <IconButton className={'FilterTag__Control'} onClick={() => setSelected(false)}>
                <XClose size={'16px'} stroke={'currentColor'} strokeWidth={1.4}/>
            </IconButton>
        </Box>
    )
}