import React from 'react';
import { SvgIcon } from '@mui/material';

function ShoppingBagIcon(props) {
  return (
    <SvgIcon viewBox="0 0 18 21" fill="white" {...props}>
        <path d="M5.09838 6.35434H2.54561L1.29852 19.12H16.2017L15.1327 6.35434H12.5796M5.09838 6.35434C5.07859 4.77102 5.799 1.60437 8.839 1.60437C11.879 1.60437 12.5994 4.77102 12.5796 6.35434M5.09838 6.35434H12.5796" fill="white" stroke="#333333" strokeWidth="2"/>
    </SvgIcon>
  );
}

export default ShoppingBagIcon;