import React from 'react';
import {SvgIcon} from "@mui/material";

export function EyeDropperIcon(props) {
  return (
      <SvgIcon sx={{...props}} viewBox={'0 0 10 10'}>
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
              <path d="M9.20456 1.9251L8.07495 0.795498C7.88669 0.60723 7.58256 0.60723 7.39429 0.795498L5.88815 2.30164L4.95647 1.37961L4.27581 2.06027L4.96129 2.74576L0.655273 7.05178V9.34478H2.94828L7.2543 5.03876L7.93979 5.72425L8.62045 5.04359L7.69359 4.11673L9.19973 2.61059C9.39283 2.4175 9.39283 2.11337 9.20456 1.9251ZM2.54761 8.37931L1.62075 7.45245L5.51162 3.56159L6.43847 4.48844L2.54761 8.37931Z" fill="currentColor"/>
          </svg>
      </SvgIcon>
  );
}