import React from 'react';

export const AuthContext = React.createContext({
  user: null,
  session: null,
  fetch: () => {},
  logout: () => {},
  withdrawTokens: () => {},
  updateTokens: () => {},
  signInForm: () => {},
  signUpForm: () => {},
  welcomeModal: () => {},
});

export default AuthContext;
