import React from 'react';
import {SvgIcon} from "@mui/material";

export function GalleryPlusIcon(props) {
    return (
        <SvgIcon sx={{...props}} viewBox={'0 0 49 49'}>
            <svg xmlns="http://www.w3.org/2000/svg" width="49" height="49" viewBox="0 0 49 49" fill="none" stroke={'currentColor'}>
                <path d="M25.5 6.5H16.1C12.7397 6.5 11.0595 6.5 9.77606 7.15396C8.64708 7.7292 7.7292 8.64708 7.15396 9.77606C6.5 11.0595 6.5 12.7397 6.5 16.1V32.9C6.5 36.2603 6.5 37.9405 7.15396 39.2239C7.7292 40.3529 8.64708 41.2708 9.77606 41.846C11.0595 42.5 12.7397 42.5 16.1 42.5H34.5C36.3599 42.5 37.2899 42.5 38.0529 42.2956C40.1235 41.7408 41.7408 40.1235 42.2956 38.0529C42.5 37.2899 42.5 36.3599 42.5 34.5M38.5 16.5V4.5M32.5 10.5H44.5M21.5 17.5C21.5 19.7091 19.7091 21.5 17.5 21.5C15.2909 21.5 13.5 19.7091 13.5 17.5C13.5 15.2909 15.2909 13.5 17.5 13.5C19.7091 13.5 21.5 15.2909 21.5 17.5ZM30.4801 24.3363L13.5623 39.7161C12.6107 40.5812 12.1349 41.0137 12.0929 41.3884C12.0564 41.7132 12.1809 42.0353 12.4264 42.2511C12.7096 42.5 13.3526 42.5 14.6386 42.5H33.412C36.2903 42.5 37.7295 42.5 38.8598 42.0164C40.2789 41.4094 41.4094 40.2789 42.0164 38.8598C42.5 37.7295 42.5 36.2903 42.5 33.412C42.5 32.4435 42.5 31.9593 42.3941 31.5083C42.2611 30.9416 42.0059 30.4107 41.6465 29.9528C41.3605 29.5884 40.9824 29.2859 40.2261 28.6809L34.6317 24.2053C33.8748 23.5998 33.4963 23.2971 33.0796 23.1902C32.7123 23.096 32.3257 23.1082 31.9651 23.2254C31.5559 23.3583 31.1973 23.6843 30.4801 24.3363Z" stroke-width="3.8" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </SvgIcon>
    );
}