import React, {useState, useEffect, useRef} from "react";
import {Box, Typography, Button, Container, useTheme} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";

import useScrollPosition from "@react-hook/window-scroll";
import useAxios from "axios-hooks";
import {getAxiosHeaders} from "../../api/client";
import _ from "lodash";
import {useParams} from "react-router-dom";

import ShopItemsList from "../../components/Shop/ShopItemsList";
import ShopSearchBoard from "../../components/Shop/SearchBoard";
import SubscriptionCards from "../../components/Subscription/SubscriptionCards";
import { HelpContent } from "../../components/Help/HelpContent";
import {useScreenType} from "../../hooks/useScreenType";
import {Star06} from 'untitledui-js/icons/shapes';
import HeroBackground from './HeroBackground.jpg';

import "./ShopIndexPage.scss";
import {PluranaButton} from "../../components/Base";
import {ArrowRight} from "untitledui-js/icons/arrow";
import {ShopHero} from "../../components/Shop/ShopHero";


export const SHOP_SECTIONS = {
	'freebies': {
		title: 'Freebies',
		description: 'Free premium quality vector designs, compatible with Figma, Adobe Illustrator and other vector software.'
	},
	'reconstruction': {
		title: 'Vectorized Art',
		description: 'Bespoke vector reconstructions of the masterpieces from the Metropolitan Art Museum, Art Institute Chicago, Tokyo Museum and other world-class cultural institutions.'
	},
	'pattern': {
		title: 'Patterns',
		description: ''
	},
	undefined: {
		title: 'All visuals',
		description: 'Enjoy our premium quality design materials. All in Vector and PNG formats.'
	}
}

const ShopIndexPage = () => {
	const params = useParams();
	const screenType = useScreenType();
	const navigate = useNavigate();

	const [shopItems, setShopItems] = useState([]);
	const [offset, setOffset] = useState(0);
	const [hasMore, setHasMore] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");

	const scrollY = useScrollPosition(10);


	const [{data, loading}] = useAxios({
		url: '/api/products/',
		headers: getAxiosHeaders(),
		params: params.slug === "freebies" ? {offset: offset, trend: true, limit: 10, query: searchQuery} : {offset: offset, type: params.slug, limit: 10, query: searchQuery},
	}, {useCache: false});

	const fetchNextPage = () => {
		setOffset(shopItems ? shopItems.length : 0)
	}

	useEffect(() => {
		if (data?.results?.length > 0) {
			setHasMore(offset + data.results.length < data.count);
			setShopItems((items) => items ? _.concat(items, data.results) : data.results);
		} // eslint-disable-next-line
	}, [data]);

	useEffect(() => {
		if (hasMore) {
			const bottom = Math.abs(window.innerHeight + scrollY - document.body.scrollHeight) < 20;
			if (bottom) {
				fetchNextPage();
			}
		} // eslint-disable-next-line
	}, [scrollY]);

	const gridRef = useRef(null);

	const handleSearch = (e) => {
		if (e.key === 'Enter') {
			if (searchQuery) {
				navigate(`/shop/catalog?q=${searchQuery}`);
				// navigate(0);
			} else {
				navigate(`/shop/catalog/`);
				// navigate(0);
			}
		}
	}

	useEffect(() => {
		gridRef.current?.updateLayout()
	}, [shopItems])

	let columnWidth = "33.3%";

	return (
		<Container maxWidth={false} disableGutters style={{overflow: 'hidden', maxWidth: '100vw'}}>
			{screenType === 'desktop' ?
				<ShopHero/>
				:
				<Typography className={'Title__page'}>
					<span>Start Your Art<br/>Journey Now</span>
				</Typography>
			}
			<Box className={'Shop__search__board__box'}>
				<ShopSearchBoard searchQuery={searchQuery} setSearchQuery={setSearchQuery} variant={'spaced'} onKeyDown={handleSearch}/>
			</Box>

			<Box className={'Shop__collection__box'}>
				<Box className={'Shop__collection__list'}>
					<Typography className={"Title__collection"}>{SHOP_SECTIONS['freebies'].title}</Typography>
					<Typography className={'Description__collection'}>{SHOP_SECTIONS['freebies'].description}</Typography>
					<ShopItemsList props={{offset: 0, trend: true, limit: 8}}/>

					<Box className={'Button__view__all__box'}>
						<Button className={'Button__view__all'} component={Link} to='/shop/catalog/freebies' endIcon={<ArrowRight stroke={'currentColor'} strokeWidth={1.4} size={20}/>}>
							View all
						</Button>
					</Box>

					<Typography className={'Title__collection'}>{SHOP_SECTIONS['reconstruction'].title}</Typography>
					<Typography className={'Description__collection'}>{SHOP_SECTIONS['reconstruction'].description}</Typography>
					<ShopItemsList props={{offset: 0, type: 'reconstruction', limit: 8}}/>

					<Box className={'Button__view__all__box'}>
						<Button className={'Button__view__all'} component={Link} to='/shop/catalog/reconstruction' endIcon={<ArrowRight stroke={'currentColor'} strokeWidth={1.4} size={20}/>}>
							View all
						</Button>
					</Box>

					<Typography className={'Title__collection'}>{SHOP_SECTIONS['pattern'].title}</Typography>
					{/*<Typography className={'Description__collection'}>{SHOP_SECTIONS['pattern'].description}</Typography>*/}
					<ShopItemsList props={{offset: 0, type: 'pattern', limit: 8}}/>

					<Box className={'Button__view__all__box'}>
						<Button className={'Button__view__all'} component={Link} to='/shop/catalog/pattern' endIcon={<ArrowRight stroke={'currentColor'} strokeWidth={1.4} size={20}/>}>
							View all
						</Button>
					</Box>
				</Box>

				<Box className={'SalesBanner'}>
					<Box className={'SalesBanner__content'}>
						<Box className={'SalesBanner__content__text'}>
							<Box>
								<Box className={'SalesBanner__content__caption__box'}>
									<Star06 stroke={'currentColor'} size={20}/>
									<Typography className={'SalesBanner__content__caption__box__text'}>Try new feature</Typography>
								</Box>
								<Typography className={'SalesBanner__content__title'}>Generate unlimited graphics</Typography>
								<Typography className={'SalesBanner__content__description'}>Choose any image from our shop and press ‘Generate’ to get thousands similar results. You can edit them further, changing color palette and graphic content. All generated results are vector-friendly and fully editable.</Typography>
							</Box>
							<Box component={Link} to='/' className={'Button__generate'}>
								<img src="/static/images/magic_wand.svg" alt="Add to cart" width='18px' height='18px' />
								<Typography className={'Button__generate__text'}>Generate</Typography>
							</Box>
						</Box>
						<img src="/static/images/promo.gif" alt=""  width='314px' height='379px' className={'Sales__banner__image'}/>
					</Box>
				</Box>

				<Typography className={'Subsription__title'}>Subscription options</Typography>

				<SubscriptionCards />
			</Box>
			<Box marginTop='96px' style={{width: '100%'}}>
				<HelpContent />
			</Box>
		{/* {loading ? <Box p={2}><LinearProgress style={{width: '100%'}} color="secondary"/></Box> : null } */}
		</Container>
	);
}

export default ShopIndexPage;