import React from 'react';

export const GeneratorContext = React.createContext({

	state: {
		customerData: false,
		elementsSet: null,
		elements: [],
		colors: [],
		categories: [],
	},
	setState: () => {},
	setCustomerData: () => {},
	setElementsSet: () => {},
	setElements: () => {},
	setColors: () => {},
	setCategories: () => {},

	showElementsSet: () => {},

});

export default GeneratorContext;