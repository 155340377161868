import {Link as RouterLink, useParams} from "react-router-dom";
import React, {useContext, useEffect} from "react";
import useAxios from "axios-hooks";
import {getAxiosHeaders} from "../../api/client";
import {makeStyles} from "@mui/styles";
import {Box, Button, FormControl, FormLabel, IconButton, MenuItem, Select, Typography} from "@mui/material";
import {currencySymbol} from "../../helpers/shop";
import CloseIcon from "@mui/icons-material/Close";

import "./PurchasePage.scss"



const PurchasePage = () => {

	const params = useParams();

	// const classes = useStyles();

	const [{data, loading, error}] = useAxios({
		method: 'GET',
		url: `/api/order/${params.orderId}/`,
		headers: getAxiosHeaders(),
	});

	const handleDownloadClick = () => {
		fetch(`/api/order/${params.orderId}/download/`, {
			method: 'GET',
			headers: {
			  ...getAxiosHeaders(),
			},
		  })
			.then((response) => {
			  if (!response.ok) {
				throw new Error('Network response was not ok');
			  }
			  return response.blob();
			})
			.then((blob) => {
			  const url = window.URL.createObjectURL(new Blob([blob]));
			  const link = document.createElement('a');
			  link.href = url;
			  link.setAttribute('download', 'file.zip');
			  document.body.appendChild(link);
			  link.click();
			  document.body.removeChild(link);
			})
			.catch((error) => {
			  console.error('There has been a problem with your fetch operation:', error);
			});
	}

	if (loading) return <>Loading...</>

	return (
    <>
        <Typography className="TitlePurchase">Thank you for your purchase!</Typography>

		<Box className="PurchaseBox">
            <Box className="PurchaseInfo">

                <Box className="PurchaseInfoMore">
                    <Typography className="PurchaseInfoMore__Title">Order number</Typography>
                    <Typography className="PurchaseInfoMore__Text">{data.number}</Typography>
                </Box>

                <Box className="PurchaseInfoMore">
                    <Typography className="PurchaseInfoMore__Title">Order status</Typography>
                    <Typography className="PurchaseInfoMore__Text">{data.status}</Typography>
                </Box>

                <Box className="PurchaseInfoMore">
                    <Typography className="PurchaseInfoMore__Title">Date</Typography>
                    <Typography className="PurchaseInfoMore__Text">{data.date_placed}</Typography>
                </Box>

                <Box className="PurchaseInfoMore">
                    <Typography className="PurchaseInfoMore__Title">Total price</Typography>
                    <Typography className="PurchaseInfoMore__Text">${data.total_excl_tax}</Typography>
                </Box>
            
            </Box>

            <Box className="PurchaseBoxLine"></Box>

            <Box className="PurchaseBoxDownload">
                <Typography className="PurchaseBoxDownload__Title">Download link</Typography>
                <Typography className="PurchaseBoxDownload__LinkText" onClick={handleDownloadClick} component={RouterLink}>Click here to download your files</Typography>
            </Box>
        </Box>
	</>
    )

}

export default PurchasePage;