import { useState, useEffect } from 'react';

// Code from https://habr.com/ru/post/492248/
export default function useDebounce(value, delay) {
	const [debouncedValue, setDebouncedValue] = useState(value);
	useEffect(
		() => {
			const handler = setTimeout(() => {
				setDebouncedValue(value);
			}, delay || 300);

			return () => {
				clearTimeout(handler);
			};
		},
		[value, delay]
	);
	return debouncedValue;
}