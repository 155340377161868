import React, {useState} from 'react';
import {Route, Routes} from "react-router-dom";
import {GeneratorPage} from "./GeneratorPage";
import {FavoritesPage} from "../favorites/FavoritesPage";

import NotFoundPage from "../../pages/errors/NotFoundPage";
import GeneratorContext from "../../contexts/GeneratorContext";

const _ = require("lodash");

const GeneratorRouter = () => {


	const [generatorState, setGeneratorState] = useState({
		customerData: false,
		elementsSet: null,
		elements: [],
		colors: [],
		categories: [],
	})

	const showElementsSet = () => {
		return !generatorState.customerData && !!generatorState.elementsSet
	}

	const setState = (value) => {
		console.log("setState: ", value)
		setGeneratorState(_.assign({}, generatorState, value))
	}
	const setCustomerData = (value) => {
		console.log("setCustomerData: ", value)
		setGeneratorState(
			_.assign({}, generatorState, {customerData: value})
		)
	}

	const setElementsSet = (value) => {
		console.log("setElementsSet: ", value)
		setGeneratorState(_.assign({}, generatorState, {
			elementsSet: value,
			elements: [],
			colors: []
		}))
	}
	const setElements = (value) => {
		console.log("setElements: ", value)
		setGeneratorState(_.assign ({}, generatorState, {elements: value}))
	}
	const setColors = (value) => {
		console.log("setColors: ", value, _.merge({}, generatorState, {colors: value}))
		setGeneratorState(_.assign({}, generatorState, {colors: value}))
	}
	const setCategories = (value) => {
		console.log("setCategories: ", value)
		setGeneratorState(_.assign ({}, generatorState, {categories: value}))
	}

	const generator = {
		state: generatorState,
		setState,
		setCustomerData, setElementsSet, setElements, setColors, setCategories,
		showElementsSet
	}

	return (
		<GeneratorContext.Provider value={generator}>
			<Routes>
				<Route path="/" element={<GeneratorPage />}/>
				<Route path="/favorites" element={<FavoritesPage />}/>
				<Route path="/*" element={ <NotFoundPage /> }/>
			</Routes>
		</GeneratorContext.Provider>
	);
}

export default GeneratorRouter;