import React from 'react';

export const CartContext = React.createContext({
	cart: false,
	setCart: () => {},
	addToCart: () => {},
	updateCart: () => {},
	refetch: () => {},
});

export default CartContext;