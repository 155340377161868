import { Container, Grid, Typography, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

import {Link, useLocation} from "react-router-dom";
import {useScreenType} from "../../hooks/useScreenType";
import './Footer.scss'

const useStyles = makeStyles((theme) => ({
  board: {
    backgroundColor: 'var(--plurana-neutrals-100)',
    borderRadius: '80px',
    marginTop: '100px',
  },

  text_link: {
    fontSize: '16px',
    color: 'rgba(153, 153, 153, 1)',
    letterSpacing: '2%',
    fontWeight: 350,
    textDecoration: 'none',
    lineHeight: '100%'
  },

  text_footer: {
    fontSize: '16px',
    color: 'var(--plurana-neutrals-900)',
    fontWeight: 350,
    lineHeight: '100%',
    letterSpacing: '-0.32px',
  },
  text_title: {
    fontSize: '16px',
    fontWeight: 600,
    color: 'var(--plurana-neutrals-900)',
    lineHeight: '100%',
    letterSpacing: '-0.32px',
  },
  text_menu: {
    textDecoration: 'none',
    fontSize: '16px',
    fontWeight: 350,
    color: 'var(--plurana-neutrals-900)',
    lineHeight: '100%',
    transition: '0.2s',
    letterSpacing: '-0.32px',

    "&:hover": {
      color: "rgba(26, 26, 25, 0.5)"
    }
  }
}));

export const Footer = () => {
  const classes = useStyles();
  const screenType = useScreenType();
  const {pathname} = useLocation();

  const showMargin = () => {
    return screenType === 'mobile' && ('/shop/cart' === pathname || pathname.includes('/shop/pattern/') || pathname === '/')
  }

  return (
      <Box className={'MainFooter__Wrapper'}>
        <Container maxWidth={false} disableGutters className={classes.board + ' MainFooter'} style={{marginBottom: showMargin() ? 80 : 0}}>
          <Box display={"flex"} justifyContent={"space-between"} alignItems={screenType === 'desktop' ? 'flex-start' : 'center'} flexDirection={screenType === 'desktop' ? 'row' : 'column'}>
            {
              screenType === 'desktop' ?
                  <>
                    <Box display='flex' alignItems='flex-start' justifyContent='space-between' width='765px' pl={7} pt={9.5}>
                      <Grid direction={"column"} display={"flex"}>
                        <Typography className={classes.text_title} variant={classes.text_title}>Product</Typography>
                        <Typography component={Link} to='/' className={classes.text_menu} variant={classes.text_menu} mt={3}>Graphics Generator</Typography>
                        <Typography component={Link} className={classes.text_menu} variant={classes.text_menu} mt={2}>Leave Feedback</Typography>
                        <Typography component={Link} className={classes.text_menu} variant={classes.text_menu} mt={2}>Feature request</Typography>
                      </Grid>
                      <Grid direction={"column"} display={"flex"}>
                        <Typography className={classes.text_title} variant={classes.text_title}>Shop</Typography>
                        <Typography component={Link} to='/shop/catalog/reconstruction' className={classes.text_menu} variant={classes.text_menu} mt={3}>Shop Vectorized Art</Typography>
                        <Typography component={Link} to='/shop/catalog/pattern' className={classes.text_menu} variant={classes.text_menu} mt={2}>Shop Patterns</Typography>
                      </Grid>
                      <Grid direction={"column"} display={"flex"}>
                        <Typography className={classes.text_title} variant={classes.text_title}>About</Typography>
                        <Typography component={Link} to='/pricing' className={classes.text_menu} variant={classes.text_menu} mt={3}>Pricing</Typography>
                        <Typography component={Link} to='/help' className={classes.text_menu} variant={classes.text_menu} mt={2}>Help</Typography>
                        <Typography component={Link} className={classes.text_menu} variant={classes.text_menu} mt={2}>Contact</Typography>
                        <Typography component={Link} to='/terms' className={classes.text_menu} variant={classes.text_menu} mt={2}>Terms & Conditions</Typography>
                        <Typography component={Link} to='/terms/privacy-policy' className={classes.text_menu} variant={classes.text_menu} mt={2}>Privacy Policy</Typography>
                        <Typography component={Link} to='/terms/sharing-policy' className={classes.text_menu} variant={classes.text_menu} mt={2}>Licenses</Typography>
                      </Grid>
                    </Box>
                    <Box sx={{
                      padding: '23px 36px',
                      borderRadius: '26px',
                      background: 'linear-gradient(180deg, #FCDAF4 0%, #F7C5EC 100%)',
                      margin: '76px 56px 0 0',
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                      <Box marginRight='15px'>
                        <Typography sx={{color: '#C33EA5', fontSize: '16px', fontWeight: 600, lineHeight: '100%'}}>UP TO 40% OFF</Typography>
                        <Typography sx={{color: '#C33EA5', fontSize: '16px', fontWeight: 350, lineHeight: '125%', marginTop: '20px'}}>Subscribe and get access to<br />generative tools and shop with<br />great discounts</Typography>
                      </Box>
                      <img src="/static/images/logo-banner.svg" alt="" />
                    </Box>
                  </>
                  :
                  <>
                    <Box display='flex' alignItems='stretch' justifyContent='space-around' p={'27px'} pt={'32px'} width={'100%'} className={'MainFooter__Content'}>
                      <Grid direction={"column"} display={"flex"}>
                        <Typography className={classes.text_title} variant={classes.text_title}>About</Typography>
                        <Typography component={Link} to='/pricing' className={classes.text_menu} variant={classes.text_menu} mt={3}>Pricing</Typography>
                        <Typography component={Link} to='/help' className={classes.text_menu} variant={classes.text_menu} mt={2}>Help</Typography>
                        <Typography component={Link} className={classes.text_menu} variant={classes.text_menu} mt={2}>Contact</Typography>
                        <Typography component={Link} to='/terms/privacy-policy' className={classes.text_menu} variant={classes.text_menu} mt={2}>Privacy Policy</Typography>
                        <Typography component={Link} to='/terms/sharing-policy' className={classes.text_menu} variant={classes.text_menu} mt={2}>Licenses</Typography>
                        <Typography component={Link} to='/terms' className={classes.text_menu} variant={classes.text_menu} mt={2}>Terms & Conditions</Typography>
                      </Grid>

                      <Grid display='flex' flexDirection={'column'}>
                        <Grid direction={"column"} display={"flex"} flexGrow={1}>
                          <Typography className={classes.text_title} variant={classes.text_title}>Shop</Typography>
                          <Typography component={Link} to='/shop/catalog/reconstruction' className={classes.text_menu} variant={classes.text_menu} mt={3}>Shop Vectorized Art</Typography>
                          <Typography component={Link} to='/shop/catalog/pattern' className={classes.text_menu} variant={classes.text_menu} mt={2}>Shop Patterns</Typography>
                        </Grid>

                        <Grid direction={"column"} display={"flex"}>
                          <Typography className={classes.text_title} variant={classes.text_title}>Product</Typography>
                          <Typography component={Link} to='/' className={classes.text_menu} variant={classes.text_menu} mt={3}>Graphics Generator</Typography>
                          <Typography component={Link} className={classes.text_menu} variant={classes.text_menu} mt={2}>Leave Feedback</Typography>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box className={'MainFooter__Banner'} sx={{
                      padding: '23px 36px',
                      marginTop: '20px',
                      borderRadius: '26px',
                      background: 'linear-gradient(180deg, #FCDAF4 0%, #F7C5EC 100%)',
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                      <Box marginRight='15px'>
                        <Typography sx={{color: '#C33EA5', fontSize: '16px', fontWeight: 600, lineHeight: '100%'}}>UP TO 40% OFF</Typography>
                        <Typography sx={{color: '#C33EA5', fontSize: '16px', fontWeight: 350, lineHeight: '125%', marginTop: '20px'}}>Subscribe and get access to<br />generative tools and shop with<br />great discounts</Typography>
                      </Box>
                      <img src="/static/images/logo-banner.svg" alt="" />
                    </Box>
                  </>
            }

          </Box>

          <Box sx={{ marginLeft: screenType === 'desktop' ? '56px' : '10px', marginTop: '62px', marginRight: screenType === 'desktop' ? '56px' : '10px', height: '1px', background: '#CCCCCC'}}></Box>

          <Grid container direction={"row"} justifyContent="space-between" mt={0} mb={0} pt={3} className={'MainFooter__BottomContent'}>
            <Grid direction={"column"} pl={screenType === 'desktop' ? 7 : '10px'} display="flex" justifyContent="center">
              <Grid direction={"column"} display="flex" alignItems="flex-start" justifyContent="flex-start">
                <img src="/static/images/logo-footer.svg" alt="Plurana logo" width="124" height="31" style={{marginLeft: -16}}/>
                <Typography className={classes.text_footer} variant={classes.text_footer} mt={3} mb={2} textAlign="flex-start">Plurana BV</Typography>
                <Typography className={classes.text_footer} variant={classes.text_footer} mb={5} textAlign="center">2023 © All rights reserved</Typography>
              </Grid>
            </Grid>

            <Grid display="flex" gap='20px' alignItems="flex-start" pr={screenType === 'desktop' ? 7 : '10px'} justifyContent="flex-end">
              <a href="https://www.instagram.com/plurana_official/"><img src="/static/images/instagram.svg" alt="Instagram Plurana" width="31" height="31"/></a>
              <a href="https://www.pinterest.com/Plurana/"><img src="/static/images/pinterest.svg" alt="Pinterest Plurana" width="31" height="31" /></a>
            </Grid>
          </Grid>
        </Container>
      </Box>
  );
};