import React from 'react';
import {SvgIcon} from "@mui/material";

export function DeleteIcon(props) {
  return (
      <SvgIcon sx={{...props}} viewBox={'0 0 12 12'}>
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
              <path d="M8.01979 3.1993V2.8153C8.01979 2.27765 8.01979 2.00882 7.91515 1.80347C7.82311 1.62283 7.67625 1.47597 7.49562 1.38393C7.29026 1.2793 7.02144 1.2793 6.48379 1.2793H5.71579C5.17813 1.2793 4.90931 1.2793 4.70395 1.38393C4.52332 1.47597 4.37646 1.62283 4.28442 1.80347C4.17979 2.00882 4.17979 2.27765 4.17979 2.8153V3.1993M5.13979 5.8393V8.2393M7.05979 5.8393V8.2393M1.77979 3.1993H10.4198M9.45979 3.1993V8.5753C9.45979 9.38177 9.45979 9.78501 9.30283 10.093C9.16478 10.364 8.94448 10.5843 8.67353 10.7223C8.3655 10.8793 7.96226 10.8793 7.15579 10.8793H5.04379C4.23731 10.8793 3.83407 10.8793 3.52604 10.7223C3.25509 10.5843 3.03479 10.364 2.89674 10.093C2.73979 9.78501 2.73979 9.38177 2.73979 8.5753V3.1993" stroke="currentColor" stroke-width="0.96" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
      </SvgIcon>
  );
}