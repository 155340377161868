import {Box, Container} from "@mui/material";
import {makeStyles} from "@mui/styles";
import Typography from "@mui/material/Typography";
import {ScrollToTopOnMount} from "../../helpers/navigarions";

const useStyles = makeStyles((theme) => ({
}));

const NotFoundPage = () => {

	const classes = useStyles();

	return <Container maxWidth="lg">
		<ScrollToTopOnMount />
		<Box display="flex" flexDirection="column" justifyContent="center"
				 alignItems="center" className={classes.oops} gap={10}>
			<Box display="flex" flexDirection="column" alignItems="center" gap={2}>
				<Typography variant="h2">
					Privacy Statement
				</Typography>
				<Typography variant="small">
					Last updated: June 28, 2023
				</Typography>

			</Box>

			<Box>
				<Typography variant="h3">Personal Information Collection</Typography>
				<p>
					This Privacy Policy explains how Plurana B.V. collects, uses, and discloses Personal Information of our
					(potential) customers and individuals utilizing our services. It pertains to our websites
					(<a href="https://app.plurana.com" target="_blank">https://app.plurana.com</a> and
					 <a href="https://plurana.com" target="_blank">https://plurana.com</a>), software (Plurana), and
					services provided by us (referred to as the "Services"). It also covers your options regarding the use,
					access, storage, and correction of Personal Information. Additionally, it outlines how we gather, utilize,
					disclose, and process Personal Information obtained through our Services and during our business operations.
				</p>
				<p>By signing up for our Services and agreeing to our General Terms and Conditions, which are necessary for utilizing certain Services, you consent to the collection, usage, storage, and disclosure of information as outlined in this Privacy Policy.</p>
				<p>Our Services may include links to other websites or services. The information practices and content of such external websites or services are governed by their respective privacy policies.</p>
				<p>We may modify this Privacy Policy periodically. In such cases, we will notify you by updating the date at the top of the statement and providing additional notifications (such as adding a statement to our homepage or sending you a notification). We encourage you to review this Privacy Policy whenever you use our Services to stay informed about our information practices and learn how you can protect your privacy.</p>
				<p>We only use your personal information to provide you with the Plurana application or to communicate with you about the services.</p>
				<p>When it comes to any graphics you upload to the Plurana Application, we take the privacy and confidentiality of that information seriously. We employ standard methods to protect against unauthorized access to your stored data, including personal information.</p>
				<p>
					We do not share the personal information you provide without your consent, unless:
				</p>
				<ul>
					<li>It is necessary to fulfill your request.</li>
					<li>We are legally required to enforce our Terms of Service.</li>
					<li>It is necessary to detect, prevent, or address fraud, security, or technical issues.</li>
				</ul>

				<p>It is essential to protect our property, legal rights, or the rights of others.</p>
				<ul>
					<li>As part of our regular business operations, we collect your Personal Information to manage your use of our Services, handle your customer account, and comply with local laws. We will not process Personal Information for any other purposes beyond what is described in this Privacy Statement.</li>
				</ul>
				
			</Box>

			<Box>
				<Typography variant="h3">Personal Information You Provide to Us</Typography>
				<p>While utilizing our Services, we may request certain personally identifiable information from you, which can be used to contact or identify you. For instance, we collect information when you create an account, seek customer support, or communicate with us. The types of information we may gather include basic user details (such as your name, email address, social media avatar, phone number, and photograph), company information, and any other information you choose to provide.</p>
				<p>
					Please note that we do not collect any financial information from you, such as payment card details
					(card number, expiration date, or security code). All payments made to us are processed through a
					third-party service provider, Paddle Ltd (<a href="https://paddle.com" target="_blank" rel="noopener nofollow">https://paddle.com</a>).
					We encourage you to refer to their Privacy Statement at <a href="https://paddle.com/gdpr" target="_blank" rel="noopener nofollow">https://paddle.com/gdpr</a>.
				</p>
			</Box>
			<Box>
				<Typography variant="h3">Automatic Collection of Personal Information When Using our Services</Typography>
				<p>When you use or access our Services, we automatically collect information about you, including:</p>
				<p>
					Log Information: We gather details about your usage of the Services, such as your browser type, device type, access times, usage times, page visits, IP address, and other statistics.
				</p>
				<p>Information Collected by Cookies and Tracking Technologies: We utilize technologies like cookies to collect information and improve our Services. Cookies are small files stored on your device that help us analyze popular features, count visits, and enhance your experience. You can adjust your browser settings to refuse cookies, but this may limit certain functionalities.</p>
				<p>Web Beacons: If you have provided consent, we may collect information using web beacons. These electronic images assist in delivering cookies, counting visits, understanding usage, and measuring the effectiveness of campaigns and notifications. We may utilize third-party services like Google Analytics, Intercom, and Mode to collect, monitor, and analyze these statistics.</p>
				<p>Social Sharing Features: Our Services may include social sharing features allowing you to share Plurana designs  with other platforms. These features may involve the sharing of information based on your established settings. We may collect, store, and use Personal Information from other platforms if you utilize social sharing features. Please refer to the privacy statements of the respective companies providing these features for more details.</p>
				<p>Third-Party Sources: We may obtain Personal Information about you from third-party sources, including LinkedIn, Facebook, Dribbble, Twitter, and publicly accessible sources.</p>
				<p>Support and Service: When you contact us for support or customer service, we may retain records related to your requests, including the information you provide.</p>
				<p>Access to Your Content: In compliance with applicable laws, we may access and view your Content (as defined in our Terms of Service) in limited ways. This may be necessary to address support requests, detect fraud or security issues, and enforce the Terms. Our automated systems may analyze your Content using techniques like machine learning to improve our Services and enhance user experiences.</p>
				<p>Marketing Communications: If you have subscribed to our newsletter, we may use your Personal Information to send you marketing or promotional materials related to Plurana. You can unsubscribe at any time using the provided unsubscribe button or by contacting <a href="mailto:support@plurana.com">support@plurana.com</a>. Your request will be promptly processed, typically within two business days.</p>
			</Box>

			<Box>
				<Typography variant="h3">Personal information we use</Typography>
				<p>We use your Personal Information based on the following legal justifications:</p>
				<ul>
					<li>The processing is necessary to fulfill an agreement with you or to take steps at your request before entering into an agreement.</li>
					<li>The processing is necessary for us to comply with applicable legal obligations.</li>
					<li>The processing is in our legitimate interest, and this interest outweighs your privacy rights, or you have provided consent for the processing.</li>
				</ul>
				<p>We collect your Personal Information to:</p>
				<ul>
					<li>Fulfill our obligations under our agreements with you and other parties.</li>
					<li>Process, evaluate, and complete specific transactions related to the Services.</li>
					<li>Operate, assess, maintain, improve, customize, and develop the Services. This includes analyzing trends, monitoring access and usage of the Services, enhancing customer experience, ensuring the security of our Services, and conducting advertising and marketing activities.</li>
					<li>Provide you with requested documentation, communications, or other services.</li>
					<li>Communicate with you to address inquiries or complaints.</li>
					<li>Protect and safeguard our Intellectual Property Rights.</li>
					<li>Manage, prevent, and investigate fraud, risks, claims, and other liabilities. This includes enforcing our contract terms, complying with laws and regulations, and maintaining overall legal compliance.</li>
				</ul>
			</Box>

			<Box>
				<Typography variant="h3">Personal Information We Disclose</Typography>
				<p>We operate globally and may share your Personal Information with our affiliated businesses as part of our business operations, administration of the Services, and to comply with local laws and regulations. We may also engage third-party service providers, who will operate under our instructions, to assist us in providing information, products, or services, managing our business, or improving our Services. Personal data may be shared with these affiliates and third parties to perform services on our behalf, subject to appropriate contractual restrictions and security measures. We may also disclose Personal Information if we believe it is necessary to prevent harm or loss, or to further an investigation of suspected or actual illegal activities.</p>
				<p>We reserve the right to share information that is not considered Personal Information or is not subject to contractual restrictions.</p>
				<p>If Personal Information is transferred outside the European Economic Area to our affiliated companies or third-party service providers, we take steps to ensure the same level of protection as within the European Economic Area. This includes entering into data transfer agreements using the European Commission approved Standard Contractual Clauses. We contractually require agents, service providers, and affiliates processing Personal Information to provide the same level of protection as required by the GDPR.</p>
				<p>We prioritize the privacy and protection of personal data and are dedicated to meeting the obligations set forth by applicable data protection laws.</p>
				<p>We may share Personal Information with third parties in connection with the potential or actual sale of our company or any affiliated company. Personal Information may be one of the transferred assets.</p>
				<p>In accordance with our legal obligations, we may also process Personal Information, as required by law, for law enforcement, national security, or other regulatory purposes.</p>
			</Box>

			<Box>
				<Typography variant="h3">Security</Typography>
				<p>We prioritize the security of your Personal Information and take measures to safeguard and protect it from unauthorized access, misuse, disclosure, modification, or loss. We employ reasonable processes, systems, and technologies to ensure the security of your Personal Information. Our personnel are only granted access to Personal Information if it is necessary for their work-related tasks, to fulfill your requests, or to comply with legal obligations on our behalf.</p>
				<p>It is important to note that no method of transmission over the internet or electronic storage is 100% secure or error-free. While we make commercially acceptable efforts to protect your Personal Information, we cannot guarantee absolute security. We cannot be held responsible for unauthorized or unintended access that is beyond our control.</p>
				<p>In cases where Personal Information is transferred outside the European Economic Area to our affiliated companies or third-party service providers, we take steps to ensure that your Personal Information receives the same level of protection as if it remained within the European Economic Area. This includes entering into data transfer agreements that incorporate the European Commission approved Standard Contractual Clauses. We require our agents, service providers, and affiliates who may process Personal Information related to the Services to provide the same level of protection as mandated by the GDPR.</p>
				<p>In the event of a security breach that may result in the accidental or unlawful destruction, loss, alteration, unauthorized disclosure, or access to your Personal Information, we will promptly and, where feasible, within 72 hours of becoming aware of the breach, notify the local supervisory authority. If the breach is unlikely to pose a risk to the rights and freedoms of individuals, we may not be required to notify the supervisory authority. However, if the breach is likely to present a high risk to individuals' rights and freedoms, we will notify the supervisory authority and communicate the breach on our security page without undue delay. This requirement may be waived if we have already implemented appropriate technical and organizational measures to protect the affected Personal Information, such as encryption, or if subsequent measures have been taken to mitigate the risk to individuals' rights and freedoms.</p>
			</Box>

			<Box>
				<Typography variant="h3">Children</Typography>
				<p>Our Services are intended for users who are at least 16 years old. We do not knowingly collect, store, share, or use Personal Information of individuals under the age of 16. If you are under 16 years old, please refrain from providing any Personal Information, even if prompted to do so by the Services. If you are under 16 years old and have inadvertently provided Personal Information, please ask your parent(s) or guardian(s) to contact us, and we will promptly delete such information from our records.</p>
			</Box>

			<Box>
				<Typography variant="h3">Your EU Rights</Typography>
				<p>Under the General Data Protection Regulation (GDPR), you may have certain rights regarding your Personal Information. These rights include:</p>
				<ul>
					<li>The right to obtain a copy of your Personal Information and information about how it is processed.</li>
					<li>The right to rectify any inaccurate or incomplete Personal Information.</li>
					<li>The right to request the deletion of your Personal Information when it is no longer necessary for the purposes it was collected or processed. We aim to anonymize your Personal Information within 30 days upon receiving your deletion request.</li>
					<li>The right to restrict the processing of your Personal Information under certain circumstances.</li>
					<li>The right to receive your Personal Information in a machine-readable format or have it transmitted to a third party (or to you) when the processing is based on your consent or the performance of a contract.</li>
					<li>The right to withdraw your consent to the processing of your Personal Information (where applicable).</li>
					<li>The right to obtain information about the appropriate safeguards used for the transfer of your Personal Information to a third country or international organization outside the European Economic Area.</li>
					<li>The right to lodge a complaint with your local supervisory authority for data protection.</li>
				</ul>
				<p>In addition to these rights, you have the right to object to the processing of your Personal Information based on legitimate interests, including profiling, as well as for marketing or promotional purposes.</p>
				<p>If you have any questions about this Privacy Statement or wish to exercise any of your rights, please contact us at <a href="mailto:support@plurana.com">support@plurana.com</a>. Please note that we may require proof of identity and, in certain cases, may charge a fee as permitted by law, especially if your request is unfounded or excessive. We will make every effort to respond to your request within the applicable timeframes specified by law.</p>
			</Box>


		</Box>

	</Container>
}

export default NotFoundPage;