import React, {useContext, useEffect, useState} from 'react';
import {Box, Button, Container, FormControl, FormLabel, Typography} from "@mui/material";
import CryptocurrencyIcon from '../../icons/CryptocurrencyIcon';
import {currencySymbol, getPrice, getPricesInCurrency} from "../../helpers/shop";
import _ from "lodash";
import AuthContext from "../../contexts/AuthContext";
import Radio from '@mui/material/Radio';
import { HeartRounded, Building08 } from "untitledui-js/icons/general";
import { MagicWand01 } from "untitledui-js/icons/editor";
import useAxios from "axios-hooks";
import {getAxiosHeaders} from "../../api/client";
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import CartContext from "../../contexts/CartContext";
import * as Sentry from "@sentry/react";
import {useSnackbar} from "notistack";

import "./ReconstructionPreview.scss";
import {Link, useNavigate} from 'react-router-dom';
import {useScreenType} from "../../hooks/useScreenType";
import {BreadCrumbs} from "../Shop/BreadCrumbs";
import RelatedList from '../Related/RelatedList';
import ParentReconstruction from './ParentReconstruction';

const amplitude = window.amplitude;

const ReconstructionOriginalBox = ({reconstruction}) => {
  const screenType = useScreenType();
  return (
      <Box className={'Original__box'}>
        <Box className={'Original__box__header'}>
          <Building08 size="16px" stroke='#999999'/>
          <Typography className={'Original__box__header__text'}>Historical origin of this artwork:</Typography>
        </Box>
        <Box className={'Original__box__info'}>
          <Box className={'Original__box__info__content'}>
            <img className={'Original__image__reconstruction'} src={reconstruction.original_image} alt={reconstruction.title}/>
          </Box>

          <Box className='Original__box__more__info'>
            <Box>
              <Typography className={'Original__meta__title'}>Dated around</Typography>
              <Typography className={'Original__meta__text'}>{reconstruction.period_text}</Typography>
              <Box className={'Original__meta__separator'}></Box>
            </Box>
            <Box>
              <Typography className={'Original__meta__title'}>Geography</Typography>
              <Typography className={'Original__meta__text'}>{reconstruction.geography_text}</Typography>
              <Box className={'Original__meta__separator'}></Box>
            </Box>
            <Box>
              <Typography className={'Original__meta__title'}>Museum</Typography>
              <Typography className={'Original__meta__text'}>{reconstruction.museum}</Typography>
              {screenType === 'desktop' && <Box className={'Original__meta__separator'}></Box>}
            </Box>
            <Box>
              <Typography className={'Original__meta__title'}>Medium</Typography>
              <Typography className={'Original__meta__text'}>{reconstruction.medium}</Typography>
            </Box>
          </Box>
        </Box>
          <Box className={'Original__title'}>
              <Typography className={'Original__title__reconstruction'}>{reconstruction.original_title}</Typography>
          </Box>
        <Box className={'Original__description'}>
          <Typography className={'Original__description__reconstruction'}>{reconstruction.original_description}</Typography>
        </Box>
      </Box>
  )
}

export const ReconstructionPreview = (props) => {
  const {reconstruction} = props;

  const pricesInCurrency = getPricesInCurrency(reconstruction, "USD");
  const [currentStock, setCurrentStock] = useState(pricesInCurrency[0].id);
  const [currentPrice, setCurrentPrice] = useState(null);
  const [isSaved, setSaved] = useState(reconstruction.favorite);

  const screenType = useScreenType();

  const auth = useContext(AuthContext);
  const cart = useContext(CartContext);
  const [addedCartExt, setAddedCartExt] = useState(false);
  const [addedCartSt, setAddedCartSt] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  // eslint-disable-next-line 
  const [likeResponse, doToggleLike] = useAxios({
		method: 'POST',
		url: '/api/saved/add-product/',
		headers: getAxiosHeaders(),
	}, {manual: true, autoCancel: false});

  useEffect(() => {
    cart?.cart?.lines.forEach((elem) => {
      if (elem?.product?.id === reconstruction.id) {
        if (elem?.stockrecord?.title === 'Extended License') {
          setAddedCartExt(true);
        } else {
          setAddedCartSt(true);
        }
      } 
    }) // eslint-disable-next-line
  }, [cart?.cart])

  useEffect(() => {
    const price = getPrice(reconstruction, "USD", currentStock)
    setCurrentPrice(price) // eslint-disable-next-line
  }, [currentStock])

  const handleChangeStockRecord = (event) => {
    setCurrentStock(parseInt(event.target.value));
  };

  const handleItemActionError = (error, item_id, action) => {
    if (error.response?.data?.error) {
      enqueueSnackbar(error.response.data.error, {autoHideDuration: 3000, variant: "error"})
    }
    Sentry.captureMessage(`Failed to ${action} shop item ${item_id}`);
    Sentry.captureException(error);
  }

  const handleAddToCart = async () => {
    (currentPrice?.title === 'Extended License') ? setAddedCartExt(true) : setAddedCartSt(true);

    try {
      await cart.doAddToCart({data: {url: reconstruction.id, quantity: 1, stockrecord: currentStock}});
      amplitude?.track('front.shop.to-cart', {item_id: reconstruction.id});
      enqueueSnackbar("The product is added to the cart! ✨", {autoHideDuration: 1500, variant: "success", action: () => (
            <div onClick={() => navigate('/shop/cart')} className={'Snackbar__Link'}>View cart</div>
        )})
      cart.refetch();
    } catch (e) {
      console.log("oops", e)
      handleItemActionError(e, reconstruction.id, "add to cart");
    }
  }

  const handleClickSaved = async () => {
    if (!auth.user?.id) return auth.signUpForm();      
    try {
      await doToggleLike({data: {product_id: reconstruction.id, user_id: auth.user?.id}});
      if (isSaved) {
        setSaved(false)
        enqueueSnackbar("The product is removed from saved! ✨", {autoHideDuration: 1500, variant: "success", action: () => (
              <div onClick={() => navigate('/favorites')} className={'Snackbar__Link'}>View saved</div>
          )})
      } else {
        setSaved(true)
        enqueueSnackbar("The product is added to the saved! ✨", {autoHideDuration: 1500, variant: "success", action: () => (
              <div onClick={() => navigate('/favorites')} className={'Snackbar__Link'}>View saved</div>
          )})
      }
    } catch (e) {
      console.log("oops", e)
      handleItemActionError(e, reconstruction.id, "add to saved");
    }
  }

  const handleDownloadClick = () => {
		fetch(`/api/freebies/${reconstruction.id}/download/`, {
			method: 'GET',
			headers: {
			  ...getAxiosHeaders(),
			},
		  })
			.then((response) => {
			  if (!response.ok) {
				throw new Error('Network response was not ok');
			  }
			  return response.blob();
			})
			.then((blob) => {
			  const url = window.URL.createObjectURL(new Blob([blob]));
			  const link = document.createElement('a');
			  link.href = url;
			  link.setAttribute('download', 'file.zip');
			  document.body.appendChild(link);
			  link.click();
			  document.body.removeChild(link);
			})
			.catch((error) => {
			  console.error('There has been a problem with your fetch operation:', error);
			});
	}

	const getFreebies = () => {
		auth.user ? handleDownloadClick() : auth.signInForm();
	}
  
  let breadCrumbsList = [['/shop', 'Shop']];
  if (reconstruction.type !== "pattern") {
    breadCrumbsList.push(['/shop/catalog/reconstruction', 'Vectorized artworks'])
  } else {
    breadCrumbsList.push(['/shop/catalog/pattern', 'Patterns'])
  }

  return (
    <Box className={'ReconstructionPreview--wrapper'}>
        <Box className={'ReconstructionPreview'}>
            <Box className={'Reconstruction__preview'}>
                <Box className={'Info__box'}>
                    {screenType === 'mobile' &&
                        <>
                            <Box className={'MobileDownloadIcons'}>
                                <img src="/static/images/file_svg.svg" alt="Plurana logo" />
                                <img src="/static/images/file_png.svg" alt="Plurana logo" />
                            </Box>
                        </>
                    }
                    <BreadCrumbs items={breadCrumbsList}/>
                    <Box className={'Title__reconstruction'}>
                        <Box className={'ReconstructionTitle'}>{reconstruction.title}</Box>
                        {screenType === 'mobile' &&
                            <Box className={'Button__save'} component={Link} onClick={handleClickSaved}>
                                <HeartRounded size={'18px'} fill={isSaved ? '#1A1A19' : 'none'} style={{strokeWidth: 2.5}} />
                            </Box>
                        }
                    </Box>

                    <Box className={'Description__reconstruction'}>
                        <Typography className='Description__reconstruction__text' >{reconstruction.description}</Typography>
                        {screenType === 'desktop' &&
                            <Box className={'Button__save'} component={Link} onClick={handleClickSaved}>
                                <HeartRounded size={"16px"} fill={isSaved ? '#1A1A19' : 'none'} style={{strokeWidth: 2.5}} />
                                <Typography className={'Text__save'}>Save</Typography>
                            </Box>
                        }
                    </Box>

                    <Box>
                        {_.map(reconstruction.images, (img) =>
                            <img
                                key={img.original}
                                className={'Product__image'}
                                src={img.original}
                                alt={reconstruction.title}
                                loading="lazy"
                            />)}
                    </Box>

                    {
                    (reconstruction.original_title !== "" && reconstruction.original_title !== null && screenType === 'desktop') 
                    || (reconstruction.original_description !== "" && reconstruction.original_description !== null && screenType === 'desktop')
                    ? 
                    <ReconstructionOriginalBox reconstruction={reconstruction}/> 
                    : null
                    }
                </Box>

                {
                (reconstruction.original_title !== "" && reconstruction.original_title !== null && screenType === 'mobile') 
                || (reconstruction.original_description !== "" && reconstruction.original_description !== null && screenType === 'mobile')
                ?
                <ReconstructionOriginalBox reconstruction={reconstruction}/>
                : null
                }

                <Box className={'Buy__box'}>
                    {screenType === 'desktop' &&
                        <Box className={'Buy__info__content'}>
                            <ul>
                                <li style={{listStyleType: 'none', paddingBottom: '24px'}}>
                                    <Box className={'Buy__info__content__icons'}>
                                        <img src="/static/images/file_svg.svg" alt="Plurana logo" />
                                        <img src="/static/images/file_png.svg" alt="Plurana logo" />
                                    </Box>
                                </li>
                                <li>High resolution</li>
                                <li>Extremely detailed vector file</li>
                                <li>Unique vector elements</li>
                            </ul>
                        </Box>
                    }

                    <Box className={'Buy__generate'}>
                        {_.map(reconstruction.images, (img) =>
                            <img
                                className={'Buy__generate__image'}
                                key={img.original}
                                src={img.original}
                                alt={reconstruction.title}
                                loading="lazy"
                            />)}
                        <Box className={'Buy__generate__title'}>Get 1000+ patterns and layouts based on this style.</Box>
                        <Button className={'Buy__generate__button'} component={Link} to={`/?element_set=${reconstruction.element_set}`}>Generate for free</Button>
                    </Box>

                    {reconstruction.trend ? 
                      <Box className={'Buy__add__cart'} id={'buy'}>
                        <Button className='Download__add__cart__button' onClick={getFreebies}>
                          Download for free
                        </Button>
                      </Box>
                      :
                      <Box className={'Buy__add__cart'} id={'buy'}>
                        <FormControl>
                            <FormLabel className={'Buy__add__cart__title'}>Product License</FormLabel>
                            <RadioGroup name="product-license-switch" onChange={handleChangeStockRecord} defaultValue={reconstruction.stockrecords[0].id}>
                                {_.map(pricesInCurrency, (stock) => {
                                    return <FormControlLabel className={'Buy__add__cart__price'} key={stock.id} value={stock.id} control={<Radio style={{padding: 0}} />} label={
                                        <Box className={'Buy__add__cart__switch__item'}>
                                            <Box className={'Buy__add__cart__switch__item__title'}>{stock.title}</Box>
                                            <Box className={'Buy__add__cart__switch__item__price'}>{currencySymbol(stock.price_currency)}{parseFloat(stock.price)}</Box>
                                        </Box>
                                    } />
                                })}
                            </RadioGroup>
                        </FormControl>
                        <Typography className='Buy__add__cart__description'>The standard VAT rate may be charged, following the law of your country</Typography>

                        {
                            (currentPrice?.title === "Extended License" && !addedCartExt) || (currentPrice?.title === "Standart License" && !addedCartSt) ?
                                <Button className='Buy__add__cart__button' onClick={handleAddToCart}>
                                    Buy for {currencySymbol(currentPrice?.price_currency)}{parseFloat(currentPrice?.price)}
                                </Button>
                                :
                                <Button className={'Buy__add__cart__button'} component={Link} to='/shop/cart'>
                                    View cart
                                </Button>
                        }

                        <Button className={'Buy__add__cart__button__sub'} startIcon={<CryptocurrencyIcon />}>
                            Buy for $170 with PRO
                        </Button>
                    </Box>}
                </Box>

                {/*{reconstruction.used_in && reconstruction.used_in.length > 0 && reconstruction.used_in.map((collection) => (*/}
                {/*  <CollectionPreview*/}
                {/*    key={collection.id}*/}
                {/*    collection={collection}*/}
                {/*    compact*/}
                {/*  />))}*/}
            </Box>

            {screenType === 'mobile' &&
                <Box className={'ScrollToBuy'}>
                    <Button onClick={() => {
                        const elem = document.getElementById("buy");
                        const y = elem.getBoundingClientRect().top + window.scrollY - 160;
                        window.scrollTo({top: y, behavior: 'smooth'});
                    }} fullWidth>
                        Buy now
                    </Button>
                </Box>
            }
            {reconstruction.element_set !== null && reconstruction.type !== "reconstruction" ? <ParentReconstruction setId={reconstruction.element_set} /> : null}
            <RelatedList setId={reconstruction.element_set} />

            <Box
                style={{
                    display: "flex",
                    // padding: "40px 392px",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "40px",
                    height: "130px",
                    width: "100%",
                    marginTop: '24px',
                    // margin: "32px 170px 0 170px",
                    background: "var(--Pale-Pink-Gradient, linear-gradient(180deg, #FFF5FD 0%, #FFDDF7 100%))"
                }}
            >
                <Button
                    component={Link} to={`/?element_set=${reconstruction.element_set}`}
                    className={'ItemPage__GenerateMoreButton'}
                    startIcon={<MagicWand01 size='18px' stroke={'#FFFFFF'}/>}
                    style={{
                        maxWidth: '555px',
                        width: '100%',
                        margin: "0 16px",
                        backgroundColor: 'var(--plurana-shades-500)',
                        color: '#FFFFFF',
                        fontSize: 14,
                        fontWeight: 600,
                        lineHeight: "100%",
                        boxShadow: "0px 6px 14px 0px rgba(255, 43, 209, 0.60)",
                        borderRadius: "52px",
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >Generate more like this with Plurana</Button>
            </Box>
        </Box>
    </Box>
  );
};