import React from "react";
import {createBrowserRouter} from "react-router-dom";
import ShopRouter from "./pages/shop/router";
import GeneratorRouter from "./pages/generator/router";
import SubscriptionPage from "./pages/subscription/SubscriptionPage";
import PluranaApp from "./App";
import TermsPage from "./pages/static/TermsPage";
import SharingPage from "./pages/static/SharingPage";
import PrivacyPage from "./pages/static/PrivacyPage";
import { HelpPage } from "./pages/help/HelpPage";

const pluranaRouter = createBrowserRouter([
  {
    element: <PluranaApp/>,
    children: [ {
      path: "/shop/*", element: <ShopRouter />
    }, {
      path: "/pricing", element: <SubscriptionPage/>
    }, {
      path: "/terms", element: <TermsPage />
    }, {
      path: "/terms/sharing-policy", element:  <SharingPage/>
    }, {
      path: "/terms/privacy-policy", element:  <PrivacyPage/>
    }, {
      path: "/*", element: <GeneratorRouter/>
    }, {
      path: "/help", element: <HelpPage/>
    }]
  }

]);

export default pluranaRouter;
