import {Box, useMediaQuery, useTheme} from "@mui/material";

import Typography from "@mui/material/Typography";
import React, {useEffect, useRef} from "react";
import GenerativeItem from "./GenerativeItem";
import StackGrid, { transitions } from "react-stack-grid";
import './GenerativeList.scss'
import {GENERATION_LIMIT} from "../../pages/generator/GeneratorPage";
const _ = require("lodash");

const { helix } = transitions;

const GenerativeList = ({items=[], preview, canDimDisliked = false, showHeader = true}) => {

	const gridRef = useRef(null);
	const theme = useTheme();

	const tablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
	const mobile = useMediaQuery(theme.breakpoints.down("sm"));
	const desktop = useMediaQuery('(min-width: 1440px)');

	let columnWidth = "33.3%";
	let gutterWidth = 30;

	if (tablet || mobile) {
		columnWidth = "50%";
		gutterWidth = 10
	}

	if (desktop) {
		columnWidth = "25%";
		gutterWidth = 24
	}

	useEffect(() => {
		window.onresize = (e) => {
			setTimeout(() => gridRef.current?.updateLayout(), 100)
		};
		return () => {
			window.onresize = () => {}
		}
	}, [])

	useEffect(() => {
		console.log(gridRef)
		if (items && items.length) {
			setTimeout(() => gridRef.current?.updateLayout(), 200)
		}
	}, [items])


	return (
		<>
			{(preview && showHeader) ? <Box my={2}>
				<Typography variant="titledCaption">Here are some random generative results:</Typography>
			</Box> : null}
			{items.length ?
				<Box my={'20px'}>
					<StackGrid
						className={'GenerativeListGrid'}
						gridRef={grid => gridRef.current = grid}
						columnWidth={columnWidth}
						itemComponent="div"
						appearDelay={0}
						duration={0}
						monitorImagesLoaded
						gutterWidth={gutterWidth}
						gutterHeight={20}
					>
						{_.map(items,
							(item, n) =>
								<GenerativeItem
									key={item.id}
									data={item}
									preview={preview}
									canDimDisliked={canDimDisliked}/>
						)}
						{items.length < GENERATION_LIMIT &&
							Array.from(Array(GENERATION_LIMIT - items.length), (e, i) => (
								<GenerativeItem skeleton key={i} data={{id: i}}/>
							))
						}
					</StackGrid>
				</Box>
			: null
			}
		</>
	)
}

export default GenerativeList;
