import './PluranaButton.scss';
import {Button} from "@mui/material";
import {Link} from "react-router-dom";

export const PluranaButton = ({
    children,
    variant='default',
    size='medium',
    showBorder,
    className,
    href,
    ...restProps
}) => {
    return (
        <Button
            component={href ? Link : undefined}
            to={href || undefined}
            className={
                'PluranaButton PluranaButton-' + variant +
                ' PluranaButton-' + size +
                (showBorder ? ' PluranaButton-withBorder' : '') +
                (className ? ' ' + className : '')
            }
            {...restProps}
        >
            {children}
        </Button>
    )
}