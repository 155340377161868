import React from 'react';
import {SvgIcon} from "@mui/material";

export function UndoIcon(props) {
  return (
      <SvgIcon sx={{...props}}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={'none'} stroke={'currentColor'}>
              <path d="M3 9H16.5C18.9853 9 21 11.0147 21 13.5C21 15.9853 18.9853 18 16.5 18H12M3 9L7 5M3 9L7 13" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
      </SvgIcon>
  );
}