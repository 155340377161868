import React from "react";
import {Box, Button} from "@mui/material";
import {ArrowRight} from "untitledui-js/icons/arrow";
import './StylePreviewItem.scss';
import PopupState, {bindHover, bindPopover} from "material-ui-popup-state";
import {DotsGrid} from "untitledui-js/icons/general";
import HoverPopover from "material-ui-popup-state/HoverPopover";
import {Link as RouterLink} from "react-router-dom";

export const StylePreviewItem = ({
    onClick = () => {},
    selected,
    preview,
    title,
    set,
    isCustomStyle,
    isPlaceholder,
    titleOnTop,
    displayTitle=true,
    size='s',
}) => {
    const fullSize = size === 'l';
    const mediumSize = size === 'm';
    const stylePreview = preview || (set?.elements.length ? set.elements[0]['png'] : '');
    const wrapperStyle = (!preview && set?.colors.length) ? {backgroundColor: set.colors[0]} : {}

    if (displayTitle) {
        return (
            <Box className={'StylePreviewItem' + (selected ? ' StylePreviewItem--selected' : '') + (fullSize ? ' StylePreviewItem--full_size' : '') + (titleOnTop ? ' StylePreviewItem--TitleOnTop' : '') + (mediumSize ? ' StylePreviewItem--medium_size' : '')} onClick={onClick}>
                <Box className={'StylePreviewItem__Image-wrapper' + (isCustomStyle ? ' StylePreviewItem__Image-wrapper--custom_style' : '') + (isPlaceholder ? ' StylePreviewItem__Image-wrapper--placeholder' : '')} style={wrapperStyle}>
                    { !isPlaceholder ?
                        <img src={stylePreview} alt={''} className={'StylePreviewItem__Image' + (!preview ? ' StylePreviewItem__Image--generated' : '')}/>
                        :
                        <div className={'StylePreviewItem__Image'}>
                            <ArrowRight stroke={'currentColor'}/>
                        </div>
                    }
                    { !isPlaceholder && <Box className={'StylePreviewItem__Image-border'}/>}
                </Box>
                <Box className={'StylePreviewItem__Title'}>
                    {title}
                </Box>
            </Box>
        )
    } else {
        return (
            <PopupState variant="popover" popupId={"stylepreview-popup"}>
                {(popupState) => (
                    <>
                        <Box className={'StylePreviewItem' + (selected ? ' StylePreviewItem--selected' : '') + (fullSize ? ' StylePreviewItem--full_size' : '') + (titleOnTop ? ' StylePreviewItem--TitleOnTop' : '') + (mediumSize ? ' StylePreviewItem--medium_size' : '')} onClick={onClick} {...bindHover(popupState)}>
                            <Box className={'StylePreviewItem__Image-wrapper' + (isCustomStyle ? ' StylePreviewItem__Image-wrapper--custom_style' : '') + (isPlaceholder ? ' StylePreviewItem__Image-wrapper--placeholder' : '')} style={wrapperStyle}>
                                { !isPlaceholder ?
                                    <img src={stylePreview} alt={''} className={'StylePreviewItem__Image' + (!preview ? ' StylePreviewItem__Image--generated' : '')}/>
                                    :
                                    <div className={'StylePreviewItem__Image'}>
                                        <ArrowRight stroke={'currentColor'}/>
                                    </div>
                                }
                                { !isPlaceholder && <Box className={'StylePreviewItem__Image-border'}/>}
                            </Box>
                        </Box>
                        <HoverPopover
                            transitionDuration={200}
                            {...bindPopover(popupState)}
                            className={'StylePreviewItem--popper'}
                            anchorOrigin={{
                                vertical: 92,
                                horizontal: "center"
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "center"
                            }}
                        >
                            <Box className={'StylePreviewItem__TitlePopup'}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="9" viewBox="0 0 17 9" fill="none" className={'StylePreviewItem__TitlePopup--arrow'}>
                                    <path d="M2.42894 8.51471C1.53803 8.51471 1.09187 7.43757 1.72183 6.8076L7.79289 0.736529C8.18342 0.346004 8.81658 0.346005 9.20711 0.736529L15.2782 6.8076C15.9081 7.43757 15.462 8.51471 14.5711 8.51471L2.42894 8.51471Z" fill="#101828"/>
                                </svg>
                                {title}
                            </Box>
                        </HoverPopover>
                    </>
                )}
            </PopupState>
        )
    }
}