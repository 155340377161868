import {Box, Typography, Button, IconButton} from "@mui/material";
import './DragAndDropInput.scss';
import {GalleryPlusIcon, RandomIcon, SquarePlusIcon} from "../../icons";
import {useEffect, useRef, useState} from "react";

export const DragAndDropInput = ({
    dragActive,
    handleFilesAttach
}) => {

    return (
        <Box className={'DragAndDropInput'}>
            <label className={"DragAndDropInput__Input" + (dragActive ? ' DragAndDropInput__Input-active' : '')}>
                <input type="file" multiple="multiple" onChange={(e) => handleFilesAttach(e.target.files)} accept={'.png,.svg,.jpg,.jpeg'}/>

                <div className={'DragAndDropInput__Input__Wrapper'}>
                    <GalleryPlusIcon width={32} height={32}/>

                    <Typography className={'DragAndDropInput__Label'}>
                        Drag and drop your files or <span className={'DragAndDropInput__Label-primary'}>upload from computer</span>
                    </Typography>

                    <Box className={'DragAndDropInput__Tags'}>
                        SVG, PNG or JPG (max. 1000px width)
                    </Box>
                </div>
            </label>
        </Box>
    )
}