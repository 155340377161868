import React from 'react';
import {SvgIcon} from "@mui/material";

function CryptocurrencyIcon(props) {
  return (
    <SvgIcon width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M2.22217 2.9502L4.22217 4.9502M22.2222 2.9502L20.2222 4.9502M22.2222 22.9502L20.2222 20.9502M2.22217 22.9502L4.22217 20.9502M2.22217 16.9502H3.72217M8.22217 2.9502V4.4502M22.2222 8.9502H20.7222M16.2222 22.9502V21.4502M18.2222 16.9502H21.7222M16.2222 2.9502V6.9502M2.22217 8.9502H6.22217M8.22217 22.9502V18.9502M11.6565 8.51588L7.78785 12.3845C7.58985 12.5825 7.49084 12.6815 7.45375 12.7957C7.42112 12.8961 7.42112 13.0043 7.45375 13.1047C7.49084 13.2189 7.58984 13.3179 7.78785 13.5159L11.6565 17.3845C11.8545 17.5825 11.9535 17.6815 12.0677 17.7186C12.1681 17.7512 12.2763 17.7512 12.3767 17.7186C12.4908 17.6815 12.5898 17.5825 12.7879 17.3845L16.6565 13.5159C16.8545 13.3179 16.9535 13.2189 16.9906 13.1047C17.0232 13.0043 17.0232 12.8961 16.9906 12.7957C16.9535 12.6815 16.8545 12.5825 16.6565 12.3845L12.7879 8.51588C12.5898 8.31787 12.4908 8.21887 12.3767 8.18177C12.2763 8.14914 12.1681 8.14914 12.0677 8.18177C11.9535 8.21887 11.8545 8.31787 11.6565 8.51588Z" fill='none' stroke="#227541" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </SvgIcon>
  );
}

export default CryptocurrencyIcon;