import {Box, Button, IconButton, Popover} from "@mui/material";
import {DeleteIcon, PlusIcon} from "../../icons";
import {ColorPicker} from "./ColorPicker";
import './ColorsList.scss';
import React, {useState} from "react";

function getBorderColor(hex) {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, 0.5)` : null
}

export const GeneratorColorsList = ({
    colors,
    setColors,
    addItemToHistory,
    addColor,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const toggleItem = (item) => {
        setColors(colors.map(data => {
            if (data.id === item.id) {
                return {...item, selected: !item.selected}
            }
            return data
        }))
    }
    const removeItem = (item) => {
        setColors([...colors.filter(data => data.id !== item.id)])
        addItemToHistory({
            type: 'delete',
            target: 'color',
            undo: () => setColors(colors),
            redo: () => setColors([...colors.filter(data => data.id !== item.id)])
        })
    }

    return (
        <Box className={'GeneratorColorsList'}>
            <Box className={'GeneratorColorsList__Item'}>
                <IconButton className={'GeneratorColorsList__Item__Tappable'} onClick={e => setAnchorEl(e.currentTarget)}>
                    <PlusIcon width={'16px'} height={'16px'}/>
                </IconButton>
                <ColorPicker anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)} onAddColor={addColor} initialColor={'#FFFFFF'}/>
            </Box>

            {colors.map(item => (
                <Box className={'GeneratorColorsList__Item' + (item.selected ? ' GeneratorColorsList__Item-selected' : '')} key={item.id}>
                    <Button className={'GeneratorColorsList__Item__Tappable'} style={{border: item.selected ? `4px solid ${getBorderColor(item.color)}` : '4px solid var(--base-white)'}} onClick={() => toggleItem(item)}>
                        <div className={'GeneratorColorsList__Item__Color'} style={{backgroundColor: item.color}}/>
                    </Button>
                    <IconButton className={'GeneratorColorsList__Item__RemoveButton'} onClick={() => removeItem(item)}>
                        <DeleteIcon width={12} height={12}/>
                    </IconButton>
                </Box>
            ))}
        </Box>
    )
}