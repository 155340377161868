import React, {useState, useRef, useEffect, useContext} from "react";
import {Typography, Box, Button, LinearProgress} from "@mui/material";
import {makeStyles} from "@mui/styles";
import { AnnotationQuestion } from "untitledui-js/icons/communication";


import './HelpContent.scss';
import {useScreenType} from "../../hooks/useScreenType";


export const HelpContent = () => {
    const screenType = useScreenType()
	return (
		<Box className={'Help__Wrapper'}>
            <Box className={'Main__box__help'}>
                <Box className={'Content__box__help'}>
                    <AnnotationQuestion size="24px" />
                    {
                        screenType === 'desktop' ?
                            <>
                                <Typography className={'Content__box__help__title'}>Got a question?</Typography>
                                <Typography className={'Content__box__help__description'}>Feel free to reach out and connect with<br />us anytime.</Typography>
                            </>
                        :
                            <Typography className={'Content__box__help__title'}>
                                Got a question? Feel free to reach<br/>out and connect with us anytime.
                            </Typography>
                    }

                    <Button className={'Content__box__help__button'} href='mailto:maria_loleyt@plurana.com' target={'_blank'}>
                        Email us
                    </Button>

                    <Box className={'Content__box__help__social'}>
                        <a style={{height: '31px'}} href="https://www.instagram.com/plurana_official/"><img src="/static/images/instagram.svg" alt="Instagram Plurana" width="31" height="31"/></a>
                        <a style={{height: '31px'}} href="https://www.pinterest.com/Plurana/"><img src="/static/images/pinterest.svg" alt="Pinterest Plurana" width="31" height="31" /></a>
                    </Box>
                </Box>
            </Box>
        </Box>
	)
}
