import React, {useState} from "react";
import {Button, Grid, Menu, MenuItem, Typography} from "@mui/material";
import {map} from "lodash";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {makeStyles} from "@mui/styles";

const filterMenuProps = {
	PaperProps: {
		sx: {
			padding: 1,
			maxHeight: 330,
			width: 238
		}
	},
	anchorOrigin: {
		vertical: 'bottom',
		horizontal: 'left',
	},
	transformOrigin: {
		vertical: 'top',
		horizontal: 'left',
	}
};

const useStyles = makeStyles((theme) => ({
	menuItem: {
		whiteSpace: 'normal',
		wordWrap: 'break-word',
	},
}));

const menus = [
	{key: "based-on", "title": "Based on", "items": [{id: 1, title: "Cultural heritage"}, {id: 2, title: "AI (Artificial Imagination)"}]},
	{key: "geo", "title": "Geography", "items": [{id: 1, title: "Latin America and The Carribean"}, {id: 2, title: "China"}]},
	{key: "period", "title": "Period", "items": [{id: 1, title: "Not long ago"}, {id: 2, title: "Long ago"}]},
]

const SearchFilters = () => {

	const [anchorEl, setAnchorEl] = useState(null);
	const [filterOpened, setFilterOpened] = useState(null);
	const onFilterButtonClick = (filterName, event) => {
		setFilterOpened(filterName);
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
		setFilterOpened(false);
	};

	const classes = useStyles();

	return <Grid container>
		<Grid item xs={4}>
			{map(menus, ({key, title, items}) =>

				<React.Fragment key={`filter-${key}`}>
					<Button
						variant="text"
						onClick={(e) => onFilterButtonClick(key, e)}
						endIcon={filterOpened === key ? <ExpandLessIcon /> : <ExpandMoreIcon/>}
					>{title}</Button>

					<Menu
						anchorEl={anchorEl}
						open={filterOpened === key}
						onClose={handleClose}
						{...filterMenuProps}
					>
						{map(items, ({id, title: itemTitle}) =>
							<MenuItem key={id} onClick={handleClose}>
								<Typography variant={"body2"} className={classes.menuItem}>{itemTitle}</Typography>
							</MenuItem>
						)}
					</Menu>
				</React.Fragment>
			)}

		</Grid>
	</Grid>
}

export default SearchFilters;