import HeroBackground from "../../pages/shop/HeroBackground.jpg";
import HeroVideo from "../../pages/shop/ShopHeroVideo.png";
import {PluranaButton} from "../Base";
import React from "react";
import './ShopHero.scss';

export const ShopHero = () => {
    return (
        <div className={'ShopHero__Wrapper'}>
            <div className={'ShopHero'}>
                <img src={HeroBackground} className={'ShopHero__Background'} alt={''}/>
                <img src={HeroVideo} className={'ShopHero__Video'} alt={''}/>
                <div className={'ShopHero__Column'}>
                    <div className={'ShopHero__Title'}>
                        Limitless Design, Limitless Creativity
                    </div>
                    <div className={'ShopHero__Subtitle'}>
                        Shop and generate thousands of intricate patterns, historic artworks, design templates and more.
                    </div>
                    <div className={'ShopHero__Controls'}>
                        <PluranaButton style={{background: 'rgba(246, 247, 249, 0.70)'}} href={'/'}>
                            Generate
                        </PluranaButton>
                        <PluranaButton variant={'outline'} href={'/shop/catalog'}>
                            Browse shop
                        </PluranaButton>
                    </div>
                </div>
                <div className={'ShopHero__Column'}>
                </div>
            </div>
        </div>
    )
}