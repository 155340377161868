import React from 'react';
import {Route, Routes} from "react-router-dom";
import CollectionsPage from './collections/CollectionsPage';
import {ReconstructionsPage} from "./reconstructions/ReconstructionsPage";
import {FiltersContext} from "../../contexts/FiltersContext";
import ShopIndexPage from "./ShopIndexPage";
import ReconstructionDetailPage from "./reconstructions/ReconstructionDetailPage";
import ShopAllIndexPage from "./ShopAllIndexPage";
import ShopSearchPage from "./ShopSearchPage";
import CartPage from "./CartPage";
import CheckPaymentPage from "./CheckPaymentPage";
import OrderPage from "./OrderPage";
import PurchasePage from "./PurchasePage";

function ShopRouter() {


  return (
    <FiltersContext.Provider value={null}>
      <Routes>
          <Route path="/" element={<ShopIndexPage />}/>
          <Route path="/cart" element={<CartPage />}/>
          <Route path="/payment" element={<CheckPaymentPage />}/>
          <Route path="/order/:orderId" element={<PurchasePage />}/>
          <Route path="/collection" element={<CollectionsPage />}/>
        {/*<Route path="/collection/:collectionId" element={<CollectionDetailsPage />}/>*/}
          <Route path='/catalog' element={<ShopAllIndexPage />}/>
          <Route path='/catalog/:slug' element={<ShopAllIndexPage />}/>
          <Route path="/reconstruction" element={<ReconstructionsPage />}/>
          <Route path="/reconstruction/:slug" element={<ReconstructionDetailPage />}/>
          <Route path="/pattern/:slug" element={<ReconstructionDetailPage />}/>
          <Route path="/catalog/search/:slug" element={<ShopSearchPage />}/>
          <Route path="/catalog/search" element={<ShopSearchPage />}/>
          <Route path="/freebies" element={<ShopAllIndexPage />}/>
      </Routes>
    </FiltersContext.Provider>
  );
}

export default ShopRouter;