import React from 'react';
import {SvgIcon} from "@mui/material";

function SelectIcon(props) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props} className={props.className + ' SelectIcon'}>
        <path d="M6 9L12 15L18 9" stroke="var(--plurana-neutrals-700)" stroke-width="1.6" fill='none' stroke-linecap="round" stroke-linejoin="round"/>
    </SvgIcon>
  );
}

export default SelectIcon;