import CartContext from "../../contexts/CartContext";
import React, {useContext, useEffect} from "react";
import {Box, Button, FormControl, Grid, Container, IconButton, MenuItem, Select, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {currencySymbol} from "../../helpers/shop";
import useAxios from "axios-hooks";
import {getAxiosHeaders} from "../../api/client";
import {Link as RouterLink } from "react-router-dom";
import SelectSmallIcon from "../../icons/SelectSmallIcon";
import './CartPage.scss';
import {useScreenType} from "../../hooks/useScreenType";
import {HeartRounded} from "untitledui-js/icons/general";

const _ = require("lodash");
const amplitude = window.amplitude;

const useStyles = makeStyles((theme) => ({
	cartHeader: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		marginBottom: '45px',
		"& h2.MuiTypography-root": {
			color: "#1A1A19",
			fontSize: '36px',
			fontWeight: 600,
			lineHeight: "100%",
		},
		"& .MuiTypography-root.MuiTypography-caption": {
			color: "rgba(26, 26, 25, 0.50)",
			fontSize: 16,
			fontWeight: 600,
			lineHeight: "100%",
		},
	},
	cartItemsList: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-start",
		gap: 14,
	},
	cartItem: {
		width: "100%",
		display: "flex",
		padding: 20,
		flexDirection: "row",
		alignItems: "center",
		gap: 20,
		alignSelf: "stretch",
		borderRadius: 20,
		boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.10), 0px 2px 2px 0px rgba(0, 0, 0, 0.04)",
	},
	cartItemImage: {
		width: 160,
		height: 160,
		borderRadius: '12px',
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center",
		backgroundSize: "cover",
		backgroundColor: "#F2F2F2",
	},
	cartItemInfo: {
		display: "flex",
		alignSelf: "stretch",
		flexDirection: "column",
		alignItems: "flex-start",
		justifyContent: "flex-start",
		flexGrow: 1,
		gap: 20,
		"& .title": {
			fontSize: '16px',
			fontWeight: 600,
			color: '#1A1A19',
			lineHeight: '100%'
		},
		"& .MuiInputBase-root": {
			height: 40,
			maxWidth: '330px',
			borderRadius: 6,
			border: "1px solid rgba(0, 0, 0, 0.07)",
			boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.05), 0px 2px 104px 0px rgba(0, 0, 0, 0.03)",
			"& .MuiSelect-select": {
				color: "#1A1A19",
				fontSize: '16px',
				fontWeight: 600
			}
		},
	},
	cartItemPrice: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		gap: 20,
		paddingLeft: 51,

		"& .price": {
			fontSize: '24px',
			fontWeight: 350,
			lineHeight: "100%",
		}
	},
	cartTotalBoard: {
		display: "flex",
		width: "100%",
		padding: "10px 30px 20px 30px",
		flexDirection: "column",
		alignItems: "flex-start",
		gap: 10,
		borderRadius: 20,
		background: "#var(--plurana-neutrals-100)",

		"& .totalLine": {
			display: "flex",
			flexDirection: "row",
			alignSelf: "stretch",
			alignItems: "center",
			justifyContent: "space-between",
			"& .total": {
				fontSize: '24px',
				fontWeight: 350,
				lineHeight: '100%',
				color: '#1A1A19'
			},
			"& .totalPrice": {
				fontSize: '48px',
				fontWeight: 350,
				lineHeight: '100%',
				color: '#1A1A19'
			}
		},

		"& .caption.MuiTypography-root": {
			color: "#999999",
			fontSize: 16,
			fontWeight: 350,
			lineHeight: "120%",
			margin: "20px 0 24px 0",
		},
		"& .buy.MuiButtonBase-root": {
			color: "#FFFFFF",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			gap: 13,
			width: '100%',
			fontSize: 14,
			fontWeight: 600,
			height: 48.5,
			borderRadius: 12,
			background: "#1A1A19",
		}
	},
	emptyCart: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		textAlign: 'center',
		width: '100%',
		height: 305,
		// maxWidth: 737,
		flexShrink: 0,
		borderRadius: 40,
		backgroundColor: "#var(--plurana-neutrals-100)",
		gap: 30,
		"& .title": {
			color: "#B3B3B2",
			fontSize: 46,
			fontWeight: 600,
			lineHeight: "100%",
		},
		"& .buttons": {
			display: "flex",
			gap: 6,
			"& .shop.MuiButtonBase-root": {
				height: 40,
				width: 126,
				borderRadius: 12,
				lineHeight: "100%",
				background: "#ffffff",
				fontSize: 14,
				color: '#767675',
				fontWeight: 600,
			},
			"& .generator.MuiButtonBase-root": {
				height: 40,
				width: 152,
				borderRadius: 12,
				color: "#FFF",
				lineHeight: "100%",
				fontSize: 14,
				fontWeight: 600,
				background: "#1A1A19",
			},
		}
	},
}));

const CartPage = () => {

	const classes = useStyles();
	const cart = useContext(CartContext);

	const screenType = useScreenType();

	const [{data}, doGetPaymentUrl] = useAxios({
		method: 'POST',
		url: '/api/checkout/',
		headers: getAxiosHeaders(),
	}, {manual: true});

	useEffect(() => {
		if (data?.payment_url) window.location.href = data.payment_url;
	}, [data])

	const handleCheckoutClick = () => {
		doGetPaymentUrl();
	}

	const handleUpdateCart = async (line, product, stockrecord_id) => {
		console.log(line, product, stockrecord_id)
		try {
			await cart.doUpdateCart({
				data: {
					line: line.id,
					stockrecord: stockrecord_id,
				}
			});
			amplitude?.track('front.shop.update-item', {item_id: product.id, stockrecord: stockrecord_id});
			cart.refetch();
		} catch (e) {
			console.log("oops", e)
		}
	}

	const handleRemoveFromCart = async (product, stockrecord) => {
		try {
			await cart.doAddToCart({
				data: {
					url: product.id,
					quantity: -1,
					stockrecord: stockrecord.id,
				}
			});
			amplitude?.track('front.shop.remove-from-cart', {item_id: product.id});
			cart.refetch();
		} catch (e) {
			console.log("oops", e)
		}
	}

	return (
	<Container maxWidth={false} disableGutters className={'CartPage'}>
		<Box className={'CartHeader'} sx={{
			width: '63%',
			mt: '47px'
		}}>
			<Box className={classes.cartHeader + ' CartHeader__Content'}>
				<Typography variant="h2">Cart</Typography>
				<Box display="flex" alignItems="center" justifyContent="center">
					<Typography variant='caption' pr={0.8} className={'CartHeader__Content__Backwards'}>View past orders</Typography>
					<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M2.6665 8H13.3332M13.3332 8L9.33317 4M13.3332 8L9.33317 12" stroke="#1A1A19" stroke-opacity="0.5" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
				</Box>
			</Box>
		</Box>

		<Grid mt='40px' display='flex' justifyContent='space-between' className={'CartPage__Grid'}>
			<Grid className={'CartPage__Column'} sx={{
					width: '63%',
				}}>
				
				<Grid>
					{cart.cart?.lines?.length > 0 ?
						<Box className={classes.cartItemsList}>

							{_.map(cart.cart?.lines, ({product, stockrecord, ...line}) => {
								const ItemCardFormSelect = () => (
									<FormControl className={classes.formSelect + ' CartItem__FormSelect'} fullWidth>
										<Typography sx={{
											fontSize: 14,
											fontWeight: 350,
											color: '#B3B3B2',
											marginBottom: '11px',
											lineHeight: '100%'
										}}>Choose License</Typography>
										<Select IconComponent={SelectSmallIcon} defaultValue={stockrecord.id} onChange={(event) => handleUpdateCart(line, product, event.target.value, )}>
											{_.map(product.stockrecords, (stock) => {
												return <MenuItem sx={{
													height: 40,
													fontSize: 14,
													fontWeight: 350,
													color: '#1A1A19',
													margin: '4px 13px 4px 13px',
												}} key={stock.id} value={stock.id}>{stock.title}</MenuItem>
											})}
										</Select>
									</FormControl>
								)
								if (screenType === 'desktop') {
									return (
										<Box key={line.id} className={classes.cartItem + ' CartItem'}>
											<Box className={classes.cartItemImage + ' CartItem__Image'} sx={{backgroundImage: `url(${product.images[0].original})`}}></Box>
											<Box className={classes.cartItemInfo + ' CartItem__Info'}>
												<Typography className="title">{product.title}</Typography>
												<ItemCardFormSelect/>
											</Box>
											<Box className={classes.cartItemPrice + ' CartItem__Price'}>
												<Typography className="price">{currencySymbol(stockrecord.price_currency)}{parseFloat(line.price)}</Typography>
												<IconButton className={classes.cartItemRemoveIcon} onClick={() => handleRemoveFromCart(product, stockrecord)}><img src="/static/images/close.svg" alt="" /></IconButton>
											</Box>
										</Box>
									);
								} else {
									return (
										<Box key={line.id} className={classes.cartItem + ' CartItem'}>
											<Box className={classes.cartItemInfo + ' CartItem__Info'}>
												<Box className={classes.cartItemImage + ' CartItem__Image'} sx={{backgroundImage: `url(${product.images[0].original})`}}/>
												<Typography className="title">
													{product.title}
												</Typography>
												<IconButton className={classes.cartItemRemoveIcon} onClick={() => handleRemoveFromCart(product, stockrecord)}>
													<img src="/static/images/close.svg" alt="" />
												</IconButton>
											</Box>
											<ItemCardFormSelect/>
											<Box className={'CartItem__Footer'}>
												<Box className={'CartItem__Footer__Controls'}>
													<Box className={'CartItem__Control CartItem__Footer__RemoveButton'} onClick={() => handleRemoveFromCart(product, stockrecord)}>
														Remove
													</Box>
													<Box className={'CartItem__Control CartItem__Footer__LikeButton'}>
														<HeartRounded size={14} stroke={'currentColor'}/> Save for later
													</Box>
												</Box>
												<Box className={classes.cartItemPrice + ' CartItem__Price'}>
													<Typography className="price">{currencySymbol(stockrecord.price_currency)}{parseFloat(line.price)}</Typography>
												</Box>
											</Box>
										</Box>
									)
								}
							})}

						</Box> : 
							<Box className={classes.emptyCart + ' CartItem-empty'}>
								<Typography className="title">Your cart is empty</Typography>
								<Box className="buttons">
									<Button variant="contained" className="shop" component={RouterLink} to="/shop">Browse Shop</Button>
									<Button variant="contained" className="generator" component={RouterLink} to="/">Generate Images</Button>
								</Box>
							</Box>
						}
				</Grid>
			</Grid>
			<Grid className={'CartPage__Column'} sx={{
					width: '35%',
				}}>
					{cart.cart?.lines?.length > 0 ?
						<Box className={classes.cartTotalBoard + ' CartTotalBoard'} id={'buy'}>
							<img src="/static/images/cards.png" width="237" alt="Cards supported: Master Card, Maestro, Visa, PayPal, ApplePay" />
							<Box className="totalLine">
								<Typography className="total">Total</Typography>
								<Typography className="totalPrice">{currencySymbol(cart.cart?.currency || "EUR")}{parseFloat(cart.cart?.total)}</Typography>
							</Box>

							<Typography className="caption">The standard VAT rate may be charged, following the law of your country</Typography>

							<Button className="buy" variant="contained" onClick={handleCheckoutClick}>Go to check-out</Button>

						</Box> : null }
			</Grid>
		</Grid>

		{(screenType === 'mobile' && cart.cart?.lines?.length > 0) &&
			<Box className={'ScrollToBuy'}>
				<Button onClick={() => {
					const elem = document.getElementById("buy");
					const y = elem.getBoundingClientRect().top + window.scrollY - 160;
					window.scrollTo({top: y, behavior: 'smooth'});
				}} fullWidth>
					Go to checkout
				</Button>
			</Box>
		}
	</Container>
	);

}

export default CartPage;