import React, {useEffect, useState, useRef} from "react";
import {Box, Container, LinearProgress, useMediaQuery, useTheme, Typography} from "@mui/material";
import useScrollPosition from "@react-hook/window-scroll";
import useAxios from "axios-hooks";

import _ from "lodash";
import {getAxiosHeaders} from "../../api/client";
import ShopItem from "../../components/Shop/ShopItem";
import StackGrid from "react-stack-grid";

import "./RelatedList.scss";


const RelatedList = (props) => {
    const setId = props.setId 

	const [shopItems, setShopItems] = useState([]);
	const [offset, setOffset] = useState(0);
	const [hasMore, setHasMore] = useState(false);

	const scrollY = useScrollPosition(10);

	const [{data, loading}] = useAxios({
		url: '/api/related/',
		headers: getAxiosHeaders(),
		params: {offset: offset, set_id: setId, limit: 10, type: 'pattern'},
	}, {useCache: false});

	const fetchNextPage = () => {
		setOffset(shopItems ? shopItems.length : 0)
	}

	useEffect(() => {
		if (data?.results?.length > 0) {
			setHasMore(offset + data.results.length < data.count);
			setShopItems((items) => items ? _.concat(items, data.results) : data.results);
		} // eslint-disable-next-line
	}, [data]);

	useEffect(() => {
		if (hasMore && !loading) {
			const elem = document.getElementById('shop-collection').getBoundingClientRect()
			if (elem.top < 0) {
				fetchNextPage();
			}
		}
	}, [scrollY]);

	useEffect(() => {
		gridRef.current?.updateLayout()
	}, [shopItems])

    const theme = useTheme();
	const gridRef = useRef(null);

	const tablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
	const mobile = useMediaQuery(theme.breakpoints.down("sm"));

	let columnWidth = "25%";
	let gutterWidth = 16;

	if (tablet || mobile) {
		columnWidth = "50%";
		gutterWidth = 10
	}

	if (!loading && !shopItems.length) {
		return null;
	}

	return (
		<Container maxWidth={false} disableGutters>
			<Box className={'Shop__collection__box Shop__collection__box-nopadding'} id={'shop-collection'} style={{marginTop: '32px'}}>
				<Box className={'Shop__collection__list'}>
					<Typography className={'Title__collection'}>Related</Typography>
					<Typography className={'Description__collection'}>Design materials below share the same graphics and color palette. Choose one of them or generate 1000+ more with Plurana.</Typography>
					<StackGrid
						className={'GenerativeListGrid'}
						gridRef={grid => gridRef.current = grid}
						columnWidth={columnWidth}
						itemComponent="div"
						duration={0}
						appearDelay={0}
						gutterWidth={gutterWidth}
						gutterHeight={24}
					>
						{_.map(shopItems, (item) => <ShopItem key={item.id} item={item} />)}
						{loading &&
							Array.from(Array(10), (e, i) => (
								<ShopItem skeleton key={i} item={{id: i}}/>
							))
						}
					</StackGrid>
				</Box>
			</Box>
		</Container>
	)
}

export default RelatedList;